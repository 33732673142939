export default {
    loadRemoveBundle: false,
    allLanders: [],

    modalApplyPauseBundles: false,
    modalApplyResumeBundles: false,
    modalApplyMultiRemoveBundles: false,
    modalApplyEditBundles: false,
    modalApplyRemoveBundle: false,
    modalAddBundle: false,
    modalAddManyBundle: false,
    loadAddBundle: false,
    stopTraffic: false,

    disableAddComment: true,
    loadComment: true,
    isBusy: false,
    textComment: "",
    modalComment: false,
    loadingCode: false,
    LoadingSource: false,

    totalRows: 0,
    valueSourceIdForBundle: "",
    valueCountryForBundle: "",
    valueSourcesIdForBundle: [],
    valueCountriesForBundle: [],
    valueMinRoiForBundle: "",
    valueMinCPMForBundle: "",
    chosenEditLander: null,
    optionsSourceIdForBundle: [],
    optionsCountryForBundle: [],
    loadPremissLanders: false,
    disableSaveBundle: false,
    disableSaveBundleEdit: false,
    modalEditBundle: false,

    actionsAllBtn: [{
            name: "playAll",
            icon: "play-fill",
            variant: "light",
            vIcon: "success",
        },
        {
            name: "editAll",
            icon: "pencil-fill",
            variant: "light",
            vIcon: "secondary",
        },
        {
            name: "pauseAll",
            icon: "pause-fill",
            variant: "light",
            vIcon: "warning",
        },
        {
            name: "archiveAll",
            icon: "trash-fill",
            variant: "light",
            vIcon: "danger",
        },
    ],

    heightTable: "300px",

    perPage: 200,
    currentPage: 1,

    sortAsc: null,
    sortField: null,
    bundles: [],
    new_height: "200px",
    valueBundlesIds: [],
    bundlesIds: [],
    loading: false,
    loadSaveBundle: false,
    selectIdBundle: 0,
    loadBundleOverlay: false,
    loadBundle: false,
    selectedBundles: [],
    disabledButtonAllAction: true,
    pageOptions: {
        groupBy: Array(0),
        groupDesc: Array(0),
        itemsPerPage: 200,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: Array(0),
        sortDesc: Array(0),
    },

    fields: [{
            value: "actions",
            text: "Действия",
            class: "headBackground",
            width: "200px",
            sortable: false,
        },
        {
            value: "black_on",
            text: "Блек",
            sortable: true,
            class: "headBackground",
            width: "85px",
        },
        {
            value: "bundle_id",
            text: "ID",
            sortable: true,
            class: "headBackground",
            width: "85px",
        },
        {
            value: "source_id",
            text: "ID ист.",
            sortable: true,
            class: "headBackground",
            width: "85px",
        },
        {
            value: "network",
            text: "Название сети",
            sortable: true,
            class: "headBackground",
            width: "221px",
        },
        {
            value: "country",
            text: "Страна",
            sortable: true,
            class: "headBackground",
            width: "85px",
        },
        {
            value: "min_cpm",
            text: "мин. CPM",
            sortable: true,
            class: "headBackground",
        },
        {
            value: "max_cpm",
            text: "макс. CPM",
            sortable: true,
            class: "headBackground",
        },
        {
            value: "max_cpm_black",
            text: "макс. CPM Блек Этап",
            sortable: true,
            class: "headBackground",
        },
        {
            value: "rate",
            text: "Рейт",
            sortable: true,
            class: "headBackground",
        },
        {
            value: "min_roi",
            text: "мин. ROI",
            sortable: true,
            class: "headBackground",
        },
        {
            value: "create_date",
            text: "Дата создания",
            sortable: true,
            class: "headBackground",
        },
        {
            value: "active_time",
            text: "Активное время",
            sortable: true,
            class: "headBackground",
        },
        {
            value: "update_date",
            text: "Дата обновления",
            sortable: true,
            class: "headBackground",
        },
        {
            value: "createn_roi",
            text: "Критический ROI",
            sortable: true,
            class: "headBackground",
        },
        {
            text: "Расход",
            value: "tracker_cost",
            sortable: true,
            class: "headBackground",
        },
        {
            text: "Доход",
            value: "revenue_tracker",
            sortable: true,
            class: "headBackground",
        },
        {
            text: "Профит",
            value: "tracker_profit",
            sortable: true,
            class: "headBackground",
        },
        {
            text: "ROI",
            value: "roi_tracker",
            sortable: true,
            class: "headBackground",
        },
    ],

    actionsBtn: [{
            name: "edit",
            icon: "pencil-fill",
            variant: "light",
            vIcon: "secondary",
            disabled: false
        },
        {
            name: "archive",
            icon: "trash-fill",
            variant: "light",
            vIcon: "danger",
            disabled: false
        },
        {
            name: "report",
            icon: "file-text-fill",
            variant: "light",
            vIcon: "info",
            disabled: false
        },
        {
            name: "comment",
            icon: "chat-left-quote-fill",
            variant: "light",
            vIcon: "dark",
            disabled: false
        },
    ],
};