import {
    HELP,
    API_BUNDLES,
    API_LANDERS,
    API_PARTNERS
} from "../constants";
import axios from "axios";

export default {
    doisabledLoading() {
        if (this.LoadingSource && this.loadingCode && this.LoadingBundlesIds)
            this.loading = false;
        else this.loading = true;
    },

    //ПОлучение связок
    getBundles(sort = false) {
        if (!sort) this.loadBundle = true;
        else this.isBusy = true;

        // console.log("sort", sort);
        // console.log("isBusy", this.isBusy);
        axios
            .get(API_BUNDLES.CHECK_BACKUP)
            .then((checkBackUp) => {
                this.stopTraffic = checkBackUp.data === 0 ? false : true;

                let status = this.dataFilter.status;
                status = status && status.code ? status.code : null;
                console.log(
                    `${API_BUNDLES.FIND}?status=${status}&bundle_ids=${this.dataFilter.bundles}&countries=${this.dataFilter.codes}&sources=${this.dataFilter.sources}&fieldSort=${this.sortField}&dirSort=${this.sortAsc}&sizePage=${this.perPage}&page=${this.currentPage}`
                );
                axios
                    .get(
                        `${API_BUNDLES.FIND}?status=${status}&bundle_ids=${this.dataFilter.bundles}&countries=${this.dataFilter.codes}&sources=${this.dataFilter.sources}&fieldSort=${this.sortField }&dirSort=${this.sortAsc}&sizePage=${this.perPage}&page=${this.currentPage}`
                    )
                    .then((res) => {
                        // console.log(res.data.data[0]);
                        this.bundles = res.data.data.map((a) => {
                            return {
                                ...a,
                                active_time: this.formatActiveTimeForDisplay(a.active_time),
                                createn_roi: !a.createn_roi ?
                                    "" : Math.round(a.createn_roi) + "%",
                                min_cpm: this.strUSD(Number(a.min_cpm).toFixed(2)),
                                max_cpm: this.strUSD(Number(a.max_cpm).toFixed(2)),
                                max_cpm_black: a.max_cpm_black ? this.strUSD(Number(a.max_cpm_black).toFixed(2)) : null,
                                min_roi: this.strPercent(Math.round(Number(a.min_roi) * 100)),
                                bundle_min_roi: a.bundle_min_roi == null ?
                                    a.bundle_min_roi : this.strPercent(
                                        Math.round(Number(a.bundle_min_roi * 100))
                                    ),
                                rate: this.strUSD(Number(a.rate).toFixed(2)),
                                create_date: new Date(a.create_date)
                                    .toLocaleString("ru-RU")
                                    .split(", ")
                                    .join(" "),
                                update_date: a.update_date ? new Date(a.update_date)
                                    .toLocaleString("ru-RU")
                                    .split(", ")
                                    .join(" ") : null,
                                tracker_cost: a.tracker_cost ?
                                    this.strUSD(Number(a.tracker_cost).toFixed(2)) : "$0.00",
                                revenue_tracker: this.strUSD(
                                    Number(a.revenue_tracker).toFixed(2)
                                ),
                                tracker_profit: this.strUSD(
                                    Number(a.tracker_profit).toFixed(2)
                                ),
                                roi_tracker: this.strPercent(
                                    Math.round(Number(a.roi_tracker) * 100)
                                ),
                            };
                        });
                        this.totalRows = res.data.amount;
                        if (!sort) this.loadBundle = false;
                        else this.isBusy = false;
                    })
                    .catch((er) => {
                        console.log(er);
                        const e = er.response.data.message;
                        this.makeToast("Ошибка получения связок", e, "danger");
                        if (!sort) this.loadBundle = false;
                        else this.isBusy = false;
                    });
            })
            .catch((er) => {
                console.log(er);
                this.makeToast("Ошибка проверки backUp статусов", er, "danger");
            });
    },
    //Получаем id всех связок
    getBundlesIds() {
        this.loading = true;
        axios
            .get(`${HELP.BUNDLES_IDS}`)
            .then((res) => {
                this.bundlesIds = res.data.map((a) => {
                    return {
                        name: a,
                        code: a
                    };
                });
                this.loading = false;
                this.LoadingBundlesIds = true;
            })
            .catch((er) => {
                this.loading = true;
                this.LoadingBundlesIds = false;
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка получения id связок", e, "danger");
            });
    },
    //Получаем все офферы
    getOffers() {
        axios
            .get(API_PARTNERS.GET)
            .then((offers) => {
                this.offers = offers.data.map((a) => {
                    return {
                        code: a.offer,
                        name: a.offer
                    };
                });
                this.loadingFilter = false;
            })
            .catch((err) => {
                const e = err.response ?
                    err.response.data.message ?
                    err.response.data.message :
                    err.message ?
                    err.message :
                    JSON.stringify(err) :
                    err.message;
                this.makeToast("Ошибка получения партнеров", e, "error");
            });
    },
    //Получаем  всех страны
    getCountries() {
        this.loadingCode = false;
        axios
            .get(`${HELP.COUNTRIES}`)
            .then((res) => {
                this.codes = res.data.map((a) => {
                    return {
                        name: a.country,
                        code: a.country
                    };
                });
                this.optionsCountryForBundle = this.codes;
                this.loadingCode = true;
                this.doisabledLoading();
            })
            .catch((er) => {
                this.loadingCode = false;
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка получения стран", e, "danger");
                this.optionsCountryForBundle = [];
            });
    },

    //Получаем  всех источников
    getSources() {
        this.LoadingSource = false;
        axios
            .get(`${HELP.SOURCES}`)
            .then((res) => {
                this.sources = res.data.map((a) => {
                    return {
                        name: `${a.source_id} - ${a.name}`,
                        code: a.source_id
                    };
                });
                this.optionsSourceIdForBundle = this.sources;
                //console.log(this.optionsSourceIdForBundle);
                // this.loadAddBundleModal = false;
                this.LoadingSource = true;
                this.doisabledLoading();
            })
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка получения источников", e, "danger");
                this.LoadingSource = false;
                this.optionsSourceIdForBundle = [];
                //Загрузка моального окна
                // this.loadAddBundleModal = false;
            });
    },

    getAllLanders() {
        axios
            .get(API_LANDERS.FULL)
            .then((landers) => {
                this.allLanders = landers.data.map((a) => {
                    return {
                        name: a.land,
                        code: a.land,
                        offer: a.offer,
                        land_id: a.land_id,
                        tb_id: a.tb_id
                    };
                });
            })
            .catch((er) => {
                const e = er.response.data.message;
                this.makeToast("Ошибка getAllLanders", e, "danger");
            });
    },

    getRates() {
        this.loadRates = true;
        const codesStr =
            this.valueCodes.length > 0 ?
            this.valueCodes.map((a) => a.code).join(",") :
            null;
        const partnerStr =
            this.valuePartner.length > 0 ?
            this.valuePartner.map((a) => a.code).join(",") :
            null;
        const sourceStr =
            this.valueSources.length > 0 ?
            this.valueSources.map((a) => a.code).join(",") :
            null;
        console.log(
            encodeURI(
                `${HELP.RATES}?countries=${codesStr}&offers=${partnerStr}&source_ids=${sourceStr}`
            )
        );
        axios
            .get(
                encodeURI(
                    `${HELP.RATES}?countries=${codesStr ? codesStr : ""}&offers=${
                partnerStr ? partnerStr : ""
              }&source_ids=${sourceStr ? sourceStr : ""}`
                )
            )
            .catch((er) => {
                console.log(er);
                this.makeToast("Ошибка получения рейтов", er, "danger");
            })
            .then((res) => {
                const d = res.data;
                this.rows_count = d.length;
                if (d.length > 0) {
                    this.rates = d.map((rateGroup) => {
                        const idx = rateGroup.rates.findIndex(a => a.source_id == -1);
                        const res = {
                            code: rateGroup.country,
                            partner_name: rateGroup.offer,
                            default: rateGroup.rates[idx] ? rateGroup.rates[idx].rate : null,
                        };
                        for (let index = 0; index < rateGroup.rates.length; index++) {
                            const rate = rateGroup.rates[index] ? rateGroup.rates[index].rate : null;
                            const source = rateGroup.rates[index] ? rateGroup.rates[index].source_id : null;
                            res[`source_${source}`] = rate;
                        }
                        return res;
                    });
                    this.fields = [{
                            text: "",
                            value: "code",
                            width: "60px",
                            sortable: false,
                            class: "headBackground",
                        },
                        {
                            text: "",
                            value: "partner_name",
                            width: "100px",
                            sortable: false,
                            class: "headBackground",
                        },
                        {
                            text: "Рейт по умолч.",
                            value: "default",
                            width: "150px",
                            class: "headBackground",
                        },
                    ];
                    let sources = this.sources.filter((a) => a.code != -1);
                    if (this.valueSources.length > 0) {
                        sources = sources.filter((a) =>
                            this.valueSources.some((s) => s.code == a.code)
                        );
                    }
                    for (let index = 0; index < sources.length; index++) {
                        const source = sources[index];
                        this.fields.push({
                            text: "" + source.code,
                            value: `source_${source.code}`,
                            sortable: false,
                            class: "headBackground",
                        });
                    }
                    this.totalRows = res.data.amount;
                    this.loadRates = false;
                    this.loadingFilter = false;
                } else {
                    this.rates = [];
                    this.fields = [];
                    this.totalRows = res.data.amount;
                    this.loadRates = false;
                    this.loadingFilter = false;
                }
            });
    }
}