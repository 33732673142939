   <template>
  <div>
    <b-row
      class="mx-0 px-0"
      style="height: calc(100vh - 120px)"
      align-v="center"
    >
      <b-col class="mx-0 px-0"></b-col>
      <b-col sm class="mx-5 px-0">
        <!-- <b-jumbotron> -->
        <b-form
          class="shadow-lg px-4 py-4 my-0 mx-0 bg-white"
          @submit.prevent="login"
        >
          <h2 class="text-center">Войти</h2>
          <b-alert :show="erAuth" variant="danger">{{ erAuthTitle }}</b-alert>
          <hr />
          <b-form-input
            id="input-1"
            v-model="email"
            type="email"
            class="mb-3"
            placeholder="Введите email"
            required
          ></b-form-input>

          <b-form-input
            id="input-2"
            v-model="password"
            type="password"
            placeholder="Введите пароль"
            required
          ></b-form-input>
          <hr />
          <b-button variant="primary" type="submit">Войти</b-button>
        </b-form>
        <!-- </b-jumbotron> -->
      </b-col>
      <b-col class="mx-0 px-0"></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      erAuth: false,
      email: "",
      password: "",
      erAuthTitle: "",
    };
  },
  methods: {
    login: function () {
      let email = this.email;
      let password = this.password;
      this.$store
        .dispatch("login", { email, password })
        .then(() => {
          this.$router.push("/");
          this.erAuth = false;
          this.erAuthTitle = "";
        })
        .catch((err) => {
          console.log(err);
          this.erAuth = true;
          this.erAuthTitle = err.response.data.message;
        });
    },
  },
};
</script>