export default {
    /**
     * Вывод сообщения пользователю
     * @param {string} title 
     * @param {string} body 
     * @param {string} variant Вариант отображения тоста ["success", "danger"]
     */
    makeToast(title, body, variant = null) {
        this.$bvToast.toast(body, {
            title: title,
            variant: variant,
            solid: true,
        });
    },

    /**
     * Ограничение конверсии. Она не должна превышать 1
     * @param {*} su 
     * @param {*} tr 
     * @returns 
     */
    phat(su, tr) {
        return su / tr > 1 ? 1 : su / tr;
    },

    /**
     * Расчет CI (BQ ats-dev-315606.system_data.calcCI )
     * @param {Number} impressions показы
     * @param {Number} leads_p лиды пуши
     * @param {Number} leads_tb лиды тб
     * @param {Number} z_scope 
     * @param {Number} rate 
     * @param {Number} epl_tb 
     * @param {Number} min_roi 
     * @param {Number} calc_vector -1 низ, 1 верх
     */
    calcCI(impressions, leads_p, leads_tb, z_scope, rate, epl_tb, min_roi, calc_vector) {
        const a = {
            p: this.phat(leads_p, impressions) + (Math.pow(z_scope, 2) / (2 * impressions)),
            tb: this.phat(leads_tb, impressions) + (Math.pow(z_scope, 2) / (2 * impressions))
        };
        const b = {
            p: z_scope * Math.sqrt(((this.phat(leads_p, impressions) * (1 - this.phat(leads_p, impressions))) + (Math.pow(z_scope, 2) / (4 * impressions))) / impressions),
            tb: z_scope * Math.sqrt(((this.phat(leads_tb, impressions) * (1 - this.phat(leads_tb, impressions))) + (Math.pow(z_scope, 2) / (4 * impressions))) / impressions)
        };
        const c = 1 + (Math.pow(z_scope, 2) / impressions);

        const result = ((a.p + calc_vector * b.p) / c * rate + (a.tb + calc_vector * b.tb) / c * epl_tb * 1000) / (min_roi + 1);
        return isNaN(result) ? null : result;
    },
    /**
     * Форматирование времени активности связки
     */
    formatActiveTimeForDisplay(obj) {
        const strTime = [];
        for (let el in obj) {
            switch (el) {
                case "days":
                    strTime.push((obj[el] < 0 ? obj[el] * -1 : obj[el]) + " дн.");
                    break;
                case "hours":
                    strTime.push((obj[el] < 0 ? obj[el] * -1 : obj[el]) + " ч.");
                    break;
                case "minutes":
                    strTime.push((obj[el] < 0 ? obj[el] * -1 : obj[el]) + " м.");
                    break;
                case "seconds":
                    // strTime.push((obj[el] < 0 ? obj[el] * -1 : obj[el]) + " с.");
                    break;
                default:
                    break;
            }
        }
        return strTime.join(" ");
    },
    /**
     * Шаблон строки с долларом
     * @param {I} v
     */
    strUSD(v) {
        const str = String(v);
        let finalVUSD;
        if (v.indexOf("-") != -1) {
            finalVUSD = `-$${str.split("-")[1]}`;
        } else {
            finalVUSD = `$${str}`;
        }
        return finalVUSD;
    },
    /**
     * Шаблон строки с процентом
     * @param {I} v
     */
    strPercent(v) {
        return `${v}%`;
    },
    /**
     * Сравнение 2х объектов по их свойствам
     * @param {*} x 
     * @param {*} y 
     * @returns 
     */
    compareTwoObjects(x, y) {
        let objectsAreSame = true;
        for (const propertyName in x) {
            if (x[propertyName] !== y[propertyName]) {
                objectsAreSame = false;
                break;
            }
        }
        return objectsAreSame;
    }
}