import VueRouter from 'vue-router';
import bundles from '../views/bundles';
import draft from '../views/draft';
import landers from '../views/landers';
import rates from '../views/rates';
import custom_rates from '../views/custom_rates';
import sources from '../views/sources';
import settings from '../views/settings';
import logger from '../views/logger';
import report from '../views/report';
import tests from '../views/tests';
import login from '../views/login';
import store from '../store';
import queues from '../views/queues';
import periods from '../views/periods';
import periodTable from '../views/periodTable';
import bunch from '../views/bunch';
import domains from '../views/domains';
import splits from '../views/splits';
import kv from '../views/kv';
import sourceLoss from '../views/traffic-loss';
import bundleLoss from '../views/bundle-loss';
import ping from '../views/ping';
import calcTests from '../views/calcTests';
import calcPlacementMinus from '../views/calcPlacementMinus';
import redis from '../views/redis';
import networks from '../views/networks';
import priorities from '../views/priorities';
import networksSchema from '../views/networks.schema';


const router = new VueRouter({
    routes: [{
            path: '/login',
            component: login
        },
        {
            path: '/ping',
            component: ping
        },
        {
            path: '/report/:id',
            component: report,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/',
            component: bundles,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/draft',
            component: draft,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/landers',
            component: landers,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/rates',
            component: rates,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/custom-rates',
            component: custom_rates,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/sources',
            component: sources,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/priorities',
            component: priorities,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/settings',
            component: settings,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/logger',
            component: logger,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tests',
            component: tests,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/queues',
            component: queues,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/periods',
            component: periods,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/domains',
            component: domains,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/splits',
            component: splits,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/kv',
            component: kv,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/source-loss',
            component: sourceLoss,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/bundle-loss',
            component: bundleLoss,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/calc-tests',
            component: calcTests,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/calc-placement-minus',
            component: calcPlacementMinus,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/redis',
            component: redis,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/networks',
            component: networks,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/networks-schema',
            component: networksSchema,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/periodTable/partner=:partner&source=:source&country=:country&from_date=:from_date&to_date=:to_date',
            component: periodTable,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/bunch/partner=:partner&source=:source&country=:country&from_date=:from_date&to_date=:to_date',
            component: bunch,
            meta: {
                requiresAuth: true
            }
        },
    ],
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
});

export default router;