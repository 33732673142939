<template>
  <div>
    <b-container>
      <br>
      <h2>KV</h2>
      <hr>
      <b-tabs
        pills
        v-model="tabIndex"
        content-class="mt-3"
      >
        <b-tab
          v-for="(tab, idx) in tabs"
          :title-link-class="linkClass(idx)"
          :key="tab.id"
          :title="tab.title"
        >
          <b-form @submit="onSearch" class="d-flex justify-content-end" inline>
            <b-form-input
              type="search"
              v-model="form.input"
              class="mr-3"
              placeholder="ИД связки"
            ></b-form-input>
            <b-button type="submit" variant="dark">Поиск</b-button>
          </b-form>

          <br>

          <b-table
            id="kv-table"
            small
            fixed
            responsive="sm"
            primary-key="name"
            head-variant="dark"
            :busy="isBusy"
            :items="items"
            :filter="filter"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            @filtered="onFiltered"
            @row-clicked="onClick"
          >
            <template #cell(action)="{ item }">
              <b-button @click="updateItem = item" v-b-modal.update-modal>
                <small><b-icon icon="pencil"></b-icon></small>
              </b-button>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            class="text-dark"
            aria-controls="kv-table"
          >
            <template #page="{ page }">
              <span>{{ page }}</span>
            </template>
          </b-pagination>
        </b-tab>
      </b-tabs>
    </b-container>
    <b-modal
      id="update-modal"
      :title="updateItem ? 'Обновить значение ' + updateItem.name + ' связки' : ''"
      :ok-disabled="okDisabled"
      @show="showModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Значение"
          label-for="value-input"
          invalid-feedback="Value is required"
          :state="valueState"
        >
          <b-form-input
            id="value-input"
            v-model="value"
            :state="valueState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { API_BUNDLES } from '../constants';

export default {
  data() {
    return {
      /* индекс текущего таба. 0 - bundles, 1 - countries, 2 - sources */
      tabIndex: 0,

      filter: '',

      value: '',
      valueState: null,
      updateItem: null,
      okDisabled: true,

      form: {
        input: '',
      },

      isBusy: false,
      perPage: 15,
      totalRows: 0,
      currentPage: 1,

      /* заголовки таблицы */
      fields: [
        { key: 'action', label: 'Действие', thStyle: { width: '10%' } },
        { key: 'name', label: 'Название', thStyle: { width: '25%' }, class: 'pl-3' },
        { key: 'value', label: 'Значение' }
      ],

      /* данные таблицы. подгружаются при вызове getValues */
      items: [],

      /* id и название из KV */
      tabs: [
        {
          id: 'b25ea20a72fe4874ac0673170b0c093c',
          title: 'BUNDLES'
        },
        {
          id: 'cb8a18f0bc884304b755c221cbda7f65',
          title: 'COUNTRIES'
        },
        {
          id: 'd67c2b2e215341278ca7f88b6f42ea96',
          title: 'SOURCES'
        }
      ]
    }
  },


  /* загружает данные по умолчанию для tabIndex = 0 */
  created() {
    this.getValues()
  },


  watch: {
    /* загружает данные при изменении tabIndex */
    tabIndex: function() {
      this.getValues()
    }
  },


  methods: {
    getValue(key) {
      return axios.get(API_BUNDLES.GET_NAMESPACE_VALUE, {
        params: {
          namespace_id: this.tabs[this.tabIndex].id, key
        }
      })
      .then((resp) => {
        return resp.data;
      })
    },

    onClick(e) {
      e.value = 'загрузка...';
      this.getValue(e.name)
        .then((val) => e.value = JSON.stringify(val))
        .catch(() => e.value = 'Не удалось загрузить!')
    },

    /* */
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-dark', 'text-white'];
      } else {
        return ['bg-transparent', 'text-dark'];
      }
    },

    /* */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.isBusy = false;
    },

    /** */
    showModal() {
      this.value = 'Загрузка...';
      this.updateItem.value = 'Загрузка...'
      this.getValue(this.updateItem.name)
        .then((val) => {
          this.okDisabled = false;
          this.value = JSON.stringify(val);
          this.updateItem.value = JSON.stringify(val);
        })
        .catch(() => {
          this.value = 'Не удалось загрузить!'
          this.okDisabled = true;
        })
    },

    /** */
    resetModal() {
      this.value = '';
      this.valueState = null;
      this.updateItem = null;
      this.okDisabled = true;
    },

    /** */
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit();
    },

    /** */
    handleSubmit() {
      axios.post(API_BUNDLES.UPDATE_NAMESPACE_VALUE, {
        namespace_id: this.tabs[this.tabIndex].id,
        key: this.updateItem.name,
        value: this.value
      })
        .then(() => {
          this.updateItem.value = this.value;
          this.makeToast('Успех', 'Связка обновлена', 'success');
        })
        .catch((err) => {
          console.log('Ошибка обновления', err.response.data.message);
          this.makeToast('Ошибка обновления!', err.response.data.message, 'danger');
        })
        .finally(() => {
          this.$nextTick(() => {
            this.$bvModal.hide('update-modal')
          })          
        })
    },

    /* */
    onSearch(e) {
      e.preventDefault();
      this.isBusy = true;
      this.filter = this.form.input;
    },

    /* загружает данные */
    /* TODO: сделать загрузку частями */
    getValues(cursor = '', items = []) {
      this.isBusy = true;
      const namespaceId = this.tabs[this.tabIndex].id;
      axios.get(API_BUNDLES.GET_NAMESPACE_KEYS, {
        params: {
          namespace_id: namespaceId,
          cursor
        }
      })
      .then((resp) => {
        items = [ ...items, ...resp.data.result.map(a => ({ ...a, value: '' })) ]
        cursor = resp.data.result_info.cursor;
        if (cursor != '') this.getValues(cursor, items);
        else {
          this.items = [ ...items ];
          this.filter = '';
          this.form.input = '';
          this.totalRows = this.items.length;
          this.isBusy = false;
        }
      })
    },

    //Тост
    makeToast(title, body, variant = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      });
    }
  },

}
</script>