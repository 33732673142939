<template>
  <div>
    <b-container fluid>
      <h2>Настройки сетей</h2>
      <hr />
      <b-button
        @click="addNetworkModal = !addNetworkModal"
        variant="outline-success"
        class="mr-2 mb-3"
        >Добавить
      </b-button>
      <b-table
        selectable
        bordered
        ref="selectableTable"
        style="font-size: 14px"
        striped
        responsive="xl"
        hover
        small
        :fields="fields"
        :items="networkSettings"
      >
        <template #cell(actions)="data">
          <div style="width: 80px">
            <b-button
              v-for="action in actionsBtn"
              :key="action.name"
              size="sm"
              class="mr-1"
              @click="processAction(action.name, data)"
              :variant="action.vIcon"
            >
              <b-icon
                font-scale="0.97"
                :icon="action.icon"
                aria-hidden="true"
              ></b-icon
            ></b-button>
          </div>
        </template>
      </b-table>
    </b-container>

    <!-- Добавить сеть -->
    <b-modal
      v-model="addNetworkModal"
      :title="`Добавить сети`"
      @hidden="resetModal"
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <div v-for="field in formFields" :key="field.name">
        <b-form-input
          v-if="formData != null"
          :type="field.type"
          class="mb-3"
          v-model="formData[field.name]"
          required
          :placeholder="field.placeholder"
        ></b-form-input>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="addNetwork">
            Добавить
          </b-button>
          <b-button
            variant="secondary"
            class="float-right mr-2"
            @click="addNetworkModal = false"
          >
            Отменить
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Обновить сеть -->
    <b-modal
      v-model="updateNetworkModal"
      :title="`Обновить сеть`"
      @hidden="resetModal"
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <div v-for="field in formFields" :key="field.name">
        <b-form-input
          v-if="formData != null"
          :type="field.type"
          class="mb-3"
          v-model="formData[field.name]"
          required
          :placeholder="field.placeholder"
        ></b-form-input>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            @click="updateNetwork"
          >
            Обновить
          </b-button>
          <b-button
            variant="secondary"
            class="float-right mr-2"
            @click="updateNetworkModal = false"
          >
            Отменить
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script scoped>
import axios from "axios";
import { API_BUNDLES } from "../constants";
import utility_funtions from "../utility/utility-funtions";

export default {
  data() {
    return {
      addNetworkModal: false,
      updateNetworkModal: false,
      removeNetworkModal: false,
      formFields: [],
      formData: null,

      networkNameValue: "",
      zoneCampLimitValue: "",
      updateIntervalValue: "",

      networkSettings: [],
      fields: [
        { key: "actions", label: "Действия" },
        { key: "network_name", label: "Название сети", sortable: true },
        {
          key: "zone_camp_limit",
          label: "Лимит на кол-во компаний",
          sortable: true,
        },
        {
          key: "update_interval",
          label: "Интервал обновления",
          sortable: true,
        },
      ],
      actionsBtn: [
        {
          name: "edit",
          icon: "pencil-fill",
          variant: "light",
          vIcon: "secondary",
        },
        {
          name: "remove",
          icon: "x-circle-fill",
          variant: "light",
          vIcon: "danger",
        },
      ],
      available_types: {
        "character varying": "text",
        integer: "number",
        smallint: "number",
        bigint: "number",
        numeric: "number",
      },
    };
  },

  mounted() {
    this.getNetworksScheme();
    this.getNetworks();
  },

  methods: {
    ...utility_funtions,
    getNetworksScheme() {
      axios
        .get(API_BUNDLES.NETWORK_SCHEME_SETTINGS)
        .then((resp) => {
          this.formFields = resp.data
            .map((a) => {
              return {
                name: a.column_name,
                type: this.available_types[a.data_type],
                placeholder: a.description,
              };
            })
            .filter((a) => a.type);
          this.formData = {};
          this.formFields.forEach((a) => (this.formData[a.name] = null));
          this.fields = [
            "actions",
            ...resp.data.map((a) => {
              return { key: a.column_name, label: a.description };
            }),
          ];
        })
        .catch((e) => {
          console.warn(e);
          this.makeToast("Ошибка!", e.message, "danger");
        });
    },
    getNetworks() {
      axios
        .get(API_BUNDLES.NETWORK_SETTINGS)
        .then((resp) => {
          this.networkSettings = [...resp.data];
        })
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {});
    },

    addNetwork() {
      const data = this.formData;
      this.formFields.forEach(
        (f) =>
          (data[f.name] = data[f.name]
            ? f.type == "number"
              ? Number(data[f.name])
              : data[f.name]
            : null)
      );
      axios
        .post(API_BUNDLES.NETWORK_SETTINGS_ADD, data)
        .then(() => {
          this.makeToast(
            "Успех!",
            "Сеть " + this.networkNameValue + " добавлена.",
            "success"
          );
        })
        .catch((e) => {
          this.makeToast("Ошибка!", e.message, "danger");
        })
        .finally(() => {
          this.getNetworks();
          this.addNetworkModal = false;
        });
    },

    updateNetwork() {
      const data = this.formData;
      axios
        .post(API_BUNDLES.NETWORK_SETTINGS_UPDATE, data)
        .then(() => {
          this.makeToast(
            "Успех!",
            "Сеть " + this.networkNameValue + " обновлена.",
            "success"
          );
        })
        .catch((e) => {
          this.makeToast("Ошибка!", e.message, "danger");
        })
        .finally(() => {
          this.getNetworks();
          this.updateNetworkModal = false;
        });
    },

    deleteNetwork(networkName) {
      axios
        .get(
          API_BUNDLES.NETWORK_SETTINGS_REMOVE + "?networkName=" + networkName
        )
        .then(() => {
          this.makeToast(
            "Успех!",
            "Сеть " + networkName + " удалена.",
            "success"
          );
        })
        .catch((e) => {
          this.makeToast("Ошибка!", e.message, "danger");
        })
        .finally(() => {
          this.getNetworks();
        });
    },

    resetModal() {
      this.networkNameValue = "";
      this.zoneCampLimitValue = "";
      this.updateIntervalValue = "";
      Object.keys(this.formData).forEach((k) => (this.formData[k] = null));
    },

    processAction(action, data) {
      if (action === "edit") {
        this.networkNameValue = data.item.network_name;
        this.zoneCampLimitValue = data.item.zone_camp_limit;
        this.updateIntervalValue = data.item.update_interval;
        this.formData = data.item;
        this.updateNetworkModal = true;
      } else {
        this.deleteNetwork(data.item.network_name);
      }
    },
  },
};
</script>