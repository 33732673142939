<template>
  <div>
    <v-app>
      <b-overlay spinner-variant="primary" :show="loadAddBundle" rounded="sm">
        <b-container fluid>
          <h2>Связки</h2>
          <b-alert :show="stopTraffic" variant="danger"
            >Внимание! Трафик остановлен! Что бы включить трафик перейдите в
            "Админ." панель.</b-alert
          >
          <hr />

          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-form inline>
                <b-skeleton
                  class="col-sm-2 mr-2 mb-2"
                  type="button"
                ></b-skeleton>
                <b-skeleton
                  class="col-sm-2 mr-2 mb-2"
                  type="button"
                ></b-skeleton>
              </b-form>
              <b-form class="mt-3" inline>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
                <b-skeleton
                  class="col-sm-2 mr-2 mb-2"
                  type="button"
                ></b-skeleton>
              </b-form>
            </template>

            <b-row class="mt-3">
              <b-col sm class="px-0 mx-2">
                <multiselect
                  class="mb-2 z-1000"
                  v-model="valueBundlesStatus"
                  tag-placeholder="Статус"
                  placeholder="Статус"
                  label="name"
                  track-by="code"
                  :options="bundlesStatuses"
                  :taggable="true"
                  @tag="addTag"
                ></multiselect>
              </b-col>

              <b-col sm class="px-0 mx-2">
                <multiselect
                  class="mb-2 z-1000"
                  :close-on-select="true"
                  :preserve-search="true"
                  v-model="valueBundlesIds"
                  tag-placeholder="ID связки"
                  placeholder="ID связки"
                  label="name"
                  track-by="code"
                  :options="bundlesIds"
                  :multiple="true"
                  :taggable="true"
                  @tag="changeMultiBundles"
                ></multiselect>
              </b-col>
              <b-col sm class="px-0 mx-2">
                <multiselect
                  :close-on-select="true"
                  :preserve-search="true"
                  class="mb-2 z-1000"
                  v-model="valueCodes"
                  tag-placeholder="Страна"
                  placeholder="Страна"
                  label="name"
                  track-by="code"
                  :options="codes"
                  :multiple="true"
                  :taggable="true"
                  @tag="addTag"
                ></multiselect>
              </b-col>
              <b-col sm class="px-0 mx-2">
                <multiselect
                  class="mb-2 z-1000"
                  :close-on-select="true"
                  :preserve-search="true"
                  v-model="valueSources"
                  tag-placeholder="Источник"
                  placeholder="Источник"
                  label="name"
                  track-by="code"
                  :options="sources"
                  :multiple="true"
                ></multiselect>
              </b-col>
              <b-col sm class="px-0 mx-2">
                <multiselect
                  class="mb-2 z-1000"
                  :close-on-select="true"
                  :preserve-search="true"
                  v-model="valueNetworks"
                  tag-placeholder="Сеть"
                  placeholder="Сеть"
                  label="name"
                  track-by="code"
                  :options="networks"
                  :multiple="true"
                ></multiselect>
              </b-col>
              <b-col sm class="px-0 mx-2">
                <b-button @click="getBundlesApply" variant="primary">
                  Применить</b-button
                >
              </b-col>
            </b-row>

            <ListBundles :dataFilter="dataFilter" :dicts="dicts" />
          </b-skeleton-wrapper>
        </b-container>
      </b-overlay>
    </v-app>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import dictionaries from "../utility/dictionaries_new";
import ListBundles from "../components/bundles-list/bundles-list.vue";

export default {
  components: {
    Multiselect,
    ListBundles,
  },
  data() {
    return {
      /**
       * отобразить скелет вместо таблицы во время загрузки
       */
      loading: true,

      loadAddBundle: false,
      /**
       * Загрузка списка сурсов завершена
       */
      LoadingSource: false,
      /**
       * Загрузка списка стран завершена
       */
      loadingCode: false,
      /**
       * Загрузка списка ИД связок завершена
       */
      LoadingBundlesIds: false,

      stopTraffic: false,
      heightTable: "200px",
      /**
       * Фильтр для таблицы связок
       */
      dataFilter: {
        status: null,
        bundles: null,
        codes: null,
        sources: null,
        /**
         * Должен меняться каждую итерацию
         * чтобы триггерить watch внутри компонента
         */
        apply: false,
      },

      dicts: {
        codes: [],
        sources: [],
        allLanders: [],
        bundlesIds: [],
      },

      //#region переменные фильтров
      valueBundlesStatus: "",
      bundlesStatuses: [
        { name: "Запущена", code: "WORKING" },
        { name: "На паузе", code: "PAUSED" },
      ],

      valueBundlesIds: [],
      bundlesIds: [],

      valueCodes: [],
      codes: [],

      valueSources: [],
      sources: [],
      valueNetworks: [],
      networks: [],
      //#endregion
    };
  },
  watch: {},
  methods: {
    ...dictionaries,

    getBundlesApply() {
      let bundles = this.valueBundlesIds.map((a) => a.code);
      bundles = bundles.length > 0 ? bundles.join(",") : null;
      let codes = this.valueCodes.map((a) => a.code);
      codes = codes.length > 0 ? codes.join(",") : null;
      let sources = this.valueSources.map((a) => a.code);
      sources = sources.length > 0 ? sources.join(",") : null;
      sources = [
        sources ? sources : "",
        this.valueNetworks?.map((a) => a.code)?.join(","),
      ]
        .filter((a) => a)
        .join(",");

      this.dataFilter.status = this.valueBundlesStatus
        ? this.valueBundlesStatus
        : null;
      this.dataFilter.bundles = bundles;
      this.dataFilter.codes = codes;
      this.dataFilter.sources = sources;
      // Необходимо для триггера watch внутри компонента связок даже если нет других изменений в фильтрах
      this.dataFilter.apply = !this.dataFilter.apply;
    },
    addTag(newTag) {
      const tag = newTag
        .split(",")
        .map((a) => {
          return { name: a.trim(), code: a.trim() };
        })
        .filter(
          (f) => this.bundlesIds.map((m) => m.code).indexOf(f.code) != -1
        );

      this.options.push(tag);
      this.value.push(tag);
    },
    changeMultiBundles(v) {
      this.valueBundlesIds = v
        .split(",")
        .map((a) => {
          return { name: a.trim(), code: a.trim() };
        })
        .filter(
          (f) => this.bundlesIds.map((m) => m.code).indexOf(f.code) != -1
        );
    },
    handleResize() {
      this.heightTable = `${window.innerHeight - 280}px`;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  async mounted() {
    this.handleResize();

    this.loading = true;

    this.bundlesIds = await this.getBundlesIds();
    this.bundlesIds = this.bundlesIds.map((a) => {
      return {
        name: a,
        code: a,
      };
    });

    this.codes = await this.getCountries();
    this.codes = this.codes.map((a) => {
      return {
        name: a.country,
        code: a.country,
      };
    });

    this.sources = await this.getSources();

    let networks = this.sources.map((a) => {
      return {
        name: `${a.network_name}`,
        code: a.source_id,
      };
    });
    // Группируем по имени
    networks = networks.reduce((r, a) => {
      r[a.name] = r[a.name] || [];
      r[a.name].push(a);
      return r;
    }, Object.create(null));
    // Конвертируем в формат для select
    networks = Object.keys(networks).map((key) => {
      return {
        name: key,
        code: networks[key].map((a) => a.code).join(","),
      };
    });
    this.networks = networks;

    this.sources = this.sources.map((a) => {
      return {
        name: `${a.source_id} - ${a.name}`,
        code: a.source_id,
      };
    });

    this.optionsCountryForBundle = this.codes;
    this.optionsSourceIdForBundle = this.sources;

    this.loading = false;

    this.allLanders = await this.getAllLanders();
    this.allLanders = this.allLanders.map((a) => {
      return {
        name: a.land,
        code: a.land,
        offer: a.offer,
        land_id: a.land_id,
        tb_id: a.tb_id,
      };
    });

    this.dicts = {
      codes: this.codes,
      sources: this.sources,
      allLanders: this.allLanders,
      bundlesIds: this.bundlesIds,
    };
  },
};
</script>

<style scoped>
h1 {
  font-weight: 100;
}
</style>