<template>
  <div>
    <b-container fluid>
      <h2>
        {{ $route.params.partner }}_{{ $route.params.source }}_{{
          $route.params.country
        }}
      </h2>

      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </template>
        <!-- Сюадв ставляй таблицу b-table -->

        <b-table
          thead-tr-class="d-none"
          bordered
          :fields="renderFields"
          ref="selectableTable"
          style="font-size: 14px"
          striped
          responsive
          small
          :items="renderData"
        >
          <template #cell()="data">
            <div
              :style="`padding: 0.3rem;white-space: nowrap; text-align: center;`"
            >
              {{ data.value }}
            </div>
          </template>

          <template slot="thead-top" slot-scope="{}">
            <b-tr>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                >Рейт:
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                >{{ oldRate }}
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                >Дата
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                >Σ
              </b-th>

              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; height: auto; text-align: center"
                colspan="1"
                v-for="(field, index) in dates"
                :key="index"
                >{{ field }}</b-th
              >
            </b-tr>
            <b-tr>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                >Партнер:
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; height: auto; text-align: center"
                colspan="1"
                >{{ $route.params.partner }}
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                >Подписки:
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                >{{ leads_sum }}
              </b-th>

              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                v-for="(field, index) in leads"
                :key="index"
                >{{ field }}</b-th
              >
            </b-tr>
            <b-tr>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                >Источник:
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                >{{ $route.params.source }}
              </b-th>

              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                >Ожид, доход:
              </b-th>

              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                >{{ income_sum }}
              </b-th>

              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                v-for="(field, index) in income"
                :key="index"
                >{{ field }}</b-th
              >
            </b-tr>
            <b-tr>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                >Страна:
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                >{{ $route.params.country }}
              </b-th>

              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                >Доход с 1к кликов:
              </b-th>

              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                >{{ income_sum_by_k }}
              </b-th>

              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                v-for="(field, index) in income_by_k"
                :key="index"
                >{{ field }}</b-th
              >
            </b-tr>
            <b-tr>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="3"
                >Σ
              </b-th>
              <b-th
                :class="'sticky_h' + (index == 0 ? ' col-4-fix' : ' notfix')"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                v-for="(field, index) in columns_sum"
                :key="index"
                >{{ field }}</b-th
              >
            </b-tr>
          </template>
        </b-table>
      </b-skeleton-wrapper>
    </b-container>
  </div>
</template>
<script>
import axios from "axios";
import { API_AD } from "../constants";
export default {
  data() {
    return {
      renderFields: [],
      renderData: [],
      dates: [],
      leads: [],
      income: [],
      income_by_k: [],
      oldRate: 0,
      leads_sum: 0,
      income_sum: 0,
      income_sum_by_k: 0,
      columns_sum: 0,
      loading: false,
      heightTable: "300px",
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.drawTable();
    this.onResize();
  },
  methods: {
    handleResize() {
      this.heightTable = `${window.innerHeight}px`;
    },
    onResize() {
      // this.heightTableRate = document.getElementById("outer").clientHeight;
    },
    drawTable() {
      this.loading = true;
      const code = String(this.$route.params.country);
      const partner = String(this.$route.params.partner);
      const source_id = Number(this.$route.params.source);
      const from_date = String(this.$route.params.from_date);
      const to_date = String(this.$route.params.to_date);
      return axios
        .get(
          `${API_AD.GET_BUNCH}/code=${code}&source_id=${source_id}&partner=${partner}&from_date=${from_date}&to_date=${to_date}`
        )
        .then((res) => {
          const data = res.data.data.render;
          data.calculated_data = data.calculated_data.map((row) =>
            row.map((cell) => (cell ? cell.money : cell))
          );
          this.dates = data.trafficpacket_dates.map((a) => a.split("T")[0]);
          this.leads = data.trafficpacket_subs;
          this.oldRate = res.data.data.rate;

          this.leads_sum = this.leads.reduce((a, b) => a + b);
          this.income_sum = (this.leads_sum * this.oldRate) / 1000;
          this.income = this.leads.map((a) => (a * this.oldRate) / 1000);

          let field_settings = [
            {
              key: "date",
              tdAttr: { colspan: "3" },
            },
            {
              key: "sum",
            },
          ];
          field_settings = [
            ...field_settings,
            ...data.calculated_data[0].map((a, i) => {
              return { key: "data_" + i };
            }),
          ];

          this.renderData = [];
          this.renderFields = field_settings;
          data.import_dates = data.import_dates.map((a) => a.split("T")[0]);
          for (let index = 0; index < data.import_dates.length; index++) {
            const import_date = data.import_dates[index];
            const row = {
              date: import_date,
              sum: data.calculated_data[index].reduce(
                (a, b) => Number(a) + Number(b)
              ),
            };
            data.calculated_data[index].map((a, i) => (row["data_" + i] = a));
            this.renderData.push(row);
          }

          this.columns_sum = this.renderData.reduce((r, a) => {
            Object.keys(a).forEach(function (b, i) {
              if (b != "date") r[i] = (r[i] || 0) + Number(a[b]);
            });
            return r;
          }, []);
          this.columns_sum = this.columns_sum.slice(1, this.columns_sum.length);
          this.income_by_k = this.columns_sum
            .slice(1, this.columns_sum.length)
            .map((a, i) => this.toMoney((a / this.leads[i]) * 1000));
          this.income_sum_by_k = this.toMoney(
            (this.columns_sum[0] / this.leads_sum) * 1000
          );

          // округление и добавление знака $
          this.income_sum = this.toMoney(this.income_sum);
          this.income = this.income.map((a) => this.toMoney(a));
          this.renderData = this.renderData.map((row) => {
            Object.keys(row).map((a) => {
              row[a] =
                typeof row[a] == "number" ? this.toMoney(row[a]) : row[a];
            });
            return row;
          });
          this.columns_sum = this.columns_sum.map((a) => this.toMoney(a));
          this.loading = false;
        })
        .catch((er) => {
          console.log(er);
          const e = er.response
            ? er.response.data.message
              ? er.response.data.message
              : er.message
              ? er.message
              : JSON.stringify(er)
            : er;
          this.loading = false;
          this.makeToast("Ошибка при получении данных из ОА", e, "danger");
          return false;
        });
    },
    toMoney(num) {
      return "$" + this.round(num);
    },
    round(num) {
      return Math.round(num * 100) / 100;
    },
    //Тост
    makeToast(title, body, variant = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
<style scoped>
::v-deep .table-sm td {
  padding: 0 !important;
}
.sticky_h {
  height: 100px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3 !important;
}
::v-deep .table-sm td {
  padding: 0 !important;
}
::v-deep tr td:first-child {
  left: 0;
  width: 258px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 800 !important;
  background: #f5f5f5;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr td:nth-child(2) {
  left: 258px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 800 !important;
  background: #f5f5f5;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr th:first-child {
  left: 0;
  width: 81px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}
::v-deep tr th:nth-child(2):not(.notfix) {
  left: 81px;
  width: 35px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}
::v-deep tr th:nth-child(3):not(.notfix) {
  left: calc(81px + 35px);
  width: 143px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}
::v-deep tr th:nth-child(4):not(.notfix),
::v-deep tr th.col-4-fix:not(.notfix) {
  left: calc(81px + 35px + 143px);
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}
</style>