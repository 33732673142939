export default {
  stageClick(item) {
    this.modalMoreStage = true;
    this.allPlacements.text = item.placements;
    this.allPlacements.type =
      item.stage_type === "Вайт" ? "Вайт лист" : "Блек лист";
    this.headModalMoreStage = `#${item.num} - кампания #${item.camp_id}`;
    console.log(item);
  },
  hideMOdalMoreStage() {
    this.allPlacements = { text: "", type: "" };
    this.headModalMoreStage = "";
  },
  getHoverId(i, index) {
    this.iHover = index;
  },
}