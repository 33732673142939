export default {
    loadDataBundleFullStages: false,
    bubndleData: [],
    fieldsBundleData: [],
    bubndleLandersFullStages: [],
    fieldsBundleFullStages: [],
    id: 0,
    filter: null,
    filterOn: ["placements"],
    headModalMoreStage: "",
    allPlacements: { text: "", type: "" },
    modalMoreStage: false,
    loadAddBlackList: false,
    iHover: 1,
}