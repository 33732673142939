<template>
  <div>
    <b-overlay spinner-variant="primary" rounded="sm">
      <b-container fluid>
        <h2>Домены</h2>
        <b-button @click="modalAdd = true" variant="primary">
          Купить новый домен</b-button
        >
        <b-button
          @click="modalConfigureDomain = true"
          variant="primary"
          class="ml-2"
        >
          Конфигурация домена</b-button
        >
        <b-button
          @click="modalChangeDomain = true"
          variant="primary"
          class="ml-2"
        >
          Изменить домен</b-button
        >
        <hr />
        <div
          class="text-danger mb-3"
          v-if="min_free_domains - free_domains_count > 0"
        >
          Осталось мало свободных доменов. Зарегистрируйте еще
          {{ min_free_domains - free_domains_count }} доменов
        </div>
        <div
          class="text-danger mb-3"
          v-for="(field, index) in expiring_domains"
          :key="index"
        >
          Срок действия домена {{ field.domain }} истекает через
          {{ field.days }} дней
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="4"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </template>

          <b-table
            ref="selectableTable"
            style="font-size: 14px"
            :fields="all_domains_fields"
            striped
            responsive="xl"
            hover
            small
            :items="all_domains"
          >
          </b-table>

          <b-modal
            @hide="modalConfigureDomain = false"
            v-model="modalConfigureDomain"
            :title="`Конфигурация домена`"
            header-bg-variant="primary"
            header-text-variant="white"
          >
            <b-overlay
              spinner-variant="primary"
              :show="loadConfigure"
              rounded="sm"
            >
              <b-row>
                <b-col sm class="px-0 mx-2">
                  <multiselect
                    v-model="config_domain_name"
                    tag-placeholder="Выберите домен"
                    placeholder="Выберите домен"
                    label="name"
                    :options="all_domains"
                  ></multiselect>
                </b-col>
              </b-row>
            </b-overlay>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="primary"
                  class="float-right mb-2"
                  @click="configureDomain"
                >
                  Создать
                </b-button>
                <b-button
                  variant="secondary"
                  class="float-right mr-2"
                  @click="modalConfigureDomain = false"
                >
                  Отменить
                </b-button>
              </div>
            </template>
          </b-modal>

          <b-modal
            @hide="modalChangeDomain = false"
            v-model="modalChangeDomain"
            :title="`Изменение домена`"
            header-bg-variant="primary"
            header-text-variant="white"
          >
            <b-overlay
              spinner-variant="primary"
              :show="loadConfigure"
              rounded="sm"
            >
              <b-row>
                <b-col sm class="px-0 mx-2">
                  <multiselect
                    v-model="change_old_domain"
                    tag-placeholder="Выберите старый домен"
                    placeholder="Выберите старый домен"
                    label="name"
                    :options="all_domains"
                  ></multiselect>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm class="px-0 mx-2">
                  <multiselect
                    v-model="change_new_domain"
                    tag-placeholder="Выберите новый домен"
                    placeholder="Выберите новый домен"
                    label="name"
                    :options="all_domains"
                  ></multiselect>
                </b-col>
              </b-row>
            </b-overlay>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="primary"
                  class="float-right mb-2"
                  @click="changeDomain"
                >
                  Создать
                </b-button>
                <b-button
                  variant="secondary"
                  class="float-right mr-2"
                  @click="modalChangeDomain = false"
                >
                  Отменить
                </b-button>
              </div>
            </template>
          </b-modal>

          <b-modal
            @hide="modalAdd = false"
            v-model="modalAdd"
            :title="`Покупка нового домена`"
            header-bg-variant="primary"
            header-text-variant="white"
          >
            <b-form-input
              v-model="new_domain_name"
              type="text"
              placeholder="Введите адрес домена "
            ></b-form-input>

            <b-form-input
              v-model="new_domain_period"
              type="number"
              class="mt-2"
              min="1"
              step="1"
              placeholder="На сколько лет?"
            ></b-form-input>

            <p>
              {{
                new_domain_name_available ? "Домен свободен " : "Домен занят"
              }}
              {{
                new_domain_name_cost
                  ? "Стоимость домена: " + new_domain_name_cost
                  : ""
              }}
            </p>

            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="primary"
                  class="float-right mb-2"
                  @click="buyDomain"
                  :disabled="!new_domain_name_available"
                >
                  Купить
                </b-button>
                <b-button
                  variant="secondary"
                  class="float-right mr-2"
                  @click="modalAdd = false"
                >
                  Отменить
                </b-button>
              </div>
            </template>
          </b-modal>
        </b-skeleton-wrapper>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import { API_DOMAINS } from "../constants";
import Multiselect from "vue-multiselect";
import utility_funtions from "../utility/utility-funtions";
import axios from "axios";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: false,
      modalAdd: false,
      modalAddToTracker: false,
      modalConfigureDomain: false,
      modalChangeDomain: false,
      loadConfigure: false,
      expiring_domains: [],
      new_domain_name_cost: 0,
      new_domain_period: 1,
      new_domain_name_available: false,
      config_domain_name: null,
      config_tracker_name: null,
      change_old_domain: null,
      change_new_domain: null,
      new_domain_name: null,
      new_domain_expire_date: (() => {
        function appendLeadingZeroes(n) {
          if (n <= 9) {
            return "0" + n;
          }
          return n;
        }
        const new_date = new Date(
          new Date().setFullYear(new Date().getFullYear() + 1)
        );
        return (
          new_date.getFullYear() +
          "-" +
          appendLeadingZeroes(new_date.getMonth()) +
          "-" +
          new_date.getDate()
        );
      })(),
      all_domains: [],
      all_domains_fields: [],
      free_domains: [],
      min_free_domains: 0,
      free_domains_count: 0,
      show_min_free_domains_msg: false,
    };
  },
  mounted() {
    this.getFreeDomains();
    this.getUsageDomains();
    this.getMinFreeDomains();
  },
  watch: {
    new_domain_name(v) {
      if (
        /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/g.test(v)
      ) {
        this.checkDomain();
      }
    },
  },
  methods: {
    ...utility_funtions,
    changeDomain() {
      if (!this.change_old_domain || !this.change_new_domain) {
        this.makeToast(
          "Ошибка изменения доменов",
          "Не выбраны старый и новый домены для изменения",
          "danger"
        );
        return;
      }
      if (this.change_old_domain?.name == this.change_new_domain?.name) {
        this.makeToast(
          "Ошибка изменения доменов",
          "Выбран один и тот же домен для изменения",
          "danger"
        );
        return;
      }
      axios
        .post(API_DOMAINS.CHANGE_DOMAIN, {
          old: this.change_old_domain?.name,
          new: this.change_new_domain?.name,
        })
        .then(() => this.makeToast("Успех", "Домен успешно изменен", "success"))
        .catch((err) => {
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.makeToast("Ошибка изменения доменов", e, "danger");
        });
    },
    checkDomain() {
      if (!this.new_domain_name) {
        return;
      }
      axios
        .get(API_DOMAINS.REG + "/" + this.new_domain_name)
        .then((data) => {
          data = data.data;
          // {"status_code": 0,"domain": "fodozu.com","isFree": "yes","cost": "7.99 in USD"}
          this.new_domain_name_available = data.isFree != "no" ? 1 : 0;
          this.new_domain_name_cost = data.cost ? data.cost : 0;
        })
        .catch((err) => {
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.makeToast("Ошибка проверки доменов", e, "danger");
        });
    },
    getFreeDomains() {
      axios
        .get(API_DOMAINS.GET_FREE)
        .then((data) => {
          this.free_domains = data.data.map((a) => {
            return { name: a.name, code: a.name };
          });
        })
        .catch((err) => {
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.makeToast("Ошибка получения свободных доменов", e, "danger");
        });
    },
    getMinFreeDomains() {
      axios
        .get(API_DOMAINS.GET_FREE_DOMAINS_MIN_COUNT)
        .then((data) => {
          this.min_free_domains = data.data;
        })
        .catch((err) => {
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.makeToast(
            "Ошибка получения необходимого количества свободных доменов",
            e,
            "danger"
          );
        });
    },
    getUsageDomains() {
      axios
        .get(API_DOMAINS.GET_ALL)
        .then((data) => {
          // console.log(data.data);
          this.all_domains = data.data.map((a) => {
            return {
              status: a.status == 0 ? "Создается" : "Создан",
              name: a.name,
              create_date: new Date(a.create_date).toDateString(),
              expire_date: new Date(a.expire_date).toDateString(),
              tracker: a.tracker,
              start_date: a.start_date
                ? new Date(a.start_date).toDateString()
                : "",
              end_date: a.end_date ? new Date(a.end_date).toDateString() : "",
            };
          });

          this.expiring_domains = this.all_domains
            .filter(
              (a) =>
                new Date(new Date(a.expire_date) - new Date()).getTime() /
                  (1000 * 60 * 60 * 24) <=
                14
            )
            .map((a) => {
              return {
                domain: a.name,
                days: Math.round(
                  new Date(new Date(a.expire_date) - new Date()).getTime() /
                    (1000 * 60 * 60 * 24)
                ),
              };
            });

          this.free_domains_count = this.all_domains.filter(
            (a) => !a.tracker
          ).length;

          this.all_domains_fields = Object.keys(data.data[0]).map((a) => {
            let title = "";
            if (a === "name") title = "Домен";
            else if (a === "status") title = "Статус";
            else if (a === "create_date") title = "Зарегистрирован";
            else if (a === "expire_date") title = "Дата истечения домена";
            else if (a === "tracker") title = "Использован на трекере";
            else if (a === "start_date") title = "Начало использования";
            else if (a === "end_date") title = "Окончание использования";
            return { label: title, key: a };
          });
        })
        .catch((err) => {
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.makeToast("Ошибка получения всех доменов", e, "danger");
        });
    },
    addDomain() {
      if (!this.new_domain_name || !this.new_domain_expire_date) {
        this.makeToast("Форма не заполнена!", "Заполните форму", "danger");
        this.modalAdd = false;
        return;
      }
      axios
        .post(API_DOMAINS.REG, {
          name: this.new_domain_name,
        })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err);
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.makeToast("Ошибка добавления домена", e, "danger");
        });
    },
    buyDomain() {
      if (!this.new_domain_name || !this.new_domain_expire_date) {
        this.makeToast("Форма не заполнена!", "Заполните форму", "danger");
        this.modalAdd = false;
        return;
      }
      axios
        .post(
          API_DOMAINS.REG +
            "/" +
            this.new_domain_name +
            "/" +
            this.new_domain_period
        )
        .then((data) => {
          console.log(data);
          this.makeToast(
            "Покупка домена",
            data.data.status,
            data.data.status_code == 0 ? "success" : "danger"
          );
          this.modalAdd = false;
          // location.reload();
        })
        .catch((err) => {
          console.log(err);
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.modalAdd = false;
          this.makeToast("Ошибка покупки домена", e, "danger");
        });
    },
    configureDomain() {
      if (!this.config_domain_name) {
        this.makeToast("Форма не заполнена!", "Заполните форму", "danger");
        this.modalConfigureDomain = false;
        return;
      }
      this.loadConfigure = true;
      axios
        .post(API_DOMAINS.CONFIGURE, {
          domain: this.config_domain_name.name,
        })
        .then((data) => {
          console.log(data);
          this.modalConfigureDomain = false;
          this.loadConfigure = false;
        })
        .catch((err) => {
          console.log(err);
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.makeToast("Ошибка конфигурации домена", e, "danger");
          this.modalConfigureDomain = false;
          this.loadConfigure = false;
        });
    },
  },
};
</script>

<style scoped>
</style>