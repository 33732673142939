<template>
  <div>
    <b-overlay spinner-variant="primary" rounded="sm">
      <b-container fluid>
        <h2>Технический раздел</h2>
        <hr />
        <h4>Расчеты тестов</h4>
        <br>
        <b-skeleton-wrapper class="mt-4" :loading="loadTestsCalculations">
          <template #loading>
            <b-form inline>
              <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
            </b-form>
            <b-skeleton-table
              :rows="5"
              :columns="4"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </template>

          <b-button
            @click="modalAddTestCalculation = true"
            variant="outline-success"
            class="mr-2 mb-3"
            >Добавить
          </b-button>

          <div class="mt-4" v-if="tests.length === 0">
            <p class="text-center">Ничего не найдено.</p>
          </div>

          <b-table
            v-if="tests.length != 0"
            ref="selectableTable"
            style="font-size: 14px"
            :sticky-header="heightTable"
            :fields="fields"
            striped
            responsive="xl"
            hover
            small
            :items="tests"
          >
            <template #cell()="data">
              <div style="white-space: nowrap">
                {{ data.value }}
              </div>
            </template>
            <template #head()="data">
              <div style="white-space: nowrap">{{ data.label }}</div>
            </template>
            <template #cell(action)="data">
              <div style="width: 80px">
                <b-button
                  size="sm"
                  class="mr-1"
                  @click="
                    () => {
                      modalApplyRemoveTestCalculation = true;
                      idToRemove = data.item.id;
                    }
                  "
                  variant="danger"
                >
                  <b-icon icon="x-circle-fill" aria-hidden="true"></b-icon
                ></b-button>

                <b-button
                  size="sm"
                  class="mr-1"
                  :disabled="data.item.end_time !== null"
                  @click="
                    () => {
                      modalStopTestCalculation = true;
                      idToStop = data.item.id;
                    }
                  "
                >
                  <div v-if="data.item.end_time === null">
                    <b-icon icon="pause-fill" aria-hidden="true"></b-icon>
                  </div>
                  <div v-else>
                    <b-icon icon="play-fill" aria-hidden="true"></b-icon>
                  </div>
                </b-button>
              </div>
            </template>
          </b-table>
        </b-skeleton-wrapper>

        <b-modal
          @hide="resetAddTestCalculationForm"
          v-model="modalAddTestCalculation"
          :title="`Добавление расчета`"
        >
          <multiselect
            class="mb-3"
            v-model="valueBundleId"
            tag-placeholder="ID связки"
            placeholder="ID связки"
            label="name"
            track-by="code"
            :options="bundlesIds"
            :multiple="false"
            :taggable="true"
          ></multiselect>
          <b-form-input
            type="text"
            v-model="valueComment"
            placeholder="Комментарий"
            class="mb-3"
          ></b-form-input>
          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="primary"
                class="float-right"
                @click="addTestCalculation"
              >
                Добавить
              </b-button>
              <b-button
                variant="secondary"
                class="float-right mr-2"
                @click="resetAddTestCalculationForm"
              >
                Отменить
              </b-button>
            </div>
          </template>
        </b-modal>

        <b-modal
          v-model="modalApplyRemoveTestCalculation"
          :title="`Удаление теста`"
          header-bg-variant="warning"
        >
          <p>Подтверждаете удаление?</p>

          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="primary"
                class="float-right"
                @click="removeTestCalculation"
              >
                Подтверждаю
              </b-button>
              <b-button
                variant="secondary"
                class="float-right mr-2"
                @click="modalApplyRemoveTestCalculation = false"
              >
                Нет
              </b-button>
            </div>
          </template>
        </b-modal>


        <b-modal
          v-model="modalStopTestCalculation"
          :title="`Остановить`"
          header-bg-variant="warning"
        >
          <p>Подтверждаете остановку?</p>

          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="primary"
                class="float-right"
                @click="stopTestCalculation"
              >
                Подтверждаю
              </b-button>
              <b-button
                variant="secondary"
                class="float-right mr-2"
                @click="modalStopTestCalculation = false"
              >
                Нет
              </b-button>
            </div>
          </template>
        </b-modal>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import utility_funtions from "../utility/utility-funtions";
import dictionaries from "../utility/dictionaries";
import { API_BUNDLES } from "../constants";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "axios";

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      loadTestsCalculations: false,

      modalAddTestCalculation: false,
      modalStopTestCalculation: false,
      modalApplyRemoveTestCalculation: false,

      idToRemove: null,
      idToStop: null,

      bundlesIds: [],

      tests: [],
      fields: [
        { key: "action", label: "Действие", stickyColumn: true },
        { key: "bundle_id", label: "ИД связки" },
        { key: "start_time", label: "Время начала" },
        { key: "end_time", label: "Время окончания" },
        { key: "comment", label: "Комментарий" },
      ],

      valueBundleId: '',
      valueComment: '',

      heightTable: "200px",
    };
  },
  mounted() {
    this.getBundlesIds();
    this.getTestsCalculations();
    this.handleResize();
  },

  created() {
    window.addEventListener("resize", this.handleResize);
  },

  methods: {
    ...utility_funtions,
    ...dictionaries,

    getTestsCalculations() {
      this.loadTestsCalculations = true;
      axios
        .get(API_BUNDLES.TEST_CALCULATION)
        .then((res) => {
          const d = res.data;
          this.tests = d.map((a) => {
            return {
              ...a,
              start_time: new Date(a.start_time)
                .toLocaleString("ru-RU")
                .split(", ")
                .join(" "),
              end_time: a.end_time != null ? new Date(a.end_time)
                .toLocaleString("ru-RU")
                .split(", ")
                .join(" ") : null,
            };
          });
        })
        .catch((er) => {
          const e = "response" in er ? er.response.data.message : "";
          this.makeToast("Ошибка изменения параметра", e, "danger");
        })
        .finally(() => {
          this.loadTestsCalculations = false;
        });
    },


    addTestCalculation() {
      const body = {
        bundle_id: Number(this.valueBundleId.code),
        comment: this.valueComment
      };
      axios
      .post(
        API_BUNDLES.TEST_CALCULATION, JSON.stringify(body),
        { headers: { "Content-type": "application/json", } }
      )
      .then(() => {
        this.getTestsCalculations();
        this.makeToast("Успех!", "Расчет был добавлен", "success")
      })
      .catch((er) => {
        const e = "response" in er ? er.response.data.message : "";
        this.makeToast("Ошибка изменения параметра", e, "danger");
      })
      .finally(() => {
        this.modalAddTestCalculation = false;
      })
    },

    removeTestCalculation() {
      axios
        .put(API_BUNDLES.TEST_CALCULATION + "/" + this.idToRemove)
        .then(() => {
          this.getTestsCalculations();
          this.makeToast(
            "Удалено",
            "Расчет по связке удален.",
            "success"
          );
        })
        .catch((er) => {
          const e = "response" in er ? er.response.data.message : "";
          this.makeToast("Что-то пошло не так!", e, "danger");
        })
        .finally(() => {
          this.idToRemove = null;
          this.modalApplyRemoveTestCalculation = false;
        });
    },


    stopTestCalculation() {
      axios
        .get(API_BUNDLES.TEST_CALCULATION_STOP + '/' + this.idToStop)
        .then(() => {
          this.getTestsCalculations();
          this.makeToast(
            "Остановлено",
            "Расчет минусовости был остановлен.",
            "success"
          );
        })
        .catch((er) => {
          const e = "response" in er ? er.response.data.message : "";
          this.makeToast("Что-то пошло не так!", e, "danger");
        })
        .finally(() => {
          this.idToStop = null;
          this.modalStopTestCalculation = false;
        })
    },

    resetAddTestCalculationForm() {
      this.modalAddTestCalculation = false;
      this.valueBundleId = '';
      this.valueComment = '';
    },

    handleResize() {
      this.heightTable = `${window.innerHeight - 280}px`;
    },
  },
};
</script>

<style scoped></style>
