<template>
  <div>
    <b-container fluid>
      <h2>Рейты</h2>
      <hr />
      <b-skeleton-wrapper :loading="loadingFilter">
        <template #loading>
          <b-form class="mt-4" inline>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
          </b-form>
        </template>

        <b-row class="mt-2">
          <b-col>
            <b-button @click="showAddCustomRateModal = true" variant="primary"
              >Добавить кастомный рейт</b-button
            >
          </b-col>
        </b-row>

        <b-row class="d-block">
          <b-skeleton-wrapper class="mt-4" :loading="loadCustomRates">
            <template #loading>
              <b-skeleton-table
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </template>
            <div class="mt-4 mb-4" v-if="custom_rates.length === 0">
              <h1 class="text-center">
                Не найдено ни одного кастомного рейта!
              </h1>
            </div>

            <v-data-table
              v-if="custom_rates.length != 0"
              dense
              disable-pagination
              :headers="custom_rates_fields"
              :items="custom_rates"
              :height="heightTable"
              item-key="value"
              hide-default-footer
              fixed-header
              class="elevation-1 m-3 table-bordered"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <div
                  style="
                    white-space: nowrap;
                    padding-top: 5px;
                    padding-bottom: 5px;
                  "
                >
                  <b-button
                    @click="removeCustomRate(item)"
                    size="sm"
                    class="mr-1"
                    :variant="'danger'"
                  >
                    <b-icon
                      font-scale="0.97"
                      :icon="'trash-fill'"
                      aria-hidden="true"
                    ></b-icon>
                  </b-button>
                </div>
              </template>
            </v-data-table>
          </b-skeleton-wrapper>
        </b-row>

        <!-- Модальаное окно добавления кастомного рейта -->
        <b-modal
          v-model="showAddCustomRateModal"
          :title="`Создание кастомного рейта`"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-col sm class="px-0 mx-2">
            <multiselect
              class="mb-2"
              v-model="newCustomRateCountry"
              tag-placeholder="Страна"
              placeholder="Страна"
              label="name"
              track-by="code"
              :options="codes"
              :multiple="true"
              :taggable="true"
            ></multiselect>
          </b-col>
          <b-col sm class="px-0 mx-2">
            <multiselect
              class="mb-2"
              v-model="newCustomRatePartner"
              tag-placeholder="Оффер"
              placeholder="Оффер"
              label="name"
              track-by="code"
              :options="partners"
              :multiple="true"
              :taggable="true"
            ></multiselect>
          </b-col>
          <b-col sm class="px-0 mx-2">
            <multiselect
              class="mb-2"
              v-model="newCustomRateSource"
              tag-placeholder="Источник"
              placeholder="Источник"
              label="name"
              track-by="code"
              :options="sources"
              :multiple="true"
              :taggable="true"
            ></multiselect>
          </b-col>

          <b-form-input
            class="mb-3"
            type="number"
            step="0.001"
            min="0.001"
            v-model="newCustomRate"
            placeholder="Введите желаемый рейт"
          ></b-form-input>
          <date-picker
            class="mb-3"
            value-type="YYYY-MM-DD"
            placeholder="Дата окончания рейта"
            v-model="newCustomRateDateEnd"
            style="width: 100%"
          ></date-picker>
          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="primary"
                class="float-right mr-2"
                @click="submitAddCustomRateForm"
              >
                Ok
              </b-button>
            </div>
          </template>
        </b-modal>
      </b-skeleton-wrapper>
    </b-container>
  </div>
</template>

<script>
import dictionaries from "../utility/dictionaries";
import utility_funtions from "../utility/utility-funtions";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "axios";
import { API_AD } from "../constants";
export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      rows_count: 0,
      offers: [],
      partners: [],
      valuePartner: [],
      valueCodes: [],
      codes: [],
      valueSources: [],
      sources: [],
      loadingFilter: true,
      showAddCustomRateModal: false,
      currentPage: 1,
      totalRows: 200,
      heightTable: "300px",
      loadCustomRates: false,
      custom_rates: [],
      custom_rates_fields: [
        {
          text: "Страна",
          value: "code",
          width: "60px",
          class: "headBackground",
        },
        {
          text: "Партнер",
          value: "partner_name",
          width: "100px",
          class: "headBackground",
        },
        {
          text: "ИД сурса",
          value: "source_id",
          width: "60px",
          class: "headBackground",
        },
        {
          text: "Кастомный рейт",
          value: "new_rate",
          width: "100px",
          class: "headBackground",
        },
        {
          text: "Дата окончания",
          value: "date_end",
          width: "100px",
          class: "headBackground",
        },
        {
          text: "",
          value: "actions",
          width: "20px",
          sortable: false,
          class: "headBackground",
        },
      ],
      newCustomRateCountry: null,
      newCustomRatePartner: null,
      newCustomRateSource: null,
      newCustomRate: null,
      newCustomRateDateEnd: null,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    // window.addEventListener("resize", this.onResize);
  },
  mounted() {
    this.getCountries();
    this.getSources();
    this.getOffers();
    this.handleResize();
    this.getCustomRates();
  },
  methods: {
    ...dictionaries,
    ...utility_funtions,
    removeCustomRate(item) {
      console.log(
        `${API_AD.REMOVE_CUSTOM_RATE}?code=${item.code}&source_id=${item.source_id}&partner=${item.partner_name}`
      );
      axios
        .delete(
          `${API_AD.REMOVE_CUSTOM_RATE}?code=${item.code}&source_id=${item.source_id}&partner=${item.partner_name}`
        )
        .then((data) => {
          console.log(data);
          this.getCustomRates();
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка get custom rates", e, "danger");
        });
    },
    getCustomRates() {
      this.loadCustomRates = true;
      axios
        .get(API_AD.GET_CUSTOM_RATES)
        .then((resp) => {
          this.custom_rates = resp.data.data.map((a) => {
            return {
              code: a.country,
              partner_name: a.offer,
              source_id: a.source_id,
              new_rate: a.rate,
              date_end: a.end_date.split("T")[0],
            };
          });
          this.loadCustomRates = false;
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка get custom rates", e, "danger");
        });
    },
    submitAddCustomRateForm() {
      this.showAddCustomRateModal = false;
      const headers = { "Content-type": "application/json" };
      const body = {
        countries: this.newCustomRateCountry.map((a) => a.code),
        partners: this.newCustomRatePartner.map((a) => a.code),
        sources: this.newCustomRateSource.map((a) => Number(a.code)),
        rate: Number(this.newCustomRate),
        date_end: String(this.newCustomRateDateEnd),
      };
      console.log(body, API_AD.ADD_CUSTOM_RATE);
      if (
        !body.countries ||
        body.countries.length == 0 ||
        !body.partners ||
        body.partners.length == 0 ||
        !body.sources ||
        body.sources.length == 0
      ) {
        this.makeToast("Форма не заполнена!", "Заполните форму", "danger");
        this.showAddCustomRateModal = false;
        return;
      }
      axios
        .post(API_AD.ADD_CUSTOM_RATE, body, headers)
        .then((data) => {
          console.log(data);
          this.makeToast("Кастомные рейты успешно добавлены", "", "success");
          this.getCustomRates();
        })
        .catch((er) => {
          console.log(er);
          this.makeToast("Ошибка добавления кастомных рейтов", er, "danger");
        });
    },
    selectPagination(v) {
      this.currentPage = v;
    },
    //Изменпие размера
    handleResize() {
      this.heightTable = `${window.innerHeight - 280}px`;
    },
  },
  watch: {
    currentPage() {
      this.getRates();
    },
    offers(offers) {
      this.partners = [...new Set(offers.map((a) => a.code.slice(-2)))].map(
        (o) => {
          return { code: o, name: o };
        }
      );
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: 100;
}
::v-deep .offerClass {
  left: 35px !important;
}
::v-deep .defaultRateClass {
  left: calc(30px + 70px) !important;
}

::v-deep .table-header thead {
  background-color: black;
}

::v-deep .itemDatatBale {
  color: red !important;
}

::v-deep tr td {
  z-index: 800 !important;
}

::v-deep tr td:first-child {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 800 !important;
  background: #f5f5f5;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr td:nth-child(2) {
  left: 60px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 800 !important;
  background: #f5f5f5;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr td:nth-child(3) {
  left: 160px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 800 !important;
  background: #f5f5f5;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr th:first-child {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr th:nth-child(2) {
  left: 60px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr th:nth-child(3) {
  left: 160px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep .headBackground {
  background: #f5f5f5 !important;
  border: 1px solid #dee2e6;
  top: -2px !important;
}

.z-1000 {
  z-index: 1000 !important;
}
.headBackground {
  top: -1px;
}
</style>
