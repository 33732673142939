import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import { IconsPlugin } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import router from './routing/router'
import store from './store'
import axios from 'axios'
import vuetify from '@/plugins/vuetify' // path to vuetify export

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

axios.interceptors.response.use(function(response) {
    return response
  }, function(error) {
    if (403 === error.response?.status && store.getters.isLoggedIn) {
      store.dispatch('logout').then(() => {
        router.push({ name: 'login' });
      })
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(function(config) {
  if (store.getters.isLoggedIn) {
    const token = store.state.token;
    config.headers.Authorization =  'Bearer ' + token;
  }
  return config;
});

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(IconsPlugin);
Vue.use(axios);

new Vue({
  vuetify,
  render: h => h(App),
  router,
  store
}).$mount('#app')
