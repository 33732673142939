var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticStyle:{"min-height":"calc(100vh - 70px) !important"},attrs:{"no-body":""}},[_c('div',{staticClass:"d-flex justify-space-around",staticStyle:{"height":"calc(100vh - 70px)"}},[_c('div',[_c('h5',{staticClass:"pt-3 pl-3 mb-2"},[_vm._v("Redis")]),_c('v-treeview',{staticStyle:{"font-size":"14px","width":"280px"},attrs:{"dense":"","hoverable":"","activatable":"","transition":"","open-on-click":"","items":_vm.treeviewItems,"load-children":_vm.fetchNetworkNames},on:{"update:active":_vm.onTreeviewChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}])})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticStyle:{"width":"calc(100vw - 260px)","padding-left":"1px"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 2),expression:"active === 2"}]},[_c('v-data-table',{attrs:{"dense":"","hide-default-header":"","loading":_vm.ibTableLoading,"headers":_vm.ibTableHeaders,"items":_vm.ibTableItems},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('b-tr',[_c('b-th',{staticStyle:{"width":"150px","text-align":"center"},attrs:{"rowspan":"2"}},[_vm._v(" Название сети ")]),_c('b-th',{staticStyle:{"border-right":"none !important"}},[_vm._v(" Данные ")]),_c('b-th',{staticStyle:{"border-left":"none !important"}})],1),_c('b-tr',[_c('b-th',{staticStyle:{"width":"200px"}},[_vm._v(" Поле ")]),_c('b-th',[_vm._v(" Значение ")])],1)],1)]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('p',{directives:[{name:"show",rawName:"v-show",value:(items.length === 0),expression:"items.length === 0"}],staticClass:"pt-3 pl-5"},[_vm._v("Нет данных")]),_c('b-tbody',[_vm._l((items),function(item,index){return [_c('b-tr',{key:'name' + index,class:index&1 ? 'darker-column' : ''},[_c('td',{staticClass:"primary-column",attrs:{"rowspan":Object.keys(item.data).length}},[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(_vm.translate(Object.keys(item.data)[0])))]),_c('td',[_vm._v(_vm._s(item.data[Object.keys(item.data)[0]]))])]),_vm._l((Object.keys(item.data)),function(key,i){return [_c('b-tr',{directives:[{name:"show",rawName:"v-show",value:(key != Object.keys(item.data)[0]),expression:"key != Object.keys(item.data)[0]"}],key:'data' + index + i,class:index&1 ? 'darker-column' : ''},[_c('td',[_vm._v(_vm._s(_vm.translate(key)))]),(key !== 'data')?_c('td',[_vm._v(" "+_vm._s(item.data[key])+" ")]):_c('td',[_c('div',{staticClass:"data-cell",on:{"click":function($event){return _vm.dataCellClickHandler(item.data[key])}}},[_vm._v(" "+_vm._s(item.data[key])+" ")])])])]})]})],2)]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 3),expression:"active === 3"}]},[_c('v-data-table',{attrs:{"dense":"","hide-default-header":"","loading":_vm.pbTableLoading,"headers":_vm.pbTableHeaders,"items":_vm.pbTableItems},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('b-tr',[_c('b-th',{staticStyle:{"width":"150px","text-align":"center"},attrs:{"rowspan":"2"}},[_vm._v(" ИД связки ")]),_c('b-th',{staticStyle:{"border-right":"none !important"}},[_vm._v(" Данные ")]),_c('b-th',{staticStyle:{"border-left":"none !important"}})],1),_c('b-tr',[_c('b-th',{staticStyle:{"width":"200px"}},[_vm._v(" Поле ")]),_c('b-th',[_vm._v(" Значение ")])],1)],1)]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('p',{directives:[{name:"show",rawName:"v-show",value:(items.length === 0),expression:"items.length === 0"}],staticClass:"pt-3 pl-5"},[_vm._v("Нет данных")]),_c('b-tbody',[_vm._l((items),function(item,index){return [_c('b-tr',{key:'name' + index,class:index&1 ? 'darker-column' : ''},[_c('td',{staticClass:"text-center",attrs:{"rowspan":Object.keys(item.data).length}},[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(_vm.translate(Object.keys(item.data)[0])))]),_c('td',[_vm._v(_vm._s(item.data[Object.keys(item.data)[0]]))])]),_vm._l((Object.keys(item.data)),function(key,i){return [_c('b-tr',{directives:[{name:"show",rawName:"v-show",value:(key != Object.keys(item.data)[0]),expression:"key != Object.keys(item.data)[0]"}],key:'data' + index + i,class:index&1 ? 'darker-column' : ''},[_c('td',[_vm._v(_vm._s(_vm.translate(key)))]),(key !== 'data')?_c('td',[_vm._v(" "+_vm._s(item.data[key])+" ")]):_c('td',[_c('div',{staticClass:"data-cell",on:{"click":function($event){return _vm.dataCellClickHandler(item.data[key])}}},[_vm._v(" "+_vm._s(item.data[key])+" ")])])])]})]})],2)]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 4),expression:"active === 4"}]},[_c('v-data-table',{attrs:{"dense":"","hide-default-header":"","items":_vm.sbTableItems,"headers":_vm.sbTableHeaders,"loading":_vm.sbTableLoading},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',[_c('b-tr',_vm._l((props.headers),function(head,index){return _c('b-th',{key:head.text + index,class:head.value !== 'data' ? 'text-center' : ''},[_vm._v(_vm._s(head.text))])}),1)],1)]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('p',{directives:[{name:"show",rawName:"v-show",value:(items.length === 0),expression:"items.length === 0"}],staticClass:"pt-3 pl-5"},[_vm._v("Нет данных")]),_c('b-tbody',[_vm._l((items),function(item,index){return [_c('b-tr',{key:'ud' + index},_vm._l((item),function(v,k,i){return _c('td',{key:'ud' + index + i},[(k !== 'data')?[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(v)+" ")])]:[_c('div',{staticClass:"data-cell",on:{"click":function($event){return _vm.dataCellClickHandler(v)}}},[_vm._v(" "+_vm._s(v)+" ")])]],2)}),0)]})],2)]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 6 || _vm.active === 7 || _vm.active === 8 || _vm.active === 10),expression:"active === 6 || active === 7 || active === 8 || active === 10"}]},[_c('v-data-table',{attrs:{"dense":"","hide-default-header":"","items":_vm.tableItems,"headers":_vm.tableHeaders,"loading":_vm.tableLoading},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',[_c('b-tr',_vm._l((props.headers),function(head,index){return _c('b-th',{key:head.text + index,style:(index < props.headers.length - 1 ? 'width: 220px;' : '')},[_vm._v(_vm._s(head.text))])}),1)],1)]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('p',{directives:[{name:"show",rawName:"v-show",value:(items.length === 0),expression:"items.length === 0"}],staticClass:"pt-3 pl-5"},[_vm._v("Нет данных")]),_c('b-tbody',[_vm._l((items),function(item,index){return [_c('b-tr',{key:'conv_tb' + index},_vm._l((item),function(v,k,i){return _c('td',{key:'conv_tb' + index + i},[_c('div',{class:k === 'data' ? 'data-cell' : ''},[_vm._v(" "+_vm._s(v)+" ")])])}),0)]})],2)]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 9),expression:"active === 9"}]},[_c('v-data-table',{attrs:{"dense":"","show-expand":"","hide-default-header":"","loading":_vm.udTableLoading,"headers":_vm.udTableHeaders,"items":_vm.udTableItems},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('b-tr',[_c('b-th',{staticStyle:{"width":"150px","text-align":"center"},attrs:{"rowspan":"2"}},[_vm._v(" Тип ")]),_c('b-th',{staticStyle:{"border-right":"none !important"}},[_vm._v(" Данные ")]),_c('b-th',{staticStyle:{"border-left":"none !important"}})],1),_c('b-tr',[_c('b-th',{staticStyle:{"width":"200px"}},[_vm._v(" Поле ")]),_c('b-th',[_vm._v(" Значение ")])],1)],1)]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('p',{directives:[{name:"show",rawName:"v-show",value:(items.length === 0),expression:"items.length === 0"}],staticClass:"pt-3 pl-5"},[_vm._v("Нет данных")]),_c('b-tbody',[_vm._l((items),function(item,index){return [_c('b-tr',{key:'name' + index,class:index&1 ? 'darker-column' : ''},[_c('td',{staticClass:"text-center",attrs:{"rowspan":Object.keys(item.data).length}},[_vm._v(_vm._s(item.type))]),_c('td',[_vm._v(_vm._s(_vm.translate(Object.keys(item.data)[0])))]),_c('td',[_vm._v(_vm._s(item.data[Object.keys(item.data)[0]]))])]),_vm._l((Object.keys(item.data)),function(key,i){return [_c('b-tr',{directives:[{name:"show",rawName:"v-show",value:(key != Object.keys(item.data)[0]),expression:"key != Object.keys(item.data)[0]"}],key:'data' + index + i,class:index&1 ? 'darker-column' : ''},[_c('td',[_vm._v(_vm._s(_vm.translate(key)))]),_c('td',[_c('div',{class:key === 'data' ? 'data-cell' : ''},[_vm._v(" "+_vm._s(item.data[key])+" ")])])])]})]})],2)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" More info about "+_vm._s(item.name)+" ")])]}}])})],1)])],1)]),_c('b-modal',{attrs:{"title":"Подробнее","size":"lg","header-bg-variant":"primary","header-text-variant":"white","ok-only":""},on:{"hide":_vm.dataCellModalHide},model:{value:(_vm.dataCellModal),callback:function ($$v) {_vm.dataCellModal=$$v},expression:"dataCellModal"}},[_c('b-table',{attrs:{"small":"","striped":"","responsive":"sm","items":_vm.dataCellItem},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_vm._v(" "+_vm._s(_vm.translate(data.column))+" ")]}},{key:"cell(placements)",fn:function(row){return [(row.item.placements != '')?[_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":row.detailsShowing ? 'danger' : ''},on:{"click":function($event){return _vm.toggleDetails(row)}}},[_vm._v(" "+_vm._s(row.detailsShowing ? 'Скрыть' : 'Показать')+" площадки ")])]:_vm._e()]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"type":"search","id":"filterInput","placeholder":"Введите ID площадки "},model:{value:(_vm.dataCellFilter),callback:function ($$v) {_vm.dataCellFilter=$$v},expression:"dataCellFilter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.dataCellFilter},on:{"click":function($event){_vm.dataCellFilter = ''}}},[_vm._v("Очистить")])],1)],1),_c('b-table',{attrs:{"per-page":_vm.dataCellPerPage,"current-page":_vm.dataCellCurrentPage,"filter":_vm.dataCellFilter,"filter-included-fields":['id_placement'],"primary-key":"id_placement","fields":[
                { value: 'id_placement', label: 'ID площадки' }
              ],"items":row.item.placements.split(',').map(function (a) {
                return { id_placement: a }
              })},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_vm._v(" "+_vm._s(_vm.translate(data.column))+" ")]}}],null,true)}),_c('b-pagination',{attrs:{"total-rows":row.item.placements.split(',').length || 0,"per-page":_vm.dataCellPerPage,"aria-controls":"my-table","pills":"","align":"center"},model:{value:(_vm.dataCellCurrentPage),callback:function ($$v) {_vm.dataCellCurrentPage=$$v},expression:"dataCellCurrentPage"}})],1)]}}]),model:{value:(_vm.dataCellItems),callback:function ($$v) {_vm.dataCellItems=$$v},expression:"dataCellItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }