<template>
  <div>
    <b-container fluid>
      <h2>Отчет по связке #{{ $route.params.id }}</h2>
      <hr />
      <ReportInfo :dataFilter="dataFilter" />
      <Archive />
    </b-container>
  </div>
</template>

<script>
import ReportInfo from "./../components/report/report.vue";
import Archive from "./../components/bundle-archive/bundle-archive.vue";
export default {
  data() {
    return {
      dataFilter: {
        message: null,
        /**
         * Должен меняться каждую итерацию
         * чтобы триггерить watch внутри компонента
         */
        apply: false,
      },
    };
  },
  components: {
    ReportInfo,
    Archive,
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>

<style>
h1 {
  font-weight: 100;
}
</style>