export const HOST = process.env.VUE_APP_HOST;
export const CLUSTER_DOMAIN = process.env.VUE_APP_CLUSTER_DOMAIN;

export const HOSTS = {
    BUNDLES_SERVICE: `https://bundles.${CLUSTER_DOMAIN}/`,
    DOMAIN_SERVICE: `https://domains.${CLUSTER_DOMAIN}/`,
    BQ_ROUTES_SERVICE: `https://bq-routes.${CLUSTER_DOMAIN}/`,
    ANALITICS_SERVICE: `https://analytics.${CLUSTER_DOMAIN}/`,
    REDIS_ROUTES: `https://redis-routes.${CLUSTER_DOMAIN}/`
};

export const SPLIT_TEST = {
    GET: `${HOSTS.BUNDLES_SERVICE}api/splits/`,
}

export const API_PARTNERS = {
    GET: `${HOSTS.BUNDLES_SERVICE}api/offers/`
}

export const API_DOMAINS = {
    GET_FREE: `${HOSTS.DOMAIN_SERVICE}api/domains/free`,
    GET_FREE_DOMAINS_MIN_COUNT: `${HOSTS.DOMAIN_SERVICE}api/domains/free/mincount`,
    GET_ALL: `${HOSTS.DOMAIN_SERVICE}api/domains/getAll`,
    GET_ALL_USAGE: `${HOSTS.DOMAIN_SERVICE}api/domains/getAllUsage`,
    ADD: `${HOSTS.DOMAIN_SERVICE}api/domains/`,
    REG: `${HOSTS.DOMAIN_SERVICE}api/domains/reg`,
    CONFIGURE: `${HOSTS.DOMAIN_SERVICE}api/domains/configureTrackerDomainInCloudfare`,
    CHANGE_DOMAIN: `${HOSTS.DOMAIN_SERVICE}api/domains/changeDomain`,
}

export const API_BUNDLES = {
    FIND: `${HOSTS.BUNDLES_SERVICE}api/bundles/`,
    COMMENT: `${HOST}api/bundles/comment/`,
    CHART: `${HOST}api/chart/`,
    DRAFT: `${HOST}api/bundles/draft`,
    CHECK_BACKUP: `${HOSTS.BUNDLES_SERVICE}api/bundles/check-backup-status`,
    FORCE_BLACK_LIST: `${HOST}api/get_force_black_list`,
    ADD_MANY: `${HOSTS.BUNDLES_SERVICE}api/bundles/multi`,
    GET_NAMESPACES: `${HOSTS.BUNDLES_SERVICE}api/kv/namespaces`,
    GET_NAMESPACE_KEYS: `${HOSTS.BUNDLES_SERVICE}api/kv/keys`,
    GET_NAMESPACE_VALUE: `${HOSTS.BUNDLES_SERVICE}api/kv/value`,
    UPDATE_NAMESPACE_VALUE: `${HOSTS.BUNDLES_SERVICE}api/kv/update`,
    TEST_CALCULATION: `${HOSTS.BUNDLES_SERVICE}api/test-calculation`,
    TEST_CALCULATION_STOP: `${HOSTS.BUNDLES_SERVICE}api/test-calculation/stop`,
    CHECK_PLACEMENTS: `${HOSTS.BUNDLES_SERVICE}api/test-calculation/check-placements`,
    NETWORK_SETTINGS: `${HOSTS.BUNDLES_SERVICE}api/networks`,
    NETWORK_SETTINGS_ADD: `${HOSTS.BUNDLES_SERVICE}api/networks/add`,
    NETWORK_SETTINGS_UPDATE: `${HOSTS.BUNDLES_SERVICE}api/networks/update`,
    NETWORK_SETTINGS_REMOVE: `${HOSTS.BUNDLES_SERVICE}api/networks/remove`,
    NETWORK_SCHEME_SETTINGS: `${HOSTS.BUNDLES_SERVICE}api/networks-scheme`,
    NETWORK_SCHEME_SETTINGS_ADD: `${HOSTS.BUNDLES_SERVICE}api/networks-scheme/add`,
    NETWORK_SCHEME_SETTINGS_REMOVE: `${HOSTS.BUNDLES_SERVICE}api/networks-scheme/remove`,
    BUNDLES_ID_NAMES: `${HOSTS.BUNDLES_SERVICE}api/bundles/source-country`
}

export const API_BQ_ROUTES = {
    BUNDLE_STATS: `${HOSTS.BQ_ROUTES_SERVICE}api/bundle-stats`,
    TRACKING_CALCULATION: `${HOSTS.BQ_ROUTES_SERVICE}api/tracker/calculation`,
    CHECK_PLACEMENTS: `${HOSTS.BQ_ROUTES_SERVICE}api/check-placements`,
    // CHECK_PLACEMENTS_NEW: `${HOSTS.BQ_ROUTES_SERVICE}api/new/check-placements`,
    SOURCE_LOSS: `${HOSTS.BQ_ROUTES_SERVICE}api/traffic-loss`,
    BUNDLE_LOSS: `${HOSTS.BQ_ROUTES_SERVICE}api/bundle-loss`,
    PRIORITIES: `${HOSTS.BQ_ROUTES_SERVICE}api/calc-priorities`,
    REFRESH_CALC_CHECK_UPDATE_DATE: `${HOSTS.BQ_ROUTES_SERVICE}api/refresh-calc-check-update-date`,
    REFRESH_CALC_CHECK: `${HOSTS.BQ_ROUTES_SERVICE}api/refresh-calc-check`
};

export const API_SPLIT = {
    CALC: `${HOST}api/check/compareLanders`,
    CALC_PLACEMENT_POS: `${HOST}api/check/calcPlacementPosition`,
}

export const API_LANDERS = {
    FULL: `${HOSTS.BUNDLES_SERVICE}api/landers/`,
    LAND_IDS: `${HOSTS.BUNDLES_SERVICE}api/landers/land-ids`,
    ADD_LAND: `${HOSTS.BUNDLES_SERVICE}api/landers/add`,
    LANDERS_DELETE_OR_DELETE: `${HOST}api/full_landers/flag/`,
    MORE: `${HOST}api/full_landers/`,
    LANDERS: `${HOST}api/landers/`,
    PREMISSIONS: `${HOST}api/getPremissLanders/`,
}

export const API_SETTINGS = {
    GET: `${HOSTS.BUNDLES_SERVICE}api/settings/`,
    UPDATE: `${HOSTS.BUNDLES_SERVICE}api/settings/`,
    GET_CATEGORY_WEIGHTS: `${HOSTS.BQ_ROUTES_SERVICE}api/settings/cat-weights/`,
    UPDATE_CATEGORY_WEIGHT: `${HOSTS.BQ_ROUTES_SERVICE}api/settings/cat-weights/`,
    GET_WEEK_WEIGHTS: `${HOSTS.BQ_ROUTES_SERVICE}api/settings/week-weights/`,
    UPDATE_WEEK_WEIGHT: `${HOSTS.BQ_ROUTES_SERVICE}api/settings/week-weights/`
}

export const API_LOGGER = {
    FIND: `${HOST}api/logger/`,
    UPDATE_TRIGGER: `${HOST}api/logger/updateTrigger`,
}

export const API_QUEUES = {
    FIND: `${HOST}api/queue/`,
}

export const API_AD = {
    GET: `${HOSTS.ANALITICS_SERVICE}api/get`,
    GET_BUNCH: `${HOSTS.ANALITICS_SERVICE}api/getBunch`,
    GET_BUNCH_DATE_RANGE: `${HOSTS.ANALITICS_SERVICE}api/getBunchDateRange`,
    GET_CUSTOM_RATES: `${HOSTS.BUNDLES_SERVICE}api/custom-rate`,
    ADD_CUSTOM_RATE: `${HOSTS.BUNDLES_SERVICE}api/custom-rate/add-multiple`,
    REMOVE_CUSTOM_RATE: `${HOSTS.BUNDLES_SERVICE}api/custom-rate/delete`,
}

export const API_REDIS_ROUTES = {
    INCOMMING_BUFFER_NETWORKS: `${HOSTS.REDIS_ROUTES}stages/incoming_buffer/networks`,
    INCOMMING_BUFFER_ALL: `${HOSTS.REDIS_ROUTES}stages/incoming_buffer/networks/all`,
    PREP_BUFFER_NETWORKS: `${HOSTS.REDIS_ROUTES}stages/prep_buffer/networks`,
    STATIC_BUFFER_NETWORKS: `${HOSTS.REDIS_ROUTES}stages/static_buffer/networks`,
    IMPRESSION_TB: `${HOSTS.REDIS_ROUTES}queue/impression-tb`,
    CONVERSION_V1: `${HOSTS.REDIS_ROUTES}queue/conversion-v1`,
    CONVERSION_V2: `${HOSTS.REDIS_ROUTES}queue/conversion-v2`,
    CONVERSION_TB: `${HOSTS.REDIS_ROUTES}queue/conversion-tb`,
    USER_DATA: `${HOSTS.REDIS_ROUTES}queue/user-data`,
};

export const API_CATALOG = {
    OS: `${HOST}api/getOs/`,
    BROWSERS: `${HOST}api/getBrowsers/`,
    TB: `${HOST}api/getTrafficBack/`,
    ID_LANDERS: `${HOST}api/getLandersIds/`,
}

export const HELP = {
    BUNDLES_IDS: `${HOSTS.BUNDLES_SERVICE}api/bundles/ids`,
    COUNTRIES: `${HOSTS.BUNDLES_SERVICE}api/countries/`,
    SOURCES: `${HOSTS.BUNDLES_SERVICE}api/sources/`,
    SOURCES_IDS: `${HOSTS.BUNDLES_SERVICE}api/sources/ids`,
    CONFIDENCE_LVLS: `${HOSTS.BUNDLES_SERVICE}api/sources/confidence-lvls`,
    SOURCES2: `${HOSTS.BUNDLES_SERVICE}api/sources2/`,
    RATES: `${HOSTS.BUNDLES_SERVICE}api/rates/`,
    SOURCES_MULTI_EDIT: `${HOSTS.BUNDLES_SERVICE}api/sources/update-multi`,
}

export const AUTH = {
    LOGIN: `${HOSTS.BUNDLES_SERVICE}api/auth/login`,
}

export const GRADIENT_COLOR_GREEN_RED = [{
        color: '#30cf00',
        value: [0, 180]
    },
    {
        color: '#40bf00',
        value: [181, 280]
    },
    {
        color: '#50af00',
        value: [281, 380]
    },
    {
        color: '#609f00',
        value: [381, 480]
    },
    {
        color: '#708f00',
        value: [481, 580]
    },
    {
        color: '#807f00',
        value: [581, 680]
    },
    {
        color: '#906f00',
        value: [681, 780]
    },
    {
        color: '#a05f00',
        value: [781, 880]
    },
    {
        color: '#b04f00',
        value: [881, 980]
    },
    {
        color: '#c03f00',
        value: [981, 1080]
    },
    {
        color: '#d02f00',
        value: [1081, 1180]
    },
    {
        color: '#e01f00',
        value: [1181, 100000000000]
    },

]