import {
    API_BUNDLES
} from "../../constants";
import axios from "axios";

export default {
    /**
     * Переключение типа блек листа у связки
     */
    toggleReverseBlack(bundle_id) {
        this.loadBundleOverlay = true;
        axios
            .put(`${API_BUNDLES.FIND}${bundle_id}/toggle-reverse-black`)
            .then(() => {
                this.loadBundleOverlay = false;
                this.getBundles();
                this.makeToast(
                    "Связка - " + bundle_id,
                    `Тип блек листа успешно изменен`,
                    "success"
                );
            })
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message ?
                    er.response.data.message :
                    er.message ?
                    er.message :
                    JSON.stringify(er);
                this.loadBundleOverlay = false;
                this.makeToast("Ошибка смены блека на обратный", e, "danger");
            });
    },
    /**
     * Получение коммента к связке
     */
    getComment() {
        this.loadComment = true;
        console.log('comment', this.selectIdBundle);
        axios
            .get(`${API_BUNDLES.FIND}${this.selectIdBundle}/comment`)
            .then((res) => {
                this.textComment = res.data;
                //console.log(this.textComment);
                this.loadComment = false;
            })
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка получения комментария связок", e, "danger");
                this.loadComment = false;
            });
    },
    /**
     * Изменение коммента к связке
     */
    saveComment() {
        this.loadComment = true;
        const headers = {
            "Content-Type": "application/json",
        };
        const body = {
            comment: this.textComment,
        };
        axios
            .post(`${API_BUNDLES.FIND}${this.selectIdBundle}/comment`,
                JSON.stringify(body), {
                    headers: headers,
                }
            )
            .then(() => {
                //this.textComment = res.data[0].comment;
                //console.log(this.textComment);
                this.loadComment = false;
                this.modalComment = false;
                this.makeToast(
                    "Комментарий добавлен",
                    "Комментарий успешно добавлен",
                    "success"
                );
            })
            .catch((er) => {
                console.error("Ошибка сохранения комментария");
                console.log(er);
                const e = er.response.data.message ?
                    er.response.data.message :
                    er.message ?
                    er.message :
                    JSON.stringify(er);
                this.makeToast("Ошибка получения комментария связок", e, "danger");
                this.loadComment = false;
            });
    },

    actionAllBundle(v) {
        // console.log(v);
        if (v === "playAll") this.modalApplyResumeBundles = true;
        //this.playBundles();
        else if (v === "pauseAll") this.modalApplyPauseBundles = true;
        //this.pauseBundles();
        else if (v === "archiveAll") this.modalApplyMultiRemoveBundles = true;
        else if (v === "editAll") this.modalApplyEditBundles = true;
    },
    /**
     * Переключение статуса связок
     * @param {*} bundle_id 
     * @param {*} status 
     */
    toggleStatus(bundle_id, status) {
        // TODO: скорее всего метод устарел и не актуален
        this.selectIdBundle = Number(bundle_id);
        this.loadBundleOverlay = true;
        console.log(bundle_id, status);
        if (status == 'WORKING') {
            this.pauseBundles(bundle_id);
        } else {
            this.playBundles(bundle_id);
        }
    },
    /**
     * Запуск связок
     * @param {string} bundle_ids список связок через запятую
     */
    playBundles(bundle_ids) {
        this.modalApplyResumeBundles = false;
        this.loadBundleOverlay = false;
        console.log('playBundles bundle_ids', bundle_ids);
        if (bundle_ids) {
            this.loadBundleOverlay = true;
            axios
                .put(`${API_BUNDLES.FIND}${bundle_ids}/resume`)
                .catch((er) => {
                    const e = er.response.data.message;
                    console.log(er);
                    this.loadBundleOverlay = false;
                    this.makeToast("Ошибка запуска связок", e, "danger");
                    // this.loadBundleOverlay = false;
                })
                .then(() => {
                    this.loadBundleOverlay = false;
                    this.getBundles();
                    this.makeToast("Связки запущены", bundle_ids, "success");
                });
        }
    },
    /**
     * Постановка связок на паузу
     * @param {string} bundle_ids список связок через запятую
     */
    pauseBundles(bundle_ids) {
        this.modalApplyPauseBundles = false;
        this.loadBundleOverlay = false;
        console.log('pauseBundles bundle_ids', bundle_ids);
        if (bundle_ids) {
            this.loadBundleOverlay = true;
            axios
                .put(`${API_BUNDLES.FIND}${bundle_ids}/pause`)
                .catch((er) => {
                    this.loadBundleOverlay = false;
                    console.log(er);
                    const e = er.response.data.message;
                    this.makeToast("Ошибка остановки связок", e, "danger");
                    // this.loadBundleOverlay = false;
                })
                .then(() => {
                    this.loadBundleOverlay = false;
                    this.getBundles();
                    this.makeToast("Связки остановлены", bundle_ids, "success");
                });
        }
    },
    /**
     * Обработчик действий по связкам
     */
    actionBundle(action, item_data) {
        const data = {
            item: item_data
        };
        console.log('data', data);
        const bundle_id = data.item.bundle_id;
        const source_id = data.item.source_id;
        const country = data.item.country;
        const min_roi = data.item.bundle_min_roi;
        const min_cpm = data.item.min_cpm;
        if (action === "comment") {
            this.textComment = "";
            this.modalComment = true;
            this.selectIdBundle = Number(bundle_id);
            this.getComment();
        } else if (action === "edit") {
            this.selectIdBundle = Number(bundle_id);
            this.modalEditBundle = true;
            this.valueSourceIdForBundle = this.optionsSourceIdForBundle ?
                this.optionsSourceIdForBundle.find((a) => a.code === source_id) :
                "";
            this.valueCountryForBundle = this.optionsCountryForBundle ?
                this.optionsCountryForBundle.find((a) => a.code === country) :
                "";
            console.log("min_roi", min_roi, min_roi ? min_roi.split("%")[0] : min_roi, data.item);
            this.valueMinCPMForBundle = min_cpm ? Number(min_cpm.split("$")[1]) : min_cpm;
            this.valueMinRoiForBundle = min_roi ? Number(min_roi.split("%")[0]) : min_roi;
            this.chosenEditLander = {
                name: data.item.land,
                code: data.item.land,
                offer: data.item.offer,
                land_id: data.item.land_id,
                tb_id: data.item.tb_id
            };
        } else if (action === "archive") {
            this.selectIdBundle = Number(bundle_id);
            this.modalApplyRemoveBundle = true;
        } else if (action === "report") {
            this.selectIdBundle = Number(bundle_id);
            // this.$router.push({
            //   path: "/report/" + this.selectIdBundle,
            // });
            window.open("./report/" + this.selectIdBundle, "_blank");
        }
    },
    /**
     * Обработка списка выделенных связок
     */
    changeMultiBundles(v) {
        this.valueBundlesIds = v
            .split(",")
            .map((a) => {
                return {
                    name: a.trim(),
                    code: a.trim()
                };
            })
            .filter(
                (f) => this.bundlesIds.map((m) => m.code).indexOf(f.code) != -1
            );
    },
}