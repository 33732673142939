<template>
  <div>
    <b-container fluid>
      <h2>Потери трафика по источникам</h2>
      <hr />
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </template>

        <b-row class="mt-2">
          <b-col sm class="px-0 mx-2">
            <multiselect
              class="mb-2"
              v-model="valueSourcesIds"
              tag-placeholder="Источники трафика"
              placeholder="Источники трафика"
              label="name"
              track-by="code"
              :options="sourcesIds"
              :multiple="true"
              :taggable="true"
            ></multiselect>
          </b-col>
          <b-col sm class="px-0 mx-2">
            <b-button @click="loadData()" variant="primary">
              Применить</b-button
            >
          </b-col>
        </b-row>

        <b-table
          bordered
          striped
          responsive="xl"
          hover
          small
          :fields="fields"
          :items="items"
        >
          <template #cell()="data">
            <div style="white-space: nowrap">
              {{ data.value }}
            </div>
          </template>
          <template #head()="data">
            <div style="white-space: nowrap">{{ data.label }}</div>
          </template>
        </b-table>
      </b-skeleton-wrapper>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { API_BQ_ROUTES, HELP } from "../constants";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: true,
      valueSourcesIds: null,
      sourcesIds: [],
      items: [],
      fields: [
        {
          key: "source_id",
          label: "ID Источника",
          sortable: true,
        },
        {
          key: "name",
          label: "Название",
        },
        {
          key: "traffic_loss",
          label: "% потерь",
          sortable: true,
        },
        {
          key: "payment_type",
          label: "Тип оплаты",
        },
      ],
    };
  },

  async mounted() {
    await this.getSourcesIds();
    await this.loadData();
  },

  methods: {
    //Получаем id всех сурсов
    getSourcesIds() {
      this.loading = true;
      axios
        .get(`${HELP.SOURCES_IDS}`)
        .then((res) => {
          this.sourcesIds = res.data.map((a) => {
            return { name: a, code: a };
          });
          this.loading = false;
          this.LoadingSourcesIds = true;
        })
        .catch((er) => {
          this.loading = true;
          this.LoadingSourcesIds = false;
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка получения id связок", e, "danger");
        });
    },
    async loadData() {
      try {
        const res = await axios.get(API_BQ_ROUTES.SOURCE_LOSS, {
          params: {
            source_ids: this.valueSourcesIds?.map((a) => a.code).join(","),
          },
        });
        const data = res.data.data.map((d) => {
          return {
            ...d,
            traffic_loss: Math.round(Number(d.traffic_loss) * 10000) / 100,
          };
        });
        this.items = [...data];
      } catch (e) {
        console.log(e.message ?? "Something went wrong");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>