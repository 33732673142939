import axios from "axios";
// import DatePicker from "vue2-datepicker";
// import Multiselect from "vue-multiselect";
import "vue2-datepicker/index.css";
import {
    API_BUNDLES
} from "../../constants";
import report_data from "./report-data";
import report_actions from "./report-actions";
import bundle_utility from "./../bundles-list/bundles-list-utility";

export default {
    data() {
        return report_data;
    },
    methods: {
        ...bundle_utility,
        ...report_actions,
        getStages() {
            this.loadDataBundleFullStages = true;
            axios
                .get(`${API_BUNDLES.FIND}${this.id}/details`)
                .catch((er) => {
                    console.log(er);
                    const e = er.response.data.message;
                    this.makeToast("Ошибка получения этапов", e, "danger");
                })
                .then((res) => {
                    const d = res.data;
                    if (d.bundle && d.stages) {
                        const infoBundle = d.bundle;
                        const infoDtages = d.stages;

                        this.bubndleData = [infoBundle].map((a) => {
                            return {
                                status: a.status,
                                source_id: a.source_id,
                                network: a.network,
                                code: a.country,
                                min_cpm: this.strUSD(Number(a.min_cpm).toFixed(2)),
                                max_cpm: this.strUSD(Number(a.max_cpm).toFixed(2)),
                                max_cpm_black: a.max_cpm_black ? this.strUSD(Number(a.max_cpm_black).toFixed(2)) : null,
                                rate: this.strUSD(Number(a.rate).toFixed(2)),
                                min_roi: this.strPercent(Math.round(Number(a.min_roi) * 100)),
                                create_date: new Date(a.create_date)
                                    .toLocaleString("ru-RU")
                                    .split(", ")
                                    .join(" "),
                                active_time: this.formatActiveTimeForDisplay(a.active_time),
                                actual_rule: a.rule_id,
                            };
                        });
                        // console.log(this.bubndleData);
                        this.fieldsBundleData = Object.keys(this.bubndleData[0]).map(
                            (a) => {
                                let title = "";
                                if (a === "status") title = "Статус";
                                else if (a === "source_id") title = "ID ист.";
                                else if (a === "network") title = "Название сети";
                                else if (a === "code") title = "Страна";
                                else if (a === "min_cpm") title = "мин. CPM";
                                else if (a === "max_cpm") title = "макс. CPM";
                                else if (a === "max_cpm_black") title = "макс. CPM Блек Этап";
                                else if (a === "rate") title = "Рейт";
                                else if (a === "min_roi") title = "мин. ROI";
                                else if (a === "create_date") title = "Дата создния";
                                else if (a === "active_time") title = "Активное время";
                                else if (a === "actual_rule") title = "Актуальное правило";
                                return {
                                    label: title,
                                    key: a
                                };
                            }
                        );

                        this.bubndleLandersFullStages = infoDtages.map((a) => {
                            return {
                                num: a.stage,
                                cpm: this.strUSD(a.cpm),
                                camp_id: a.camp_id,
                                stage_type: a.TYPE_PLACEMENTS === 0 ? "Вайт" : "Блек",
                                placements: a.PLACEMENTS,
                                update_date: a.UPDATE_DATE ? new Date(Number(a.UPDATE_DATE) * 1000).toLocaleString("ru-RU")
                                    .split(", ")
                                    .join(" ") : null,
                                _cellVariants: a.TYPE_PLACEMENTS === 1 ? {
                                    stage_type: "dark"
                                } : {},
                            };
                        });
                        // console.log(this.bubndleData);
                        this.fieldsBundleFullStages = Object.keys(
                                this.bubndleLandersFullStages[0]
                            )
                            .filter((f) => f !== "_cellVariants")
                            .map((a) => {
                                let title = "";
                                if (a === "num") title = "Этап";
                                else if (a === "cpm") title = "Ставка";
                                else if (a === "camp_id") title = "ID кампании";
                                else if (a === "stage_type") title = "Тип";
                                else if (a === "placements") title = "Площадки";
                                else if (a === "update_date") title = "Время обновления";
                                return {
                                    label: title,
                                    key: a
                                };
                            });

                        this.loadDataBundleFullStages = false;
                    } else {
                        this.makeToast(
                            "Ошибка получения данных по связке",
                            "Ошибка ",
                            "danger"
                        );
                    }
                });
        },
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getStages();
    }
}