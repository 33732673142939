<template>
  <div>
    <b-container fluid>
      <h2>Журнал</h2>
      <hr />
      <b-skeleton-wrapper class="mt-4" :loading="loadLogger">
        <template #loading>
          <b-form class="mt-4" inline>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
          </b-form>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </template>
        <b-row>
          <b-col>
            <b-form-input
              type="number"
              v-model="bundleIDFilter"
              placeholder="ID связки"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              type="text"
              v-model="messageLogFilter"
              placeholder="Сообщение"
            ></b-form-input>
          </b-col>
          <b-col>
            <date-picker
              class="mb-3"
              value-type="DD-MM-YYYY"
              placeholder="Даты логов"
              v-model="dateBetWeen"
              range
            ></date-picker>
          </b-col>
          <b-col>
            <b-button @click="getLogger" variant="primary"
              >Искать логи</b-button
            >
          </b-col>
        </b-row>
        <div class="mt-4" v-if="logs.length === 0">
          <h1 class="text-center">Ни чего не найдено!</h1>
        </div>
        <b-table
          ref="selectableTable"
          style="font-size: 14px"
          :sticky-header="heightTable"
          :fields="fields"
          striped
          responsive="xl"
          hover
          small
          :items="logs"
        >
          <template #cell()="data">
            <div style="white-space: nowrap">
              {{ data.value }}
            </div>
          </template>
          <template #head()="data">
            <div style="white-space: nowrap">{{ data.label }}</div>
          </template>
        </b-table>
        <b-pagination
          v-if="logs.length != 0"
          v-model="currentPage"
          :total-rows="totalRows"
          align="right"
          size="sm"
          class="my-0"
          @change="selectPagination"
        ></b-pagination>
      </b-skeleton-wrapper>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import { API_LOGGER } from "../constants";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      bundleIDFilter: "",
      messageLogFilter: "",
      dateBetWeen: [],
      heightTable: "300px",
      loadLogger: true,
      fields: [],
      logs: [],
      currentPage: 1,
      totalRows: 50,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.getLogger();
    this.handleResize();
  },
  watch: {
    currentPage() {
      this.getLogger();
    },
  },
  methods: {
    selectPagination(v) {
      this.currentPage = v;
    },
    handleResize() {
      this.heightTable = `${window.innerHeight - 280}px`;
    },
    getLogger() {
      this.loadLogger = true;
      const bundleId = this.bundleIDFilter ? this.bundleIDFilter : null;
      const message = this.messageLogFilter ? this.messageLogFilter : null;
      const date1 = this.dateBetWeen.length > 0 ? this.dateBetWeen[0] : null;
      const date2 = this.dateBetWeen.length > 1 ? this.dateBetWeen[1] : null;
      console.log(
        `${API_LOGGER.FIND}bundle_id=${bundleId}&date_from=${date1}&date_to=${date2}&message=${message}&page=${this.currentPage}`
      );
      axios
        .get(
          encodeURI(
            `${API_LOGGER.FIND}bundle_id=${bundleId}&date_from=${date1}&date_to=${date2}&message=${message}&page=${this.currentPage}`
          )
        )
        .catch((er) => {
          const e = er.response.data.message;
          console.log(er);
          this.makeToast("Ошибка получения источников трафика", e, "danger");
        })
        .then((res) => {
          const d = res.data.data;
          if (d.length > 0) {
            this.logs = d.map((a) => {
              return {
                create_date: new Date(a.create_date)
                  .toLocaleString("ru-RU")
                  .split(", ")
                  .join(" "),
                bundle_id: a.bundle_id,
                message: a.message,
              };
            });
            // console.log(this.bubndleData);
            this.fields = Object.keys(this.logs[0]).map((a) => {
              let title = "";
              if (a === "create_date") title = "Дата лога";
              else if (a === "bundle_id") title = "ID связки";
              else if (a === "message") title = "Сообщение";
              return { label: title, key: a, sortable: true };
            });
            this.totalRows = res.data.amount;
            this.loadLogger = false;
          } else {
            this.logs = [];
            this.fields = [];
            this.totalRows = res.data.amount;
            this.loadLogger = false;
          }
        });
    },
    //Тост
    makeToast(title, body, variant = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: 100;
}
</style>