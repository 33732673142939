<template>
  <div>
    <b-overlay spinner-variant="primary" rounded="sm">
      <b-container fluid>
        <h2>Расчеты минусовости площадки</h2>
        <hr />

        <b-form @submit="onSubmit">
          <b-row class="row" cols-sm="12">
            <b-col sm v-for="f in fields" :key="f.name">
              <b-form-group :label="f.label">
                <b-form-input
                  :key="f.name"
                  type="text"
                  v-model="f.value"
                  step="0.001"
                  class="mb-2"
                  :required="f.required"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <label class="d-block"
                >Дата последнего обновления:
                {{ lastCalcCheckUpdateDate }}</label
              >
              <b-button
                variant="primary"
                type="button"
                @click="updateCalcCheck"
              >
                <b-spinner small v-if="loadingCalcCheck"></b-spinner
                ><span v-if="!loading">Обновить</span></b-button
              >
            </b-col>
          </b-row>
          <b-button variant="primary" type="submit">
            <b-spinner small v-if="loading"></b-spinner
            ><span v-if="!loading">Открыть</span></b-button
          >
        </b-form>

        <br />

        <b-skeleton-wrapper class="mt-4" :loading="loading">
          <template #loading>
            <b-form inline>
              <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
            </b-form>
            <b-skeleton-table
              :rows="5"
              :columns="4"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </template>

          <v-data-table
            v-if="topTableData.length"
            dense
            hide-default-header
            hide-default-footer
            disable-pagination
            :items="topTableData"
            :headers="topTableHeader"
            :loading="loading"
            item-key="value"
            loading-text="Loading data..."
            class="elevation-1 mb-3 table-bordered table-responsive"
          >
            <template v-slot:body="{ items }">
              <tr v-for="(item, idx) in items" :key="idx">
                <td
                  v-for="(header, i) in topTableHeader"
                  :key="i"
                  :class="'text-center ' + choose_color_class(i, 0)"
                >
                  <p
                    class="mb-0 p-1"
                    v-if="header.value != 'last_30_payouts_str'"
                  >
                    {{ item[header.value] }}
                  </p>
                  <div
                    v-if="header.value == 'last_30_payouts_str'"
                    class="ltb30 p-1"
                  >
                    {{ item.last_30_payouts_str }}
                  </div>
                </td>
              </tr>
            </template>

            <template v-slot:[`item.last_30_payouts_str`]="{ item }">
              <div class="ltb30">{{ item.last_30_payouts_str }}</div>
            </template>

            <template v-slot:[`header`]="{}">
              <b-tr>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="10"
                >
                  Данные связки
                </b-th>
                <b-th
                  class="color-2"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="2"
                >
                  Данные по площадкам
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="4"
                >
                  Параллельные связки - Old
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="4"
                >
                  Параллельные связки - New
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="4"
                >
                  Параллельные связки - Другие страны
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="4"
                >
                  Параллельные связки - Старые данные этой связки
                </b-th>
              </b-tr>
              <b-tr>
                <!-- Данные связки -->
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  ID связки
                </b-th>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  ID площадки
                </b-th>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  ID источника
                </b-th>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Страна
                </b-th>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Версия браузера
                </b-th>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Качество трафика
                </b-th>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  MaxCPM связки
                </b-th>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Процент потери трафика
                </b-th>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  MinROI
                </b-th>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Рейт по пушам
                </b-th>
                <!-- Данные по площадкам -->
                <b-th
                  class="color-2"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Рейт по ТБ
                </b-th>
                <b-th
                  class="color-2"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Последние 30 конверсий по ТБ
                </b-th>
                <!-- Параллельные связки - Old -->
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Показы
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Лиды по пушам </b-th
                ><b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Лиды по ТБ
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  MaxCPM (верхняя граница)
                </b-th>
                <!-- Параллельные связки - New -->
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Показы
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Лиды по пушам </b-th
                ><b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Лиды по ТБ
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  MaxCPM (верхняя граница)
                </b-th>
                <!-- Параллельные связки - Другие страны -->
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Показы
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Лиды по пушам </b-th
                ><b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Лиды по ТБ
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  MaxCPM (верхняя граница)
                </b-th>
                <!-- Параллельные связки - Старые данные этой связки-->
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Показы
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Лиды по пушам </b-th
                ><b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  Лиды по ТБ
                </b-th>
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                >
                  MaxCPM (верхняя граница)
                </b-th>
              </b-tr>
            </template>
          </v-data-table>

          <v-data-table
            v-if="middleTableData.length"
            dense
            hide-default-header
            hide-default-footer
            disable-pagination
            :items="middleTableData"
            :headers="middleTableHeader"
            :loading="loading"
            item-key="value"
            loading-text="Loading data..."
            class="elevation-1 mb-3 table-bordered table-responsive"
          >
            <template v-slot:body="{ items }">
              <tr v-for="(item, idx) in items" :key="idx">
                <td
                  v-for="(header, i) in middleTableHeader"
                  :key="i"
                  :class="'p-1 text-center ' + choose_color_class(i, 1)"
                >
                  {{ item[header.value] }}
                </td>
              </tr>
            </template>

            <template v-slot:[`header`]="{}">
              <b-tr>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  rowspan="2"
                  >Этап</b-th
                >
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  rowspan="2"
                  >Ставка</b-th
                >

                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="4"
                  >Данные только текущего этапа</b-th
                >
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="4"
                  >Данные текущего этапа + все, что выше</b-th
                >
                <b-th
                  class="color-4"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="4"
                  >Данные текущего этапа + все, что ниже</b-th
                >
                <b-th
                  class="color-4"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="4"
                  >Данные текущего этапа + предыдущего этапа</b-th
                >
                <b-th
                  class="color-5"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="2"
                  >MaxCPM из данных по текущей связке</b-th
                >
                <b-th
                  class="color-6"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="2"
                  >MaxCPM с учетом данных параллельных связок</b-th
                >
                <b-th
                  class="color-7"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="2"
                  >Итоговый MaxCPM</b-th
                >
              </b-tr>
              <b-tr>
                <!-- Данные только текущего этапа -->
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Показы</b-th
                >
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Лиды по пушам</b-th
                >
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Лиды по ТБ</b-th
                >
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >MaxCPM (верхняя граница)</b-th
                >
                <!-- Данные только текущего этапа + все что выше -->
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Показы</b-th
                >
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Лиды по пушам</b-th
                >
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Лиды по ТБ</b-th
                >
                <b-th
                  class="color-3"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >MaxCPM (верхняя граница)</b-th
                >
                <!-- Данные только текущего этапа + все что ниже -->
                <b-th
                  class="color-4"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Показы</b-th
                >
                <b-th
                  class="color-4"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Лиды по пушам</b-th
                >
                <b-th
                  class="color-4"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Лиды по ТБ</b-th
                >
                <b-th
                  class="color-4"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >MaxCPM (нижняя граница)</b-th
                >
                <!-- Данные текущего этапа + предыдущего этапа-->
                <b-th
                  class="color-4"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Показы</b-th
                >
                <b-th
                  class="color-4"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Лиды по пушам</b-th
                >
                <b-th
                  class="color-4"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Лиды по ТБ</b-th
                >
                <b-th
                  class="color-4"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >MaxCPM (нижняя граница)</b-th
                >
                <!-- MaxCPM из данных по текущей связке	-->
                <b-th
                  class="color-5"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >MaxCPM (нижняя граница)</b-th
                >
                <b-th
                  class="color-5"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >MaxCPM (верхняя граница)</b-th
                >
                <!-- MaxCPM с учетом данных параллельных связок	-->
                <b-th
                  class="color-6"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Минимальный из MaxCPM параллельных связок (нижняя
                  граница)</b-th
                >
                <b-th
                  class="color-6"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Минимальный из MaxCPM параллельных связок (верхняя
                  граница)</b-th
                >
                <!-- Итоговый MaxCPM	-->
                <b-th
                  class="color-7"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Итоговый MaxCPM (нижняя граница)</b-th
                >
                <b-th
                  class="color-7"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Итоговый MaxCPM (верхняя граница)</b-th
                >
              </b-tr>
            </template>
          </v-data-table>

          <v-data-table
            v-if="bottomTableData.length"
            dense
            hide-default-header
            hide-default-footer
            disable-pagination
            :items="bottomTableData"
            :headers="bottomTableHeader"
            :loading="loading"
            item-key="value"
            loading-text="Loading data..."
            class="elevation-1 mb-3 table-bordered table-responsive"
          >
            <template v-slot:body="{ items }">
              <tr v-for="(item, idx) in items" :key="idx">
                <td
                  v-for="(header, i) in bottomTableHeader"
                  :key="i"
                  :class="'p-1 text-center ' + choose_color_class(i, 2)"
                >
                  {{ item[header.value] }}
                </td>
              </tr>
            </template>

            <template v-slot:[`header`]="{}">
              <b-tr>
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  rowspan="2"
                  >Этап</b-th
                >
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  rowspan="2"
                  >CPM Этапа</b-th
                >
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  rowspan="2"
                  >Вайт / Блек (?)</b-th
                >
                <b-th
                  class="color-1"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  rowspan="2"
                  >Маркер (?)</b-th
                >
                <b-th
                  class="color-8"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="3"
                  >Итоговые MaxCPM</b-th
                >
                <b-th
                  class="color-8"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  rowspan="2"
                  >Подходит для данного этапа?</b-th
                >
              </b-tr>
              <b-tr>
                <b-th
                  class="color-8"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Итоговый MaxCPM (нижняя граница)</b-th
                >
                <b-th
                  class="color-8"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Итоговый MaxCPM (верхняя граница)</b-th
                >
                <b-th
                  class="color-8"
                  variant="light"
                  style="white-space: normal; text-align: center; height: auto"
                  colspan="1"
                  >Итоговый MaxCPM</b-th
                >
              </b-tr>
            </template>
          </v-data-table>
        </b-skeleton-wrapper>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";
import { API_BQ_ROUTES, API_BUNDLES } from "../constants";
import utility_funtions from "../utility/utility-funtions";
export default {
  components: {},

  data() {
    return {
      loading: false,
      loadingCalcCheck: false,
      fields: [
        {
          label: "ИД связки",
          name: "bundle_id",
          value: "",
          required: true,
        },
        {
          label: "Site ID",
          name: "site_id",
          value: "",
          required: true,
        },
      ],

      heightTable: "300px",
      lastCalcCheckUpdateDate: " - ",
      topTableData: [],
      middleTableData: [],
      bottomTableData: [],

      topTableHeader: [
        {
          value: "bundle_id",
        },
        {
          value: "site_id",
        },
        {
          value: "source_id",
        },
        {
          value: "country",
        },
        {
          value: "bundle_browser_version_type",
        },
        {
          value: "traffic_quality",
        },
        {
          value: "max_cpm_bundle",
        },
        {
          value: "traffic_loss",
        },
        {
          value: "min_roi",
        },
        {
          value: "rate",
        },
        {
          value: "rate_tb",
        },
        {
          value: "last_30_payouts_str",
        },
        {
          value: "parallel_old_impr",
        },
        {
          value: "parallel_old_lp",
        },
        {
          value: "parallel_old_ltb",
        },
        {
          value: "parallel_old_top_max_cpm",
        },
        {
          value: "parallel_new_impr",
        },
        {
          value: "parallel_new_lp",
        },
        {
          value: "parallel_new_ltb",
        },
        {
          value: "parallel_new_top_max_cpm",
        },
        {
          value: "parallel_another_c_impr",
        },
        {
          value: "parallel_another_c_lp",
        },
        {
          value: "parallel_another_c_ltb",
        },
        {
          value: "parallel_another_c_top_max_cpm",
        },
        {
          value: "parallel_self_old_impr",
        },
        {
          value: "parallel_self_old_lp",
        },
        {
          value: "parallel_self_old_ltb",
        },
        {
          value: "parallel_self_old_top_max_cpm",
        },
      ],

      middleTableHeader: [
        { value: "stage" },
        { value: "cpm" },

        { value: "stage_impr" },
        { value: "stage_lp_d" },
        { value: "stage_ltb_d" },
        { value: "stage_tmcpm" },

        { value: "stage_up_impr" },
        { value: "stage_up_lp_d" },
        { value: "stage_up_ltb_d" },
        { value: "stage_up_tmcpm" },

        { value: "stage_down_impr" },
        { value: "stage_down_lp_d" },
        { value: "stage_down_ltb_d" },
        { value: "stage_down_tmcpm" },

        { value: "stage_pre_impr" },
        { value: "stage_pre_lp_d" },
        { value: "stage_pre_ltb_d" },
        { value: "stage_pre_tmcpm" },

        { value: "bottom_max_cpm" },
        { value: "top_max_cpm" },

        { value: "parallel_bottom_min_max_cpm" },
        { value: "parallel_top_min_max_cpm" },

        { value: "bottom_res_max_cpm" },
        { value: "top_res_max_cpm" },
      ],
      bottomTableHeader: [
        { value: "stage" },
        { value: "cpm" },
        { value: "wb" },
        { value: "calc_vector" },
        { value: "bottom_res_max_cpm" },
        { value: "top_res_max_cpm" },
        { value: "site_cpm" },
        { value: "is_suitable" },
      ],
    };
  },
  mounted() {
    this.handleResize();
    this.getUpdateDate();
  },

  created() {
    window.addEventListener("resize", this.handleResize);
  },

  methods: {
    ...utility_funtions,
    getUpdateDate() {
      axios
        .get(API_BQ_ROUTES.REFRESH_CALC_CHECK_UPDATE_DATE)
        .then((data) => {
          this.lastCalcCheckUpdateDate = data.data.data
            ? new Date(data.data.data)
                .toLocaleString("ru-RU")
                .split(", ")
                .join(" ")
            : " - ";
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast(
            "Ошибка получения даты последнего обновления",
            e,
            "danger"
          );
          return [];
        });
    },
    updateCalcCheck() {
      this.loadingCalcCheck = true;
      axios
        .get(`${API_BQ_ROUTES.REFRESH_CALC_CHECK}`)
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка обновления ", e, "danger");
          return [];
        })
        .finally(() => {
          this.loadingCalcCheck = false;
        });
    },
    async onSubmit(evt) {
      evt.preventDefault();

      const bundle_id = this.fields.find((a) => a.name === "bundle_id").value;

      const site_id = this.fields.find((a) => a.name === "site_id").value;

      this.loading = true;
      this.topTableData = [];
      try {
        const res = await axios.get(`${API_BUNDLES.CHECK_PLACEMENTS}`, {
          params: { bundle_id, site_id },
        });
        let data = res.data.data ?? [];

        data = data.sort((a, b) => Number(b.stage) - Number(a.stage));
        data.forEach((row) => {
          row.rate_tb = row.epl_tb * 1000;
          row.max_cpm_bundle = this.round_to(
            row.max_cpm_bundle ? row.max_cpm_bundle : row.max_cpm
          );
          // Параллельные связки - Old
          const parallel_old = row.site_cpm_limit.scl.parallel.find(
            (a) => a.parallel_browser_version_type == "OLD"
          );
          if (parallel_old) {
            row.parallel_old_impr = this.round_to_without_fixed(
              parallel_old.impressions,
              0
            );
            row.parallel_old_lp =
              this.round_to_without_fixed(parallel_old.leads_p, 0) +
              ` (${this.round_to_percents(
                this.phat(parallel_old.leads_p, parallel_old.impressions)
              )}%)`;
            row.parallel_old_ltb =
              this.round_to_without_fixed(parallel_old.leads_tb, 0) +
              ` (${this.round_to_percents(
                this.phat(parallel_old.leads_tb, parallel_old.impressions)
              )}%)`;
            row.parallel_old_top_max_cpm = this.round_to(
              this.calcCI(
                parallel_old.impressions,
                parallel_old.leads_p,
                parallel_old.leads_tb,
                row.z_value,
                Number(row.rate),
                Number(row.epl_tb),
                Number(row.min_roi),
                1
              )
            );
          }

          // Параллельные связки - New
          const parallel_new = row.site_cpm_limit.scl.parallel.find(
            (a) => a.parallel_browser_version_type == "NEW"
          );
          if (parallel_new) {
            row.parallel_new_impr = this.round_to_without_fixed(
              parallel_new.impressions,
              0
            );
            row.parallel_new_lp =
              this.round_to_without_fixed(parallel_new.leads_p, 0) +
              ` (${this.round_to_percents(
                this.phat(parallel_new.leads_p, parallel_new.impressions)
              )}%)`;
            row.parallel_new_ltb =
              this.round_to_without_fixed(parallel_new.leads_tb, 0) +
              ` (${this.round_to_percents(
                this.phat(parallel_new.leads_tb, parallel_new.impressions)
              )}%)`;
            row.parallel_new_top_max_cpm = this.round_to(
              this.calcCI(
                parallel_new.impressions,
                parallel_new.leads_p,
                parallel_new.leads_tb,
                row.z_value,
                Number(row.rate),
                Number(row.epl_tb),
                Number(row.min_roi),
                1
              )
            );
          }

          //#TODO: Параллельные связки - Другие страны
          row.parallel_another_c_impr = this.round_to_without_fixed(
            row.site_agg_over_nw.saonw.imp_over_network,
            0
          );
          row.parallel_another_c_lp =
            this.round_to_without_fixed(
              row.site_agg_over_nw.saonw.lp_over_network,
              0
            ) +
            ` (${this.round_to_percents(
              this.phat(
                row.site_agg_over_nw.saonw.lp_over_network,
                row.site_agg_over_nw.saonw.imp_over_network
              )
            )}%)`;
          row.parallel_another_c_ltb =
            this.round_to_without_fixed(
              row.site_agg_over_nw.saonw.ltb_over_network,
              0
            ) +
            ` (${this.round_to_percents(
              this.phat(
                row.site_agg_over_nw.saonw.ltb_over_network,
                row.site_agg_over_nw.saonw.imp_over_network
              )
            )}%)`;
          row.parallel_another_c_top_max_cpm = this.round_to(
            this.calcCI(
              row.site_agg_over_nw.saonw.imp_over_network,
              row.site_agg_over_nw.saonw.lp_over_network,
              row.site_agg_over_nw.saonw.ltb_over_network,
              row.z_value,
              Number(row.rate),
              Number(row.site_agg_over_nw.saonw.epl_tb),
              row.min_roi,
              1
            )
          );

          // Параллельные связки - Старые данные этой связки
          const parallel_self_old = row.site_cpm_limit.scl.parallel[0];
          if (parallel_self_old) {
            row.parallel_self_old_impr = this.round_to_without_fixed(
              parallel_self_old.impressions,
              0
            );
            row.parallel_self_old_lp =
              this.round_to_without_fixed(parallel_self_old.leads_p, 0) +
              ` (${this.round_to_percents(
                this.phat(
                  parallel_self_old.leads_p,
                  parallel_self_old.impressions
                )
              )}%)`;
            row.parallel_self_old_ltb =
              this.round_to_without_fixed(parallel_self_old.leads_tb, 0) +
              ` (${this.round_to_percents(
                this.phat(
                  parallel_self_old.leads_tb,
                  parallel_self_old.impressions
                )
              )}%)`;
            row.parallel_self_old_top_max_cpm = this.round_to(
              this.calcCI(
                parallel_self_old.impressions,
                parallel_self_old.leads_p,
                parallel_self_old.leads_tb,
                row.z_value,
                Number(row.rate),
                Number(row.epl_tb),
                Number(row.min_roi),
                1
              )
            );
          }

          // Данные только текущего этапа
          row.stage_impr = row.impressions
            ? this.round_to_without_fixed(row.impressions, 0)
            : null;
          row.stage_lp = row.leads_p
            ? this.round_to_without_fixed(row.leads_p, 0)
            : null;
          row.stage_lp_d = !row.stage_lp
            ? row.stage_lp
            : row.stage_lp +
              ` (${this.round_to_percents(
                this.phat(row.leads_p, row.impressions)
              )}%)`;
          row.stage_ltb = row.leads_tb
            ? this.round_to_without_fixed(row.leads_tb, 0)
            : null;
          row.stage_ltb_d = !row.stage_ltb
            ? row.stage_ltb
            : row.stage_ltb +
              ` (${this.round_to_percents(
                this.phat(row.stage_ltb, row.impressions)
              )}%)`;
          row.stage_tmcpm = row.top_direct_cpm
            ? this.round_to(row.top_direct_cpm)
            : null;
          // Данные текущего этапа + все, что выше
          row.stage_up_impr = row.imp_down
            ? this.round_to_without_fixed(row.imp_down, 0)
            : null;
          row.stage_up_lp = row.lp_down
            ? this.round_to_without_fixed(row.lp_down, 0)
            : null;
          row.stage_up_lp_d = !row.stage_up_lp
            ? row.stage_up_lp
            : row.stage_up_lp +
              ` (${this.round_to_percents(
                this.phat(row.stage_up_lp, row.imp_down)
              )}%)`;
          row.stage_up_ltb = row.ltb_down
            ? this.round_to_without_fixed(row.ltb_down, 0)
            : null;
          row.stage_up_ltb_d = !row.stage_up_ltb
            ? row.stage_up_ltb
            : row.stage_up_ltb +
              ` (${this.round_to_percents(
                this.phat(row.stage_up_ltb, row.imp_down)
              )}%)`;
          row.stage_up_tmcpm = row.top_down_cpm
            ? this.round_to(row.top_down_cpm)
            : null;
          // Данные текущего этапа + все, что ниже
          row.stage_down_impr = row.imp_up
            ? this.round_to_without_fixed(row.imp_up, 0)
            : null;
          row.stage_down_lp = row.lp_up
            ? this.round_to_without_fixed(row.lp_up, 0)
            : null;
          row.stage_down_lp_d = !row.stage_down_lp
            ? row.stage_down_lp
            : row.stage_down_lp +
              ` (${this.round_to_percents(
                this.phat(row.stage_down_lp, row.stage_down_impr)
              )}%)`;
          row.stage_down_ltb = row.ltb_up
            ? this.round_to_without_fixed(row.ltb_up, 0)
            : null;
          row.stage_down_ltb_d = !row.stage_down_ltb
            ? row.stage_down_ltb
            : row.stage_down_ltb +
              ` (${this.round_to_percents(
                this.phat(row.stage_down_ltb, row.stage_down_impr)
              )}%)`;
          row.stage_down_tmcpm = row.bott_up_cpm
            ? this.round_to(row.bott_up_cpm)
            : null;
          // Данные текущего этапа + предыдущего этапа
          row.stage_pre_impr = row.imp_pre_up
            ? this.round_to_without_fixed(row.imp_pre_up, 0)
            : null;
          row.stage_pre_lp = row.lp_pre_up
            ? this.round_to_without_fixed(row.lp_pre_up, 0)
            : null;
          row.stage_pre_lp_d = !row.stage_pre_lp
            ? row.stage_pre_lp
            : row.stage_pre_lp +
              ` (${this.round_to_percents(
                this.phat(row.stage_pre_lp, row.imp_pre_up)
              )}%)`;
          row.stage_pre_ltb = row.ltb_pre_up
            ? this.round_to_without_fixed(row.ltb_pre_up, 0)
            : null;
          row.stage_pre_ltb_d = !row.stage_pre_ltb
            ? row.stage_pre_ltb
            : row.stage_pre_ltb +
              ` (${this.round_to_percents(
                this.phat(row.stage_pre_ltb, row.imp_pre_up)
              )}%)`;
          row.stage_pre_tmcpm = row.bott_pre_cpm
            ? this.round_to(row.bott_pre_cpm)
            : null;
          // "MaxCPM (нижняя граница)"
          row.bottom_max_cpm =
            row.stage_down_tmcpm && row.stage_pre_tmcpm
              ? Math.max(
                  Number(row.stage_down_tmcpm),
                  Number(row.stage_pre_tmcpm)
                )
              : null;
          // "MaxCPM (верхняя граница)"
          row.top_max_cpm =
            row.stage_tmcpm && row.stage_up_tmcpm
              ? Math.min(Number(row.stage_tmcpm), Number(row.stage_up_tmcpm))
              : null;
          // Минимальный из MaxCPM параллельных связок (нижняя граница)
          row.parallel_bottom_min_max_cpm = "-";
          // Минимальный из MaxCPM параллельных связок (верхняя граница)
          row.parallel_top_min_max_cpm = Math.min(
            ...[
              Number(row.parallel_old_top_max_cpm ?? 0),
              Number(row.parallel_new_top_max_cpm ?? 0),
              Number(row.parallel_self_old_top_max_cpm ?? 0),
              Number(row.parallel_another_c_top_max_cpm ?? 0),
            ].filter((a) => a > 0)
          );
          // "Итоговый MaxCPM (нижняя граница)"
          row.bottom_res_max_cpm = row.bottom_max_cpm;
          // "Итоговый MaxCPM (верхняя граница)"
          row.top_res_max_cpm = Math.min(
            ...[
              Number(row.top_max_cpm),
              Number(row.parallel_old_top_max_cpm ?? 0),
              Number(row.parallel_new_top_max_cpm ?? 0),
              Number(row.parallel_self_old_top_max_cpm ?? 0),
              Number(row.parallel_another_c_top_max_cpm ?? 0),
            ].filter((a) => a > 0)
          );

          row.site_cpm =
            row.calc_vector == 1 ? row.top_max_cpm : row.bottom_max_cpm;
          row.is_suitable = row.site_cpm > Number(row.cpm) ? "+" : "-";
        });
        this.topTableData = [data[0]];
        this.middleTableData = [...data];

        // #TODO: Для получения типа этапа нужна интеграция с бд
        /**
        { value: "is_suitable" },
         */
        this.bottomTableData = [...data];
      } catch (e) {
        this.makeToast(
          "Ошибка при получении данных с сервера.",
          e.message,
          "danger"
        );
      } finally {
        this.loading = false;
      }
    },
    round_to_percents(num, digits = 2) {
      const round_multiplier = Math.pow(100, digits);
      return (
        (Math.round(num * round_multiplier) / round_multiplier) *
        100
      ).toFixed(2);
    },
    round_to(num, digits = 2) {
      const round_multiplier = Math.pow(100, digits);
      return (Math.round(num * round_multiplier) / round_multiplier).toFixed(2);
    },
    round_to_without_fixed(num, digits = 2) {
      const round_multiplier = Math.pow(100, digits);
      return Math.round(num * round_multiplier) / round_multiplier;
    },
    handleResize() {
      this.heightTable = `${window.innerHeight - 280}px`;
    },
    choose_color_class(index, schema_num = 0) {
      switch (schema_num) {
        default:
        case 0:
          if (index >= 0 && index <= 9) {
            return "color-content-1";
          } else if (index >= 10 && index <= 11) {
            return "color-content-2";
          } else if (index >= 12) {
            return "color-content-3";
          }
          break;
        case 1:
          if (index >= 0 && index <= 1) {
            return "color-content-1";
          } else if (index >= 2 && index <= 9) {
            return "color-content-3";
          } else if (index >= 10 && index <= 17) {
            return "color-content-4";
          } else if (index >= 18 && index <= 19) {
            return "color-content-5";
          } else if (index >= 20 && index <= 21) {
            return "color-content-6";
          } else if (index >= 22 && index <= 23) {
            return "color-content-7";
          }
          break;
        case 2:
          if (index >= 0 && index <= 3) {
            return "color-content-1";
          } else if (index >= 4) {
            return "color-content-8";
          }
          break;
      }
      return "color-content-1";
    },
  },
};
</script>

<style scoped>
::v-deep .table-bordered th,
::v-deep .table-bordered td {
  border-color: #777;
}
::v-deep .color-1 {
  background-color: #f9cb9c;
}
::v-deep .color-2 {
  background-color: #ffe599;
}
::v-deep .color-3 {
  background-color: #a4c2f4;
}
::v-deep .color-4 {
  background-color: #9fc5e8;
}
::v-deep .color-5 {
  background-color: #d9d2e9;
}
::v-deep .color-6 {
  background-color: #d5a6bd;
}
::v-deep .color-7 {
  background-color: #b4a7d6;
}
::v-deep .color-8 {
  background-color: #dd7e6b;
}

::v-deep .color-content-1 {
  background-color: #fce5cd;
}
::v-deep .color-content-2 {
  background-color: #fff2cc;
}
::v-deep .color-content-3 {
  background-color: #c9daf8;
}
::v-deep .color-content-4 {
  background-color: #cfe2f3;
}
::v-deep .color-content-5 {
  background-color: #eae6f3;
}
::v-deep .color-content-6 {
  background-color: #ead1dc;
}
::v-deep .color-content-7 {
  background-color: #d9d2e9;
}
::v-deep .color-content-8 {
  background-color: #e6b8af;
}

::v-deep .ltb30 {
  display: block;
  max-width: 300px;
  overflow-x: scroll;
  padding: 2px;
}
</style>
