<template>
  <div>
    <b-container fluid>
      <h2>Очереди на обновление</h2>
      <hr />
      <b-skeleton-wrapper class="mt-4" :loading="loadQueues">
        <template #loading>
          <b-form class="mt-4" inline>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
          </b-form>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </template>

        <b-row>
          <b-col sm>
            <multiselect
              class="mb-2"
              v-model="statusFilter"
              tag-placeholder="Статус"
              placeholder="Статус"
              label="name"
              track-by="code"
              :options="statuses"
              :taggable="true"
              :multiple="false"
            ></multiselect>
          </b-col>
          <b-col sm>
            <multiselect
              class="mb-2"
              v-model="bundleIDFilter"
              tag-placeholder="ID связки"
              placeholder="ID связки"
              label="name"
              track-by="code"
              :options="bundlesIds"
              :taggable="true"
              :multiple="false"
            ></multiselect>
          </b-col>
          <b-col sm>
            <b-form-input
              class="mb-2"
              type="number"
              v-model="campaignIDFilter"
              placeholder="ID кампании"
            ></b-form-input>
          </b-col>
          <b-col sm>
            <b-form-input
              class="mb-2"
              type="text"
              v-model="placementFilter"
              placeholder="Площадка"
            ></b-form-input>
          </b-col>
          <b-col sm>
            <multiselect
              class="mb-2"
              v-model="queue"
              tag-placeholder="Очередь"
              placeholder="Очередь"
              label="name"
              track-by="code"
              :options="queuesFlags"
              :taggable="true"
              :multiple="false"
            ></multiselect>
          </b-col>
          <b-col sm>
            <b-button @click="getQueues(true)" variant="primary"
              >Применить</b-button
            >
          </b-col>
        </b-row>
        <b-skeleton-wrapper class="mt-4" :loading="loadLogger">
          <template #loading>
            <b-skeleton class="col-sm mr-2 mb-2" type="header"></b-skeleton>
            <b-row class="pl-3">
              <b-col>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
              </b-col>
              <b-col>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
              </b-col>
            </b-row>
          </template>
          <b-card
            bg-variant="success"
            text-variant="white"
            header="Обновление  площадок"
            class="text-center mb-4 mt-4"
          >
            <b-row class="pl-3">
              <b-col sm>
                <h6>Дата обновления: {{ updateTrigger.date }}</h6>
              </b-col>
              <b-col sm>
                <h6>Продолжительнсть: {{ updateTrigger.duration }}</h6>
              </b-col>
            </b-row>
          </b-card>
        </b-skeleton-wrapper>
        <b-row>
          <b-col sm="8">
            <bar-chart
              :height="150"
              :chartdata="chartData"
              :options="chartOptions"
            />
          </b-col>
          <b-col sm="4">
            <b-input-group size="sm">
              <b-form-input
                v-model="filterForceBlack"
                type="search"
                id="filterInput"
                placeholder="Введите ID связки "
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  :disabled="!filterForceBlack"
                  @click="filterForceBlack = ''"
                  >Очистить</b-button
                >
              </b-input-group-append>
            </b-input-group>

            <b-table
              class="mt-2"
              ref="selectableTable"
              style="font-size: 14px"
              sticky-header="290px"
              :fields="fieldsForceBlackList"
              striped
              responsive="xl"
              hover
              small
              :filter="filterForceBlack"
              :items="forcesBlackList"
            >
            </b-table> </b-col
        ></b-row>

        <div class="mt-4" v-if="queues.length === 0">
          <h1 class="text-center">Ни чего не найдено!</h1>
        </div>
        <b-overlay spinner-variant="primary" :show="isBusy" rounded="sm">
          <b-table
            @sort-changed="sorted"
            :no-local-sorting="true"
            class="mt-4"
            ref="selectableTable"
            style="font-size: 14px"
            :sticky-header="heightTable"
            :fields="fields"
            striped
            responsive="xl"
            hover
            small
            @row-clicked="stageClick"
            :items="queues"
          >
            <template #cell(TYPE_PLACEMENTS)="data">
              <div
                :style="
                  Number(data.value) === 1
                    ? 'background:black;color:white'
                    : 'color:black'
                "
              >
                {{ Number(data.value) === 1 ? "Блек" : "Вайт" }}
              </div>
            </template>

            <template #cell(PLACEMENTS)="data">
              <div
                style="
                  max-width: 100px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ data.value }}
              </div>
            </template>

            <template #cell(TS_ID)="data">
              <div
                :style="`color:${
                  data.value === 'Создается...'
                    ? 'blue'
                    : data.value === 'Ожидает модерации...'
                    ? 'orange'
                    : 'green'
                }`"
              >
                {{
                  data.value != "Создается..." &&
                  data.value != "Ожидает модерации..."
                    ? "Активна"
                    : data.value
                }}
              </div>
            </template>

            <template #cell(STATUS)="data">
              <div>
                <b-icon
                  :variant="Number(data.value) === 1 ? 'success' : 'warning'"
                  :icon="
                    Number(data.value) === 1 ? 'circle-fill' : 'circle-fill'
                  "
                  aria-hidden="true"
                ></b-icon>
              </div>
            </template>

            <template #cell(QUEUE)="data">
              <div
                :style="`color:${Number(data.value) === 0 ? 'green' : 'red'}`"
              >
                {{ Number(data.value) === 0 ? "Завершена" : data.value }}
              </div>
            </template>

            <template #cell(UPDATE_DURATION)="data">
              <span
                :style="`color:${
                  colorsGradisent.find(
                    (a) =>
                      Number(data.value) >= a.value[0] &&
                      Number(data.value) <= a.value[1]
                  ).color
                }`"
              >
                {{ Number(Number(data.value) / 60).toFixed(2) }}
              </span>
              мин.
            </template>

            <template #cell()="data">
              <div style="white-space: nowrap">
                {{ data.value }}
              </div>
            </template>
            <template #head()="data">
              <div style="white-space: nowrap">{{ data.label }}</div>
            </template>
          </b-table>
        </b-overlay>
        <b-pagination
          v-if="queues.length != 0"
          v-model="currentPage"
          :total-rows="totalRows"
          per-page="50"
          align="right"
          size="sm"
          class="my-0"
          @change="selectPagination"
        ></b-pagination>
      </b-skeleton-wrapper>

      <!-- Модельаное окно ПОдробностей этапа -->
      <b-modal
        v-model="modalMoreStage"
        :title="`Подробнее ${headModalMoreStage}`"
        header-bg-variant="primary"
        header-text-variant="white"
        @hide="hideMOdalMoreStage"
        ok-only
      >
        <!-- <b-overlay
          spinner-variant="primary"
          :show="loadAddBlackList"
          rounded="sm"
        > -->
        <h6>{{ allPlacements.type }}</h6>
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Введите ID площадки "
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"
              >Очистить</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <b-table
          :fields="[
            {
              key: 'id_placement',
              label: 'ID площадки',
              stickyColumn: true,
            },
          ]"
          ref="selectableTable"
          style="font-size: 14px"
          :sticky-header="true"
          striped
          responsive
          hover
          :filter="filter"
          :filter-included-fields="['id_placement']"
          small
          :items="
            allPlacements.text.split(',').map((a) => {
              return { id_placement: a };
            })
          "
        >
          <template #cell()="data">
            <div style="white-space: nowrap">
              {{ data.value }}
            </div>
          </template>
          <template #head()="data">
            <div style="white-space: nowrap">{{ data.label }}</div>
          </template>

          <template #cell(action)="data">
            <b-button
              v-if="
                iHover === data.index &&
                allPlacements.type === 'Вайт лист' &&
                bubndleData[0].status != 'На паузе'
              "
              variant="success"
              @click="addToBlack(data.item.id_placement)"
              size="sm"
              >Добавить в блек</b-button
            >
          </template>
        </b-table>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right mr-2"
              @click="modalMoreStage = false"
            >
              Ok
            </b-button>
          </div>
        </template>
        <!-- </b-overlay> -->
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import BarChart from "./chart_bar.vue";
// import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import {
  API_QUEUES,
  GRADIENT_COLOR_GREEN_RED,
  API_BUNDLES,
  HELP,
  API_LOGGER,
} from "../constants";
export default {
  components: {
    Multiselect,
    BarChart,
  },
  //   components: {
  //     DatePicker,
  //   },
  data() {
    return {
      isBusy: false,
      sortField: null,
      sortAsc: null,
      fieldsForceBlackList: [],

      forcesBlackList: [],
      updateTrigger: { duration: " - ", date: " - " },
      loadLogger: false,
      allPlacements: { text: "", type: "" },
      modalMoreStage: false,
      bundlesIds: [],
      bundleIDFilter: "",
      campaignIDFilter: "",
      headModalMoreStage: "",
      statusFilter: "",
      placementFilter: "",
      statuses: [
        { name: "Запущена", code: 1 },
        { name: "на паузе", code: 2 },
      ],
      filterForceBlack: "",
      filter: "",
      queue: "",
      queuesFlags: [
        { name: "Активная очередь", code: false },
        { name: "Завершенная очередь", code: true },
      ],

      messageLogFilter: "",
      dateBetWeen: [],
      heightTable: "300px",
      loadQueues: true,
      fields: [],
      queues: [],
      currentPage: 1,
      totalRows: 50,
      colorsGradisent: GRADIENT_COLOR_GREEN_RED,
      chartOptions: {},
      chartData: {},
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.getQueues();
    this.handleResize();
    this.getBundlesIds();
  },
  watch: {
    currentPage() {
      this.getQueues(false, true);
    },
  },
  methods: {
    sorted(ctx) {
      // console.log(ctx);
      this.sortField = ctx.sortBy;
      this.sortAsc = !ctx.sortDesc;

      // console.log(this.sortAsc);
      // console.log(this.sortField);
      this.getQueues(true, true);
    },
    /**
     * Шаблон строки с долларом
     * @param {I} v
     */
    strUSD(v) {
      const str = String(v);
      let finalVUSD;
      if (v.indexOf("-") != -1) {
        finalVUSD = `-$${str.split("-")[1]}`;
      } else {
        finalVUSD = `$${str}`;
      }
      return finalVUSD;
    },
    selectPagination(v) {
      this.currentPage = v;
    },
    handleResize() {
      this.heightTable = `${window.innerHeight - 280}px`;
    },
    getForceBlackList() {
      axios
        .get(API_BUNDLES.FORCE_BLACK_LIST)
        .then((res) => {
          const d = res.data;

          if (d.length > 0) {
            this.forcesBlackList = d.map((a) => {
              return {
                ...a,
                stop_time: a.stop_time
                  ? new Date(a.stop_time)
                      .toLocaleString("ru-RU")
                      .split(", ")
                      .join(" ")
                  : "",
                start_time: a.start_time
                  ? new Date(a.start_time)
                      .toLocaleString("ru-RU")
                      .split(", ")
                      .join(" ")
                  : "",
              };
            });
            // console.log(this.forcesBlackList);
            // console.log(this.bubndleData);
            this.fieldsForceBlackList = Object.keys(
              this.forcesBlackList[0]
            ).map((a) => {
              let title = "";
              if (a === "bundle_id") title = "ID связки";
              else if (a === "stage") title = "Этап";
              else if (a === "stop_time") title = "Дата остановки";
              else if (a === "start_time") title = "Дата запуска";
              return { label: title, key: a, sortable: false };
            });
            // console.log(this.chartData);
          } else {
            this.forcesBlackList = [];
            this.fieldsForceBlackList = [];
          }
          this.loadLogger = false;
        })
        .catch((er) => {
          const e = er.response.data.message
            ? er.response.data.message
            : er.message
            ? er.message
            : JSON.stringify(er);
          this.makeToast(
            "Ошибка получения принудительного блне листа",
            e,
            "danger"
          );
        });
    },
    getLogger() {
      this.loadLogger = true;
      axios
        .get(API_LOGGER.UPDATE_TRIGGER)
        .catch((er) => {
          this.loadLogger = true;
          const e = er.response.data.message;
          console.log(er);
          this.makeToast("Ошибка получения лога", e, "danger");
        })
        .then((res) => {
          this.getForceBlackList();
          let r = res.data.data;
          r = r.length
            ? {
                duration: r[0].message.split("after ")[1],
                date: new Date(r[0].create_date)
                  .toLocaleString("ru-RU")
                  .split(", ")
                  .join(" "),
              }
            : { duration: " - ", date: " - " };
          this.updateTrigger = r;
          console.log(this.updateTrigger);
        });
    },
    getQueues(reset = false, sort = false) {
      if (reset) this.currentPage = 1;

      if (!sort) this.loadQueues = true;
      else this.isBusy = true;

      //this.queues = [];
      // this.loadQueues = true;
      const status = this.statusFilter ? this.statusFilter.code : null;
      const bundleId = this.bundleIDFilter ? this.bundleIDFilter.code : null;
      const campaignId = this.campaignIDFilter ? this.campaignIDFilter : null;
      const queue = this.queue ? this.queue.code : null;
      const placements = this.placementFilter ? this.placementFilter : null;
      console.log(
        `${API_QUEUES.FIND}page=${this.currentPage}&status=${status}&campaignID=${campaignId}&bundleID=${bundleId}&placements=${placements}&queue=${queue}&sortField=${this.sortField}&asc=${this.sortAsc}`
      );
      axios
        .get(
          encodeURI(
            `${API_QUEUES.FIND}page=${this.currentPage}&status=${status}&campaignID=${campaignId}&bundleID=${bundleId}&placements=${placements}&queue=${queue}&sortField=${this.sortField}&asc=${this.sortAsc}`
          )
        )
        .catch((er) => {
          const e = er.response.data.message;
          console.log(er);
          if (!sort) this.loadQueues = false;
          else this.isBusy = false;
          this.makeToast("Ошибка получения очередей кампани", e, "danger");
        })
        .then((res) => {
          const d = res.data.data;
          // console.log(d);
          if (d.length > 0) {
            this.queues = d.map((a) => {
              return {
                ...a,
                BID: this.strUSD(a.BID),
                UPDATE_DATE: a.UPDATE_DATE
                  ? new Date(Number(a.UPDATE_DATE * 1000))
                      .toLocaleString("ru-RU")
                      .split(", ")
                      .join(" ")
                  : "",
              };
            });
            // console.log(this.bubndleData);
            this.fields = Object.keys(this.queues[0]).map((a) => {
              let title = "";
              if (a === "ID") title = "ID кампании";
              else if (a === "STATUS") title = "Статус";
              else if (a === "BUNDLE_ID") title = "ID связки";
              else if (a === "TS_ID") title = "Статус в сети";
              else if (a === "NETWORK_NAME") title = "Сеть";
              else if (a === "CODE_STAGE") title = "Этап";
              else if (a === "BID") title = "Ставка";
              else if (a === "TYPE_PLACEMENTS") title = "Тип площадок";
              else if (a === "PLACEMENTS") title = "Площадки";
              else if (a === "UPDATE_DATE") title = "Дата обновления";
              // else if (a === "UPDATE_DURATION") title = "Продолжительность";
              // else if (a === "QUEUE") title = "Очередь";
              else if (a === "PRIORITY") title = "Приоритет";
              return {
                label: title,
                key: a,
                sortable:
                  a === "ID" || a === "UPDATE_DATE" || a === "PRIORITY"
                    ? true
                    : false,
              };
            });
            this.totalRows = Number(res.data.amount);
            if (!sort) this.loadQueues = false;
            else this.isBusy = false;

            this.chartOptions = {
              responsive: true,
              maintainAspectRatio: true,
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: "Максимальный приоритет",
                    },
                  },
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: "Рекламная сеть",
                    },
                  },
                ],
              },
            };

            const dataSet = res.data.priorities.map((a) => Number(a.max));
            const labels = res.data.priorities.map((a) => `${a.network} ${a.bundle}`);
            // console.log(dataSet);
            this.chartData = {
              labels: labels,
              datasets: [
                {
                  backgroundColor: [
                    "#00b894",
                    "#6c5ce7",
                    "#ff7675",
                    "#0984e3",
                    "#fdcb6e",
                  ],
                  data: dataSet,
                },
              ],
            };
            this.getLogger();
            // console.log(this.chartData);
          } else {
            this.queues = [];
            this.fields = [];
            this.totalRows = Number(res.data.amount);
            this.loadQueues = false;
          }
        });
    },
    getBundlesIds() {
      //   this.LoadingBundlesIds = false;
      axios
        .get(`${HELP.BUNDLES_IDS}`)
        .then((res) => {
          this.bundlesIds = res.data.map((a) => {
            return { name: a, code: a };
          });
          //   this.LoadingBundlesIds = true;
          //   this.doisabledLoading();
        })
        .catch((er) => {
          // this.LoadingBundlesIds = false;
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка получения id связок", e, "danger");
        });
    },
    hideMOdalMoreStage() {
      this.allPlacements = { text: "", type: "" };
      this.headModalMoreStage = "";
    },
    stageClick(item) {
      this.modalMoreStage = true;
      this.allPlacements.text = item.PLACEMENTS;
      this.allPlacements.type =
        item.TYPE_PLACEMENTS === "0" ? "Вайт лист" : "Блек лист";
      this.headModalMoreStage = `#${item.CODE_STAGE} - кампания #${item.ID}`;
      console.log(item);
    },
    //Тост
    makeToast(title, body, variant = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: 100;
}
</style>