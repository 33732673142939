<template>
  <div>
    <b-container fluid>
      <h2>Архив связок</h2>
      <hr />

      <b-skeleton-wrapper class="mt-4" :loading="loadBundleDraft">
        <template #loading>
          <b-form inline>
            <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
          </b-form>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </template>
        <b-button class="mb-3" @click="getDraft" variant="outline-primary"
          >Обновить
        </b-button>
        <div class="mt-4" v-if="bundlesDraft.length === 0">
          <h1 class="text-center">Ни чего не найдено!</h1>
        </div>
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Введите ID связки"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"
              >Очистить</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <b-table
          ref="selectableTable"
          style="font-size: 14px"
          :sticky-header="heightTable"
          :fields="fields"
          striped
          responsive="xl"
          hover
          small
          :filter="filter"
          :filter-included-fields="filterOn"
          :items="bundlesDraft"
        >
          <template #cell()="data">
            <div style="white-space: nowrap">
              {{ data.value }}
            </div>
          </template>
          <template #head()="data">
            <div style="white-space: nowrap">{{ data.label }}</div>
          </template>
        </b-table>
      </b-skeleton-wrapper>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { API_BUNDLES } from "../constants";
export default {
  data() {
    return {
      loadBundleDraft: true,
      filter: null,
      bundlesDraft: [],
      fields: [],
      heightTable: "300px",
      filterOn: ["id"],
    };
  },
  methods: {
    //Ищменпие размера
    handleResize() {
      this.heightTable = `${window.innerHeight - 280}px`;
    },
    //Тост
    makeToast(title, body, variant = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    /**
     * Шаблон строки с долларом
     * @param {I} v
     */
    strUSD(v) {
      const str = String(v);
      let finalVUSD;
      if (v.indexOf("-") != -1) {
        finalVUSD = `-$${str.split("-")[1]}`;
      } else {
        finalVUSD = `$${str}`;
      }
      return finalVUSD;
    },
    /**
     * Шаблон строки с процентом
     * @param {I} v
     */
    strPercent(v) {
      return `${v}%`;
    },
    getDraft() {
      this.loadBundleDraft = true;
      axios
        .get(`${API_BUNDLES.DRAFT}`)
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка получения врхива связок", e, "danger");
        })
        .then((res) => {
          const d = res.data;
          this.bundlesDraft = d.map((a) => {
            return {
              id: a.id,
              source_id: a.source_id,
              code: a.code,
              min_cpm: this.strUSD(Number(a.min_cpm).toFixed(2)),
              max_cpm: this.strUSD(Number(a.max_cpm).toFixed(2)),
              min_roi: this.strPercent(Math.round(Number(a.min_roi) * 100)),
              create_date: new Date(a.create_date)
                .toLocaleString("ru-RU")
                .split(", ")
                .join(" "),
              update_date: new Date(a.update_date)
                .toLocaleString("ru-RU")
                .split(", ")
                .join(" "),
            };
          });
          // console.log(this.bubndleData);
          this.fields = Object.keys(this.bundlesDraft[0]).map((a) => {
            let title = "";
            if (a === "id") title = "ID";
            else if (a === "source_id") title = "ID ист.";
            else if (a === "code") title = "Страна";
            else if (a === "min_cpm") title = "мин. CPM";
            else if (a === "max_cpm") title = "макс. CPM";
            else if (a === "min_roi") title = "мин. ROI";
            else if (a === "create_date") title = "Дата создания";
            else if (a === "update_date") title = "Дата удаления";
            return { label: title, key: a };
          });

          this.loadBundleDraft = false;
        });
    },
  },
  mounted() {
    this.getDraft();
    this.handleResize();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
h1 {
  font-weight: 100;
}
</style>