import dictionaries from "../../utility/dictionaries_new";
import DatePicker from "vue2-datepicker";

export default {
    components: {
        DatePicker,
    },
    props: ["dataFilter"],
    data() {
        return {
            loadArchive: false,
            loading: false,
            filterQuery: '',
            fields: [{
                    value: "date",
                    text: "Дата",
                    width: "200px",
                    sortable: true,
                },
                {
                    value: "action",
                    text: "Действие",
                    sortable: false,
                }
            ],
            history: [],
            pageOptions: {
                page: 1,
            },
            reportDateBetWeen: [],
            id: 0,
            totalRows: 0,
            heightTable: "300px",
        }
    },
    watch: {
        pageOptions: {
            handler(a) {
                this.currentPage = a.page;
                this.perPage = a.itemsPerPage;
                this.sortField = a.sortBy[0];
                this.sortAsc = !a.sortDesc[0] ? "asc" : "desc";
                //TODO: need refactoring! too much requests
                this.showArchive();
            },
            deep: true,
        },
    },
    methods: {
        ...dictionaries,
        handleResize() {
            this.heightTable = `${window.innerHeight}px`;
        },
        async showArchive() {
            const dateFrom = this.reportDateBetWeen[0];
            const dateTo = this.reportDateBetWeen[1];
            const data = await this.getArchive(this.id, this.filterQuery, dateFrom, dateTo, this.sortField, this.sortAsc, this.perPage, this.currentPage);
            if (data.data.length == 0) {
                this.filterQuery = '';
            }
            this.totalRows = data.amount;
            this.history = data.data.map(a => {
                a.date = new Date(a.date)
                    .toLocaleString("ru-RU")
                    .split(", ")
                    .join(" ");
                return a;
            });
        }
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        // this.dataFilter.apply = !this.dataFilter.apply;
    },
    mounted() {
        this.id = this.$route.params.id;
        this.handleResize();
    },
};