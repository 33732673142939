<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ["chartdata", "options"],
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
  watch: {
    chartdata: {
      handler(v) {
        // console.log(v);
        this.renderChart(v, this.options);
      },
      deep: true,
    },
  },
};
</script>