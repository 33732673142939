import {
    HELP,
    API_BUNDLES,
    API_PARTNERS,
    API_LANDERS,
    API_BQ_ROUTES
} from "../constants";
import utility_funtions from "../utility/utility-funtions";
import axios from "axios";

export default {
    ...utility_funtions,

    //Получаем id всех связок
    getBundlesIds() {
        return axios
            .get(`${HELP.BUNDLES_IDS}`)
            .then((res) => res.data)
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка получения id связок", e, "danger");
                return [];
            });
    },
    //Получаем все офферы
    getOffers() {
        return axios
            .get(API_PARTNERS.GET)
            .then((offers) => offers.data)
            .catch((err) => {
                console.log(err);
                const e = err.response ?
                    err.response.data.message ?
                    err.response.data.message :
                    err.message ?
                    err.message :
                    JSON.stringify(err) :
                    err.message;
                this.makeToast("Ошибка получения партнеров", e, "error");
                return [];
            });
    },
    //Получаем  всех страны
    getCountries() {
        return axios
            .get(`${HELP.COUNTRIES}`)
            .then((res) => res.data)
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка получения стран", e, "danger");
                return [];
            });
    },
    //Получаем  всех источников
    async getSources() {
        return axios
            .get(`${HELP.SOURCES}`)
            .then((res) => res.data)
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка получения источников", e, "danger");
                return [];
            });
    },
    // Расчитываем и получаем приоритеты обновления связок
    async getPriorities() {
        return axios
            .get(API_BQ_ROUTES.PRIORITIES)
            .then((res) => res.data.data)
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка получения приоритетов связок на обновление", e, "danger");
                return [];
            });
    },
    // Дергаем историю изменения связки
    async getArchive(bundle_id, query, dateFrom, dateTo, sortField, dirSort, sizePage, page) {
        return axios
            .get(`${API_BUNDLES.FIND}${bundle_id}/archive?query=${query}&dateFrom=${dateFrom}&dateTo=${dateTo}&fieldSort=${sortField}&dirSort=${dirSort}&sizePage=${sizePage}&page=${page}`)
            .then((res) => res.data)
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка получения истории изменения связки", e, "danger");
                return [];
            });
    },
    // Получаем список всех лендов
    getAllLanders() {
        return axios
            .get(API_LANDERS.FULL)
            .then((landers) => landers.data)
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка getAllLanders", e, "danger");
                return [];
            });
    },
}