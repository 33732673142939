<template>
  <div>
    <b-container fluid>
      <h2>
        {{ $route.params.partner }}_{{ $route.params.source }}_{{
          $route.params.country
        }}
      </h2>
      <b-row class="mb-3">
        <b-col>
          <b-button @click="showAddCustomRateModal = true" variant="primary"
            >Добавить кастомный рейт</b-button
          >
        </b-col>
        <b-col> </b-col>
        <b-col> </b-col>
        <b-col> </b-col>
        <b-col> </b-col>
        <b-col>
          <b-form-select
            v-model="cellVariantSelected"
            :options="cellVariants"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </template>

        <v-data-table
          hide-default-header
          hide-default-footer
          :items="renderData"
          :headers="headers"
          :items-per-page="rows_count"
          class="elevation-1 mb-3 table-bordered"
        >
          <template v-slot:body="{ items }">
            <tr v-for="(item, idx) in items" :key="idx">
              <td v-for="(header, i) in headers" :key="i" class="text-center">
                <div
                  class="p-1"
                  v-if="item[header.value]"
                  :style="'background-color:' + item[header.value].color"
                >
                  {{ item[header.value].value }}
                </div>
              </td>
            </tr>
          </template>

          <template v-slot:[`header`]="{}">
            <b-tr>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                >{{ $route.params.partner }}_{{ $route.params.source }}_{{
                  $route.params.country
                }}
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; height: auto; text-align: center"
                colspan="1"
              ></b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                >Дата
              </b-th>

              <b-th
                class="sticky_h pl-1 pr-1"
                variant="light"
                style="white-space: nowrap; height: auto; text-align: center"
                colspan="1"
                v-for="field in dates"
                :key="field.key"
                >{{ field.label }}</b-th
              >
              <b-th
                class="sticky_h"
                variant="light"
                style="
                  white-space: normal;
                  text-align: center;
                  height: auto;
                  vertical-align: middle;
                "
                rowspan="2"
                >Окупаемость за последние 5 дней
              </b-th>
            </b-tr>
            <b-tr>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                >Рейт
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; height: auto; text-align: center"
                colspan="1"
                >{{ oldRate }}
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                >Подписки:
              </b-th>

              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: normal; text-align: center; height: auto"
                colspan="1"
                v-for="(field, index) in leads"
                :key="index"
                >{{ field }}</b-th
              >
            </b-tr>
            <b-tr>
              <b-th
                class="sticky_h pl-1 pr-1"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                >Номер периода
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="
                  white-space: normal;
                  text-align: center;
                  height: auto;
                  vertical-align: middle;
                "
                >% от пред дня
              </b-th>
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                >{{ days60 }}
              </b-th>

              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                v-for="(field, index) in columns_sum"
                :key="index"
                >{{ field }}</b-th
              >
              <b-th
                class="sticky_h"
                variant="light"
                style="white-space: nowrap; text-align: center; height: auto"
                colspan="1"
                >{{ payback_last_90 }} / {{ payback_last_60 }}
              </b-th>
            </b-tr>
          </template>
        </v-data-table>
      </b-skeleton-wrapper>

      <!-- Модальаное окно добавления кастомного рейта -->
      <b-modal
        v-model="showAddCustomRateModal"
        :title="`Создание кастомного рейта`"
        header-bg-variant="primary"
        header-text-variant="white"
      >
        <b-form-input
          class="mb-3"
          type="number"
          step="0.001"
          min="0.001"
          v-model="newCustomRate"
          placeholder="Введите желаемый рейт"
        ></b-form-input>
        <date-picker
          class="mb-3"
          value-type="YYYY-MM-DD"
          placeholder="Дата окончания рейта"
          v-model="newCustomRateDateEnd"
          style="width: 100%"
        ></date-picker>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right mr-2"
              @click="submitAddCustomRateForm"
            >
              Ok
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import axios from "axios";
import { API_AD } from "../constants";
import DatePicker from "vue2-datepicker";
export default {
  data() {
    return {
      headers: [],
      renderFields: [],
      renderData: [],
      loading: false,
      rows_count: 0,
      row_len: 0,
      oldRate: 0,
      days90: 0,
      days60: 0,
      payback_last_90: 0,
      payback_last_60: 0,
      leads: [],
      dates: [],
      columns_sum: [],
      renderDataValues: [],
      renderDataNumValues: [],
      renderDataSumValues: [],
      showMode: false, // false - default, true - all data
      showModeChanging: false,
      showAddCustomRateModal: false,
      newCustomRate: null,
      newCustomRateDateEnd: null,
      cellVariantSelected: "revEstPercent",
      cellVariants: [
        { value: "date", text: "Дата" },
        { value: "revEstPercent", text: "% Est. Rev" },
        { value: "revenue", text: "Revenue" },
        { value: "epm", text: "EPM" },
        { value: "epl", text: "EPL" },
      ],
    };
  },
  components: {
    DatePicker,
  },
  watch: {
    cellVariantSelected() {
      for (let index = 0; index < this.renderData.length; index++) {
        const row = this.renderData[index];
        const keys = Object.keys(row);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (key.indexOf("cell_") == -1 || !row[key]) continue;
          // console.log(this.renderDataValues[index][i]);
          row[key] = this.renderDataValues[index][i]
            ? {
                value:
                  this.renderDataValues[index][i][this.cellVariantSelected],
                color: row[key].color,
              }
            : row[key];
        }
        this.columns_sum[index] =
          this.renderDataSumValues[index][this.cellVariantSelected];
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.drawTable();
  },
  methods: {
    submitAddCustomRateForm() {
      this.showAddCustomRateModal = false;
      const headers = { "Content-type": "application/json" };
      const body = {
        code: String(this.$route.params.country),
        partner: String(this.$route.params.partner),
        source_id: Number(this.$route.params.source),
        rate: Number(this.newCustomRate),
        date_end: String(this.newCustomRateDateEnd),
      };
      return axios
        .post(`${API_AD.ADD_CUSTOM_RATE}`, JSON.stringify(body), {
          headers: headers,
        })
        .then(() => {
          this.drawTable();
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message
            ? er.response.data.message
            : er.message
            ? er.message
            : JSON.stringify(er);
          this.loading = false;
          this.makeToast("Ошибка при submitAddCustomRateForm", e, "danger");
          return false;
        });
    },
    drawTable() {
      this.loading = true;
      const code = String(this.$route.params.country);
      const partner = String(this.$route.params.partner);
      const source_id = Number(this.$route.params.source);
      const from_date = String(this.$route.params.from_date);
      const to_date = String(this.$route.params.to_date);

      return axios
        .get(
          `${API_AD.GET}/code=${code}&source_id=${source_id}&partner=${partner}&from_date=${from_date}&to_date=${to_date}`
        )
        .then((res) => {
          const data = res?.data?.data;
          console.log("data", res?.data);
          if (res?.data?.status == 404) {
            this.renderData = rows;
            this.loading = false;
            return false;
          }
          let renderData = data.render.calculated_data;
          this.oldRate = data.rateOld;

          this.dates = [];
          for (let index = 0; index < data.render.date_period.length; index++) {
            const date = data.render.date_period[index];
            this.dates.push({
              key: "date_" + date.split(/\r?\n/)[0],
              label: date.split("T")[0],
              stickyColumn: true,
            });
          }

          this.leads = [];
          for (let index = 0; index < data.render.subs_sum.length; index++) {
            const item = data.render.subs_sum[index];
            this.leads.push(item);
          }

          if (data.render.subs_sum.length < data.render.columns_sum.length)
            this.leads.push(0);

          this.columns_sum = [];
          for (let index = 0; index < data.render.columns_sum.length; index++) {
            const item = data.render.columns_sum[index];
            this.columns_sum.push(this.round(item) + "%");
          }

          this.days90 = this.round(data.render.days90) + "%";
          this.days60 = this.round(data.render.days60) + "%";
          this.payback_last_90 = this.round(data.render.payback_last_90) + "%";
          this.payback_last_60 = this.round(data.render.payback_last_60) + "%";

          let rows = [];
          this.rows_count = data.render.period_days.length;
          for (let index = 0; index < data.render.period_days.length; index++) {
            this.renderDataValues[index] = [];
            this.renderDataNumValues[index] = [];
            this.headers = [];
            let row = {};
            const period = data.render.period_days[index];
            row["period"] = period;
            this.headers.push({ text: "", value: "period" });
            if (index == 0) {
              row["previous_date_percent"] = null;
            } else {
              row["previous_date_percent"] =
                this.round(data.render.previous_date_percent[index - 1]) + "%";
            }
            this.headers.push({ text: "", value: "previous_date_percent" });

            row["rows_average"] =
              this.round(data.render.rows_average[index]) + "%";
            this.headers.push({ text: "", value: "rows_average" });

            renderData[index].map((a, i) => {
              row["cell_" + i] = a;
              this.headers.push({ text: "", value: "cell_" + i });
            });
            row["payback"] =
              (data.render.payback[index] * 100).toFixed(2) + "%";
            this.headers.push({ text: "", value: "payback" });

            Object.keys(row).map((key, i) => {
              if (!row[key] || typeof row[key] != "object") {
                return;
              }
              this.renderDataNumValues[index][i] = {
                revEstPercent: row[key].estimateRevenue,
                revenue: row[key].money,
                epm: row[key].epm,
                epl: row[key].epl,
              };

              row[key].estimateRevenue =
                (row[key].estimateRevenue * 100).toFixed(2) + "%";

              const values = {
                date: this.dates[index + i - 3]
                  ? this.dates[index + i - 3].label
                  : null,
                revEstPercent: row[key].estimateRevenue,
                revenue: row[key].money
                  ? "$" + (Math.round(row[key].money * 100) / 100).toFixed(2)
                  : "",
                epm: (
                  Math.round(row[key].epm * 10000000000) / 10000000000
                ).toFixed(10),
                epl: (
                  Math.round(row[key].epl * 10000000000) / 10000000000
                ).toFixed(10),
              };

              this.renderDataValues[index][i] = values;
              row[key] = row[key].estimateRevenue;
              return;
            });
            rows.push(row);
          }
          rows = rows.map((row) => {
            Object.keys(row).map((key, i) => {
              const background = this.getColor(
                row["rows_average"]
                  ? row["rows_average"].value
                  : row["rows_average"],
                row[key] ? row[key] : row[key],
                i
              );
              row[key] = { value: row[key], color: background };
            });
            return row;
          });
          //#region Формирование сумм по столбцам
          this.renderDataSumValues = this.renderDataNumValues.map(() => {
            return {
              date: "-",
              revEstPercent: 0,
              revenue: 0,
              epl: 0,
              epm: 0,
            };
          });
          for (
            let index = 0;
            index < this.renderDataNumValues.length;
            index++
          ) {
            const row = this.renderDataNumValues[index];
            for (let i = 3; i < row.length; i++) {
              const cell = row[i];
              if (!this.renderDataSumValues[i - 3]) continue;
              this.renderDataSumValues[i - 3].revEstPercent += cell
                ? cell.revEstPercent
                : 0;
              this.renderDataSumValues[i - 3].revenue += cell
                ? cell.revenue
                : 0;
              this.renderDataSumValues[i - 3].epl += cell ? cell.epl : 0;
              this.renderDataSumValues[i - 3].epm += cell ? cell.epm : 0;
            }
          }
          for (
            let index = 0;
            index < this.renderDataSumValues.length;
            index++
          ) {
            if (!this.renderDataSumValues[index]) continue;
            this.renderDataSumValues[index].revEstPercent =
              (this.renderDataSumValues[index].revEstPercent * 100).toFixed(2) +
              "%";
            this.renderDataSumValues[index].revenue = this.renderDataSumValues[
              index
            ].revenue
              ? "$" +
                (
                  Math.round(this.renderDataSumValues[index].revenue * 100) /
                  100
                ).toFixed(2)
              : "";
            this.renderDataSumValues[index].epm = (
              Math.round(this.renderDataSumValues[index].epm * 10000000000) /
              10000000000
            ).toFixed(10);
            this.renderDataSumValues[index].epl = (
              Math.round(this.renderDataSumValues[index].epl * 10000000000) /
              10000000000
            ).toFixed(10);
          }
          //#endregion Формирование сумм по столбцам
          this.renderData = rows;
          this.loading = false;
          return true;
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message
            ? er.response.data.message
            : er.message
            ? er.message
            : JSON.stringify(er);
          this.loading = false;
          this.makeToast("Ошибка при получении данных из ОА", e, "danger");
          return false;
        });
    },
    round(num) {
      return Math.round(num * 10000) / 100;
    },
    pickHex(color1, color2, weight) {
      const p = weight;
      const w = p * 2 - 1;
      const w1 = (w / 1 + 1) / 2;
      const w2 = 1 - w1;
      const rgb = [
        Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2),
      ];
      return rgb;
    },
    getColor(target, value, idx) {
      idx = Number(idx);
      if (
        !target ||
        !value ||
        String(target).indexOf("%") == -1 ||
        String(value).indexOf("%") == -1 ||
        idx < 3 ||
        idx == this.row_len - 1
      )
        return "rgba(255,255,255,0)";

      target = Number(target.slice(0, target.length - 1));
      value = Number(value.slice(0, value.length - 1));

      if (isNaN(target) || isNaN(value)) return "rgba(255,255,255,0)";

      const min = Number(target) / 2;
      const middle = Number(target);
      const max = Number(target) * 1.5;
      const gradient = [
        [min, [230, 124, 115]],
        [middle, [255, 255, 255]],
        [max, [87, 187, 138]],
      ];

      const sliderWidth = 500;

      let colorRange = [];

      if (value <= gradient[0][0]) {
        colorRange = [0, 0];
      } else if (value <= gradient[1][0]) {
        colorRange = [0, 1];
      } else if (value <= gradient[2][0]) {
        colorRange = [1, 2];
      } else {
        colorRange = [2, 2];
      }
      //Get the two closest colors
      const firstcolor = gradient[colorRange[0]][1];
      const secondcolor = gradient[colorRange[1]][1];
      //Calculate ratio between the two closest colors
      const firstcolor_x = sliderWidth * (gradient[colorRange[0]][0] / 100);
      const secondcolor_x =
        sliderWidth * (gradient[colorRange[1]][0] / 100) - firstcolor_x;
      const slider_x = sliderWidth * (value / 100) - firstcolor_x;
      const ratio = Number.isFinite(slider_x / secondcolor_x)
        ? slider_x / secondcolor_x
        : 1;
      //Get the color with pickHex(thx, less.js's mix function!)
      const result = this.pickHex(secondcolor, firstcolor, ratio);
      return "rgba(" + result.join() + ")";
    },
    //Тост
    makeToast(title, body, variant = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
<style scoped>
::v-deep .table-sm td {
  padding: 0 !important;
}
.sticky_h {
  height: 100px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3 !important;
}
::v-deep .table-sm td {
  padding: 0 !important;
}

::v-deep tr td:first-child {
  left: 0;
  width: 134px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 800 !important;
  background: #f5f5f5;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr td:nth-child(2) {
  left: 134px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 800 !important;
  background: #f5f5f5;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}
::v-deep tr td:nth-child(3) {
  left: calc(134px + 69px);
  width: 84px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 800 !important;
  background: #f5f5f5;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr th:first-child {
  left: 0;
  width: 134px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr th:nth-child(2):not(.notfix) {
  left: 134px;
  width: 69px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}
::v-deep tr th:nth-child(3):not(.notfix) {
  left: calc(134px + 69px);
  width: 84px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}
/* 
::v-deep tr th:nth-child(3):not(.notfix) {
  left: calc(81px + 35px);
  width: 143px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}
::v-deep tr th:nth-child(4):not(.notfix),
::v-deep tr th.col-4-fix:not(.notfix) {
  left: calc(81px + 35px + 143px);
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
} */
</style>