<template>
  <div>
    <b-container fluid>
      <h2>Потери трафика по площадкам</h2>
      <hr />
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </template>

        <b-row class="mt-2">
          <b-col sm class="px-0 mx-2">
            <multiselect
              class="mb-2"
              v-model="valueBundlesIds"
              tag-placeholder="Связки"
              placeholder="Связки"
              label="name"
              track-by="code"
              :options="bundlesIds"
              :multiple="true"
              :taggable="true"
            ></multiselect>
          </b-col>
          <b-col sm class="px-0 mx-2">
            <b-form-input
              type="text"
              v-model="valuePlacements"
              placeholder="Площадки"
              class="mb-3"
            ></b-form-input>
          </b-col>
          <b-col sm class="px-0 mx-2">
            <b-button
              @click="
                items = [];
                page = 0;
                loadData();
              "
              variant="primary"
            >
              Применить</b-button
            >
          </b-col>
        </b-row>

        <b-table
          bordered
          striped
          responsive="xl"
          hover
          small
          ref="table"
          :fields="fields"
          :items="items"
        >
          <template #cell()="data">
            <div style="white-space: nowrap">
              {{ data.value }}
            </div>
          </template>
          <template #head()="data">
            <div style="white-space: nowrap">{{ data.label }}</div>
          </template>
        </b-table>
      </b-skeleton-wrapper>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import { API_BQ_ROUTES, HELP } from "../constants";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: true,
      scrollLoading: false,

      page: 0,
      limit: 30,
      valueBundlesIds: [],
      valuePlacements: "",
      bundlesIds: [],
      data: [],

      items: [],
      fields: [
        {
          key: "bundle_id",
          label: "ID связки",
          sortable: true,
        },
        {
          key: "site_id",
          label: "ID площадки",
        },
        {
          key: "cost_loss",
          label: "% потерь",
          sortable: true,
        },
        {
          key: "update_date",
          label: "Время обновления",
        },
      ],
    };
  },

  async mounted() {
    this.loading = true;
    await this.loadData();
    this.loading = false;
    await this.getBundlesIds();
    this.$nextTick(function () {
      window.addEventListener("scroll", this.onScroll);
    });
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    async onScroll() {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - 5 && !this.scrollLoading) {
        await this.loadData();
      }
    },
    getBundlesIds() {
      this.loading = true;
      axios
        .get(`${HELP.BUNDLES_IDS}`)
        .then((res) => {
          this.bundlesIds = res.data.map((a) => {
            return { name: a, code: a };
          });
          this.loading = false;
        })
        .catch((er) => {
          this.loading = true;
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка получения id связок", e, "danger");
        });
    },
    async loadData() {
      const locale_options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timezone: "UTC",
        hour: "numeric",
        minute: "numeric",
      };
      try {
        this.page++;
        this.scrollLoading = true;

        this.valuePlacements = this.valuePlacements
          ?.split(",")
          .map((a) => a.trim())
          .join(",");

        const res = await axios.get(API_BQ_ROUTES.BUNDLE_LOSS, {
          params: {
            page: this.page,
            limit: 30,
            bundle_ids: this.valueBundlesIds?.map((a) => a.code).join(","),
            placements: this.valuePlacements,
          },
        });

        const data = res.data.data.map((a) => {
          a.update_date = new Date(a.update_date.value).toLocaleString(
            "ru",
            locale_options
          );
          a.cost_loss = Math.round(Number(a.cost_loss) * 10000) / 100;
          return a;
        });
        this.items = [...this.items, ...data];
      } catch (e) {
        console.log(e.message ?? "Something went wrong");
      } finally {
        this.scrollLoading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>