import utility_funtions from "../../utility/utility-funtions";

export default {
    ...utility_funtions,
    /**
     * Функция - обработчик для изменения размера экрана
     */
    handleResize() {
        this.new_height = `${window.innerHeight - 200}px`;
    },
    /**
     * Скрыть модалку редактирования связки
     */
    hideModalEditBundle() {
        this.hideModalAddBundle();
    },
    /**
     * Очистка полей модалки добавления связки
     */
    hideModalAddBundle() {
        this.valueSourceIdForBundle = "";
        this.valueCountryForBundle = "";
        this.valueSourcesIdForBundle = [];
        this.valueCountriesForBundle = [];
        this.valueMinRoiForBundle = null;
        this.valueMinCPMForBundle = null;
        this.chosenEditLander = null;
    },
    addBundleModal() {
        this.modalAddBundle = true;
    },
    addBundleManyModal() {
        this.modalAddManyBundle = true;
    },
}