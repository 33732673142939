import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";
import bundle_actions from "./bundles-list-actions";
import bundle_utility from "./bundles-list-utility.js";
import bundle_crud from "./bundles-list-crud";
import bundle_data from "./bundles-list-data.js";

export default {
    components: {
        Multiselect,
    },
    props: ["dataFilter", "dicts"],
    data() {
        return bundle_data;
    },

    watch: {
        textComment(v) {
            if (v === "" || !v) this.disableAddComment = false;
            else this.disableAddComment = false;
        },
        dataFilter: {
            handler() {
                // console.log(v);
                this.getBundles(true);
            },
            /**
             * это для того то бы ловить изменений внутри обьекта
             */
            deep: true,
        },
        pageOptions: {
            handler(newData, oldData) {
                if (bundle_utility.compareTwoObjects(newData, oldData)) return;
                this.currentPage = newData.page;
                this.perPage = newData.itemsPerPage;
                this.sortField = newData.sortBy[0];
                this.sortAsc = !newData.sortDesc[0] ? "asc" : "desc";
                // console.log(this.sortField, this.sortAsc);
                //TODO: need refactoring! too much requests
                this.getBundles(true);
            },
            deep: true,
        },
        dicts: {
            handler(v) {
                // console.log(v);
                this.dicts = v;
                this.optionsCountryForBundle = this.dicts.codes;
                this.optionsSourceIdForBundle = this.dicts.sources;
                this.allLanders = this.dicts.allLanders;
            },
            /**
             * это для того то бы ловить изменений внутри обьекта
             */
            deep: true,
        },
        chosenEditLander(v) {
            if (!v || !this.valueCountryForBundle || !this.valueSourceIdForBundle)
                this.disableSaveBundle = true;
            else this.disableSaveBundle = false;
        },
        valueSourceIdForBundle(v) {
            // console.log(v);
            if (!v || !this.valueCountryForBundle || !this.chosenEditLander)
                this.disableSaveBundle = true;
            else this.disableSaveBundle = false;
        },
        valueCountryForBundle(v) {
            if (!v || !this.valueSourceIdForBundle || !this.chosenEditLander)
                this.disableSaveBundle = true;
            else this.disableSaveBundle = false;
        },
        selectedBundles(v) {
            if (v.length) this.disabledButtonAllAction = false;
            else this.disabledButtonAllAction = true;
        },
    },
    methods: {
        ...bundle_utility,
        ...bundle_actions,
        ...bundle_crud,
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.dataFilter.apply = !this.dataFilter.apply;
    },
    mounted() {
        this.handleResize();
    },
};