<template>
  <div>
    <b-container fluid>
      <h2>Статистика по периодам</h2>
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-form inline>
            <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
            <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
          </b-form>
          <b-form class="mt-3" inline>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
          </b-form>
        </template>

        <b-row class="mt-3">
          <b-col sm class="px-0 mx-2">
            <multiselect
              v-model="valueOrtner"
              tag-placeholder="Партнер"
              placeholder="Партнер"
              label="name"
              track-by="code"
              :options="partners"
              :taggable="true"
            ></multiselect>
          </b-col>

          <b-col sm class="px-0 mx-2">
            <multiselect
              v-model="valueCountry"
              tag-placeholder="Страны"
              placeholder="Страны"
              label="name"
              track-by="code"
              :options="countries"
              :multiple="false"
              :taggable="true"
            ></multiselect>
          </b-col>

          <b-col sm class="px-0 mx-2">
            <multiselect
              v-model="valueSources"
              tag-placeholder="ID источника"
              placeholder="ID источника"
              label="name"
              track-by="code"
              :options="sources"
              :multiple="false"
              :taggable="false"
            ></multiselect>
          </b-col>
          <b-col sm class="px-0 mx-2 pb-2">
            <date-picker
              class="h-100"
              value-type="YYYY-MM-DD"
              placeholder="Даты отчета"
              v-model="reportDateBetWeen"
              :disabled-date="(date) => date >= max_date || date <= min_date"
              :shortcuts="[
                {
                  text: 'Подгрузить даты связки',
                  onClick: () => {
                    getDates();
                  },
                },
                {
                  text: 'За все время',
                  onClick: () => {
                    return [this.min_date, this.max_date];
                  },
                },
              ]"
              style="width: 100%"
              range
            >
            </date-picker>
          </b-col>
          <b-col sm class="px-0 mx-2">
            <b-button @click="getStats" variant="primary">
              Открыть статистику</b-button
            >
          </b-col>
          <b-col sm class="px-0 mx-2">
            <b-button @click="getBunch" variant="primary">
              Открыть связку</b-button
            >
          </b-col>
        </b-row>
      </b-skeleton-wrapper>
    </b-container>
  </div>
</template>
<script>
import { API_PARTNERS, HELP, API_AD } from "../constants";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue-multiselect/dist/vue-multiselect.min.css";
import utility_funtions from "../utility/utility-funtions";
import dictionaries from "../utility/dictionaries";
import axios from "axios";
export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      loading: false,
      partners: [],
      valueOrtner: "",
      countries: [],
      valueCountry: "",
      sources: [],
      valueSources: "",
      reportDateBetWeen: [],
      min_date: new Date(),
      max_date: new Date(),
    };
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    ...utility_funtions,
    ...dictionaries,
    async getDates() {
      if (
        this.valueOrtner !== "" &&
        this.valueSources !== "" &&
        this.valueCountry !== ""
      ) {
        const headers = { "Content-type": "application/json" };
        await axios
          .get(
            `${API_AD.GET_BUNCH_DATE_RANGE}/code=${this.valueCountry.code}&source_id=${this.valueSources.code}&partner=${this.valueOrtner.code}`,
            {
              headers: headers,
            }
          )
          .then((res) => {
            console.log(
              res.data.data.first.split("T")[0] +
                " - " +
                res.data.data.last.split("T")[0]
            );
            this.min_date = new Date(res.data.data.first);
            this.max_date = new Date(res.data.data.last);
          })
          .catch((er) => {
            console.log(er);
            const e = er.response.data.message
              ? er.response.data.message
              : er.message
              ? er.message
              : JSON.stringify(er);
            this.loading = false;
            this.makeToast("Ошибка при получении данных из ОА", e, "danger");
          });
      } else {
        this.makeToast(
          "Ошибка при получении дат начала и конца связки",
          "Не выбран партнер, сурс или страна",
          "danger"
        );
      }
    },
    getStats() {
      if (
        this.valueOrtner !== "" &&
        this.valueSources !== "" &&
        this.valueCountry !== ""
      ) {
        const from_date =
          this.reportDateBetWeen.length > 0 ? this.reportDateBetWeen[0] : null;
        const to_date =
          this.reportDateBetWeen.length > 1 ? this.reportDateBetWeen[1] : null;
        window.open(
          `./periodTable/partner=${this.valueOrtner.code}&source=${this.valueSources.code}&country=${this.valueCountry.code}&from_date=${from_date}&to_date=${to_date}`,
          "_blank"
        );
      }
    },
    getBunch() {
      if (
        this.valueOrtner !== "" &&
        this.valueSources !== "" &&
        this.valueCountry !== ""
      ) {
        const from_date =
          this.reportDateBetWeen.length > 0 ? this.reportDateBetWeen[0] : null;
        const to_date =
          this.reportDateBetWeen.length > 1 ? this.reportDateBetWeen[1] : null;
        window.open(
          `./bunch/partner=${this.valueOrtner.code}&source=${this.valueSources.code}&country=${this.valueCountry.code}&from_date=${from_date}&to_date=${to_date}`,
          "_blank"
        );
      }
    },
    //Получаем  всех источников
    getSources() {
      axios
        .get(`${HELP.SOURCES}`)
        .then((res) => {
          this.sources = res.data.map((a) => {
            return { name: `${a.source_id} - ${a.name}`, code: a.source_id };
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.makeToast("Ошибка получения источников", e, "danger");
        });
    },
    //Получаем  всех страны
    getCountries() {
      axios
        .get(`${HELP.COUNTRIES}`)
        .then((res) => {
          this.countries = res.data.map((a) => {
            return { name: a.country, code: a.country };
          });
          this.getSources();
        })
        .catch((err) => {
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.makeToast("Ошибка получения стран", e, "danger");
        });
    },
    getPartners() {
      this.loading = true;
      axios
        .get(API_PARTNERS.GET)
        .then((partners) => {
          this.partners = [
            ...new Set(partners.data.map((a) => a.offer.slice(-2))),
          ].map((b) => {
            return { name: b, code: b };
          });
          this.getCountries();
          this.loading = false;
        })
        .catch((err) => {
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.makeToast("Ошибка получения партнеров", e, "danger");
        });
    },
  },
};
</script>
<style scoped>
::v-deep input.mx-input {
  height: 100% !important;
}
::v-deep .mx-input-wrapper {
  height: 100% !important;
}
::v-deep .mx-datepicker {
  height: 100% !important;
}
</style>