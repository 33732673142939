<template>
  <div>
    <b-container fluid>
      <h2>Источники трафика</h2>
      <hr />
      <b-skeleton-wrapper class="mt-4" :loading="loadSources">
        <template #loading>
          <b-form inline>
            <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
            <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
            <b-skeleton class="col-sm-2" type="text"></b-skeleton>
            <br />
          </b-form>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </template>
        <div class="mt-4" v-if="sources.length === 0">
          <h1 class="text-center">Ни чего не найдено!</h1>
        </div>
        <b-button
          @click="addSourceModal"
          variant="outline-success"
          class="mr-2 mb-3"
          >Добавить
        </b-button>
        <b-button @click="getSources" class="mb-3" variant="outline-primary"
          >Обновить
        </b-button>

        <div>
          <b-button
            size="sm"
            @click="seletedAll"
            class="mr-1 mb-1"
            variant="primary"
          >
            {{ selectedTitle }}
          </b-button>

          <b-button
            v-for="actionALl in actionsAllBtn"
            :key="actionALl.name"
            :disabled="disabledButtonAllAction"
            size="sm"
            class="mr-1 mb-1"
            @click="actionAllBundle(actionALl.name)"
            :variant="actionALl.vIcon"
          >
            <b-icon
              font-scale="0.97"
              :icon="actionALl.icon"
              aria-hidden="true"
            ></b-icon
          ></b-button>
          <b-col col md="4" sm="2">
            <b-form-group>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Поиск"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    >Очистить</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </div>

        <b-table
          selectable
          bordered
          ref="selectableTable"
          style="font-size: 14px"
          :sticky-header="heightTable"
          @row-selected="onRowSelected"
          :fields="fields"
          striped
          responsive="xl"
          :filter="filter"
          hover
          small
          :items="sources"
        >
          <template #cell(confidence_lvl)="data">
            {{ data.value * 100 }}%
          </template>

          <template #cell(min_roi)="data"> {{ data.value * 100 }}% </template>

          <template #cell()="data">
            <div style="white-space: nowrap">
              {{ data.value }}
            </div>
          </template>
          <template #head()="data">
            <div style="white-space: nowrap">{{ data.label }}</div>
          </template>
          <template #cell(actions)="data">
            <div style="width: 80px">
              <b-button
                v-for="action in actionsBtn"
                :key="action.name"
                size="sm"
                class="mr-1"
                @click="actionSource(action.name, data)"
                :variant="action.vIcon"
              >
                <b-icon
                  font-scale="0.97"
                  :icon="action.icon"
                  aria-hidden="true"
                ></b-icon
              ></b-button>
            </div>
          </template>
        </b-table>
      </b-skeleton-wrapper>

      <!-- Модельаное окно множественного редактирования источника -->
      <b-modal
        @hide="hideModalMultiEditSource"
        v-model="modalApplyMultiEditSources"
        :title="`Редактирование источников ${selectedSources
          .map((a) => a.source_id)
          .join('\n')}`"
        header-bg-variant="primary"
        header-text-variant="white"
      >
        <b-overlay
          spinner-variant="primary"
          :show="loadMultiEditSource"
          rounded="sm"
        >
          <b-form-input
            type="number"
            v-model="valueMinRoiForSources"
            placeholder="%, Min ROI"
            class="mb-3"
          ></b-form-input>
        </b-overlay>
        <!-- </div> -->
        <template #modal-footer>
          <div class="w-100">
            <b-button
              :disabled="disableSourceMultiEdit"
              variant="primary"
              class="float-right"
              @click="saveSources"
            >
              Сохранить
            </b-button>
            <b-button
              variant="secondary"
              class="float-right mr-2"
              @click="modalApplyMultiEditSources = false"
            >
              Отменить
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- Модельаное окно редактирования источника -->
      <b-modal
        @hide="hideModalEditSource"
        v-model="modalEditSource"
        :title="`Редактирование источника ${
          currentSourceId ? ' - ' + currentSourceId : ''
        }`"
        header-bg-variant="primary"
        header-text-variant="white"
      >
        <b-overlay
          spinner-variant="primary"
          :show="loadSaveSource"
          rounded="sm"
        >
          <b-form-input
            type="text"
            class="mb-3"
            v-model="valueNameForSource"
            placeholder="Имя источника"
          ></b-form-input>

          <b-form-input
            type="text"
            class="mb-3"
            v-model="valueNetworkNameForSource"
            placeholder="Имя рекл. сети"
          ></b-form-input>

          <b-form-input
            type="number"
            class="mb-3"
            v-model="valueSourceIdForSource"
            placeholder="ID источника"
          ></b-form-input>

          <b-form-input
            type="text"
            class="mb-3"
            v-model="valueBrowserForSource"
            placeholder="Браузер"
          ></b-form-input>
          <b-form-input
            type="text"
            class="mb-3"
            v-model="valueBrowserVersionForSource"
            placeholder="Версия браузера"
          ></b-form-input>
          <b-form-input
            type="number"
            class="mb-3"
            v-model="valueTrafficQualityForSource"
            placeholder="Качество трафика(1 is top)"
          ></b-form-input>
          <multiselect
            v-model="valuePaymentTypeForSource"
            :options="optionsPaymentTypeForSource"
            :multiple="false"
            :close-on-select="true"
            :preserve-search="true"
            placeholder="Тип оплаты"
            label="name"
            track-by="code"
            class="mb-3"
          >
          </multiselect>
          <multiselect
            v-model="selectedLander"
            :options="allLanders"
            :multiple="false"
            :close-on-select="true"
            :preserve-search="true"
            placeholder="Ленд"
            label="name"
            track-by="code"
            class="mb-3"
          >
          </multiselect>

          <multiselect
            v-model="valueConfidenceLvl"
            :options="confidenceLvls"
            :multiple="false"
            :close-on-select="true"
            :preserve-search="true"
            placeholder="Уровень доверия"
            label="label"
            track-by="value"
            class="mb-3"
          >
          </multiselect>

          <b-form-input
            type="number"
            class="mb-3"
            v-model="valueMinRoiForSource"
            placeholder="%, Min ROI"
          ></b-form-input>

          <b-form-input
            type="number"
            class="mb-3"
            v-model="valueMaxCPMForSource"
            placeholder="Макс. CPM"
          ></b-form-input>

          <b-form-input
            type="number"
            class="mb-3"
            v-model="valueMinStepForSource"
            placeholder="Мин. Шаг"
          ></b-form-input>

          <b-form-input
            type="number"
            v-model="valueDifficultyForSource"
            placeholder="Сложность"
            max="100"
            min="1"
          ></b-form-input>
        </b-overlay>

        <template #modal-footer>
          <div class="w-100">
            <b-button
              :disabled="disableSaveSource"
              variant="primary"
              class="float-right"
              @click="saveSource"
            >
              Сохранить
            </b-button>
            <b-button
              variant="secondary"
              class="float-right mr-2"
              @click="modalEditSource = false"
            >
              Отменить
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- Модельаное окно подтверждение удаления исчтоника -->
      <b-modal
        v-model="modalApplyRemoveSource"
        :title="`Удаление источника - ${currentSourceId}`"
        header-bg-variant="warning"
      >
        <b-overlay
          spinner-variant="primary"
          :show="loadRemoveSource"
          rounded="sm"
        >
          <p>Подтверждаете удаление источника?</p>
        </b-overlay>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="removeSource"
            >
              Подтверждаю
            </b-button>
            <b-button
              variant="secondary"
              class="float-right mr-2"
              @click="modalApplyRemoveSource = false"
            >
              Нет
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- Модальаное окно добавления  источника -->
      <b-modal
        @hide="hideModalAddSource"
        v-model="modalAddSource"
        :title="`Добавление источника`"
        header-bg-variant="primary"
        header-text-variant="white"
      >
        <b-overlay
          spinner-variant="primary"
          :show="loadSaveSource"
          rounded="sm"
        >
          <b-form-input
            type="text"
            class="mb-3"
            v-model="valueNameForSource"
            placeholder="Имя источника"
          ></b-form-input>

          <b-form-input
            type="text"
            class="mb-3"
            v-model="valueNetworkNameForSource"
            placeholder="Имя рекл. сети"
          ></b-form-input>

          <b-form-input
            type="number"
            class="mb-3"
            v-model="valueSourceIdForSource"
            placeholder="ID источника"
          ></b-form-input>

          <b-form-input
            type="text"
            class="mb-3"
            v-model="valueBrowserForSource"
            placeholder="Браузер"
          ></b-form-input>
          <b-form-input
            type="text"
            class="mb-3"
            v-model="valueBrowserVersionForSource"
            placeholder="Версия браузера"
          ></b-form-input>
          <b-form-input
            type="text"
            class="mb-3"
            v-model="valueBrowserVersionType"
            placeholder="Тип версии браузера"
          ></b-form-input>
          <multiselect
            v-model="valueTrafficTypeForSource"
            :options="optionsTrafficTypeForSource"
            :multiple="false"
            :close-on-select="true"
            :preserve-search="true"
            placeholder="Тип Трафика"
            label="name"
            track-by="code"
            class="mb-3"
          >
          </multiselect>
          <multiselect
            v-model="valuePaymentTypeForSource"
            :options="optionsPaymentTypeForSource"
            :multiple="false"
            :close-on-select="true"
            :preserve-search="true"
            placeholder="Тип оплаты"
            label="name"
            track-by="code"
            class="mb-3"
          >
          </multiselect>
          <b-form-input
            type="number"
            class="mb-3"
            v-model="valueTrafficQualityForSource"
            placeholder="Качество трафика(1 is top)"
          ></b-form-input>

          <multiselect
            v-model="selectedLander"
            :options="allLanders"
            :multiple="false"
            :close-on-select="true"
            :preserve-search="true"
            placeholder="Ленд"
            label="name"
            track-by="code"
            class="mb-3"
          >
          </multiselect>

          <multiselect
            v-model="valueConfidenceLvl"
            :options="confidenceLvls"
            :multiple="false"
            :close-on-select="true"
            :preserve-search="true"
            placeholder="Уровень доверия"
            label="label"
            track-by="value"
            class="mb-3"
          >
          </multiselect>

          <b-form-input
            type="number"
            class="mb-3"
            v-model="valueMinRoiForSource"
            placeholder="%, Min ROI"
          ></b-form-input>

          <b-form-input
            type="number"
            class="mb-3"
            v-model="valueMaxCPMForSource"
            placeholder="макс. CPM"
          ></b-form-input>

          <b-form-input
            type="number"
            class="mb-3"
            v-model="valueMinStepForSource"
            placeholder="Мин. Шаг"
          ></b-form-input>

          <b-form-input
            type="number"
            v-model="valueDifficultyForSource"
            placeholder="Сложность"
            max="100"
            min="1"
          ></b-form-input>
        </b-overlay>

        <template #modal-footer>
          <div class="w-100">
            <b-button
              :disabled="disableSaveSource"
              variant="primary"
              class="float-right"
              @click="addSource"
            >
              Добавить
            </b-button>
            <b-button
              variant="secondary"
              class="float-right mr-2"
              @click="modalAddSource = false"
            >
              Отменить
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { HELP } from "../constants";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import dictionaries from "../utility/dictionaries";
import utils from "../utility/utility-funtions";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      actionsAllBtn: [
        {
          name: "editAll",
          icon: "pencil-fill",
          variant: "light",
          vIcon: "secondary",
        },
      ],
      selectedTitle: "Выбрать все",
      totalRows: 0,
      modalApplyMultiEditSources: false,
      selectedSources: [],
      filter: "",
      loadMultiEditSource: false,
      valueMinRoiForSources: "",
      disabledButtonAllAction: true,
      disableSourceMultiEdit: false,

      loadRemoveSource: false,
      modalApplyRemoveSource: false,
      modalAddSource: false,
      disableSaveSource: false,
      modalEditSource: false,
      valueSourceIdForSource: "",
      optionsSourceIdForSource: [],
      optionsTrafficTypeForSource: [
        { code: "Adult", name: "Adult" },
        { code: "Mainstream", name: "Mainstream" },
      ],
      optionsPaymentTypeForSource: [
        { code: "CPC", name: "CPC" },
        { code: "CPM", name: "CPM" },
      ],
      optionsCampaignForSource: [],
      selectedLander: null,
      allLanders: [],
      confidenceLvls: [],

      valueBrowserForSource: "Chrome",
      valuePaymentTypeForSource: null,
      valueBrowserVersionForSource: "",
      valueBrowserVersionType: "",
      valueTrafficTypeForSource: "",
      valueTrafficQualityForSource: 1,

      valueMinRoiForSource: "",
      valueMaxCPMForSource: "",
      valueMinStepForSource: "",
      valueDifficultyForSource: "",
      valueNameForSource: "",
      valueNetworkNameForSource: "",
      valueConfidenceLvl: "",

      currentSourceId: "",

      loadSaveSource: false,
      loadAddSource: false,
      loadSources: true,
      sources: [],
      heightTable: "300px",
      fields: [
        { key: "actions", label: "Действия", stickyColumn: true },
        { key: "source_id", label: "ID", sortable: true },
        { key: "name", label: "Имя", sortable: true },
        { key: "network_name", label: "Имя Сети", sortable: true },
        { key: "browser", label: "Браузер", sortable: true },
        { key: "browser_version", label: "Версия браузера", sortable: true },
        {
          key: "browser_version_type",
          label: "Тип версии браузера",
          sortable: true,
        },
        { key: "version", label: "Версия", sortable: true },
        { key: "traffic_type", label: "Тип траффика", sortable: true },
        { key: "payment_type", label: "Тип оплаты", sortable: true },
        { key: "traffic_quality", label: "Качество", sortable: true },
        { key: "min_roi", label: "Мин. ROI", sortable: true },
        { key: "max_cpm", label: "Макс. CPM", sortable: true },
        { key: "min_step", label: "Мин. шаг", sortable: true },
        { key: "difficulty", label: "Сложность", sortable: true },
        { key: "confidence_lvl", label: "Уровень доверия", sortable: true },
        { key: "z_value", label: "Z value", sortable: true },
        { key: "land", label: "Ленд", sortable: true },
      ],
      actionsBtn: [
        {
          name: "edit",
          icon: "pencil-fill",
          variant: "light",
          vIcon: "secondary",
        },
        {
          name: "remove",
          icon: "x-circle-fill",
          variant: "light",
          vIcon: "danger",
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.getConfidenceLvls();
    this.getSources();
    this.handleResize();
    this.getAllLanders();
  },
  watch: {
    valueSourceIdForSource(v) {
      if (
        v != "" &&
        this.valueNameForSource.length > 0 &&
        this.valueMinRoiForSource &&
        !isNaN(Number(this.valueMinRoiForSource)) &&
        this.selectedLander
      )
        this.disableSaveSource = false;
      else this.disableSaveSource = true;
    },
    selectedLander(v) {
      if (
        v != "" &&
        this.valueNameForSource.length > 0 &&
        this.valueMinRoiForSource &&
        !isNaN(Number(this.valueMinRoiForSource)) &&
        this.selectedLander
      )
        this.disableSaveSource = false;
      else this.disableSaveSource = true;
    },
    valueMinRoiForSource(v) {
      if (
        v != "" &&
        this.valueNameForSource.length > 0 &&
        this.valueMinRoiForSource &&
        !isNaN(Number(this.valueMinRoiForSource)) &&
        this.selectedLander
      )
        this.disableSaveSource = false;
      else this.disableSaveSource = true;
    },
    valueNameForSource(v) {
      if (
        v != "" &&
        this.valueNameForSource.length > 0 &&
        this.valueMinRoiForSource &&
        !isNaN(Number(this.valueMinRoiForSource)) &&
        this.selectedLander
      )
        this.disableSaveSource = false;
      else this.disableSaveSource = true;
    },
  },
  methods: {
    ...utils,
    ...dictionaries,
    getSources() {
      this.loadSources = true;
      axios
        .get(encodeURI(`${HELP.SOURCES}`))
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка получения источников трафика", e, "danger");
        })
        .then((res) => {
          const d = res.data;
          this.totalRows = d ? d.length : 0;
          if (d.length > 0) {
            this.sources = d.map((a) => {
              return {
                name: a.name,
                network_name: a.network_name,
                browser: a.browser,
                browser_version: a.browser_version,
                payment_type: a.payment_type,
                traffic_type: a.traffic_type,
                traffic_quality: a.traffic_quality,
                source_id: a.source_id,
                min_roi: a.min_roi,
                max_cpm: a.max_cpm,
                min_step: a.min_step,
                difficulty: a.difficulty,
                confidence_lvl: a.confidence_lvl,
                browser_version_type: a.browser_version_type,
                version: a.version,
                z_value: a.z_value,
                land: a.land,
              };
            });
            this.loadSources = false;
          } else {
            this.sources = [];
            this.fields = [];
            this.loadSources = false;
          }
        });
    },
    saveSources() {
      const headers = {
        "Content-Type": "application/json",
      };
      const body = {
        sources_ids: this.selectedSources.map((a) => a.source_id),
        min_roi: Number(this.valueMinRoiForSources) / 100,
      };
      console.log("saveSources", body);
      axios
        .post(`${HELP.SOURCES_MULTI_EDIT}`, JSON.stringify(body), {
          headers: headers,
        })
        .then(() => {
          this.loadMultiEditSource = false;
          this.modalApplyMultiEditSources = false;
          this.makeToast(
            "Истоники - " + this.selectedSources.join("\n"),
            "Истчоник успешно обновлен",
            "success"
          );
          this.hideModalMultiEditSource();
          this.getSources();
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка обновления исчтоников", e, "danger");
          this.loadMultiEditSource = false;
        });
    },
    addSource() {
      this.loadSaveSource = true;
      const headers = {
        "Content-Type": "application/json",
      };
      if (!this.selectedLander) {
        this.makeToast(
          "Выберите дефолтный ленд сурса",
          "Ошибка создания связки",
          "danger"
        );
      }
      if (!this.valueNameForSource) {
        this.makeToast(
          "Введите имя рекламной сети",
          "Ошибка создания связки",
          "danger"
        );
      }
      if (!this.valueSourceIdForSource) {
        this.makeToast("Введите ID сурса", "Ошибка создания связки", "danger");
      }
      const body = {
        name: this.valueNameForSource,
        network_name: this.valueNetworkNameForSource,

        browser: this.valueBrowserForSource,
        browser_version: this.valueBrowserVersionForSource,
        browser_version_type: this.valueBrowserVersionType,
        traffic_type: this.valueTrafficTypeForSource
          ? this.valueTrafficTypeForSource.code
          : "",
        traffic_quality: this.valueTrafficQualityForSource,
        payment_type: this.valuePaymentTypeForSource
          ? this.valuePaymentTypeForSource.code
          : null,

        source_id: Number(this.valueSourceIdForSource),
        min_roi: Number(this.valueMinRoiForSource) / 100,
        max_cpm: this.valueMaxCPMForSource
          ? Number(this.valueMaxCPMForSource)
          : null,
        min_step: this.valueMinStepForSource
          ? Number(this.valueMinStepForSource)
          : null,
        difficulty: Number(this.valueDifficultyForSource),
        offer: this.selectedLander.offer,
        land_id: this.selectedLander.land_id,
        tb_id: this.selectedLander.tb_id,
        confidence_lvl: this.valueConfidenceLvl
          ? this.valueConfidenceLvl.value
          : this.valueConfidenceLvl[0].value,
      };
      console.log(body);
      axios
        .post(`${HELP.SOURCES}`, JSON.stringify(body), {
          headers: headers,
        })
        .then(() => {
          this.loadSaveSource = false;
          this.modalAddSource = false;
          this.makeToast("Истоник", "Истчоник успешно добавлен", "success");
          this.hideModalEditSource();
          this.getSources();
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка обновления исчтоника", e, "danger");
          this.loadSaveSource = false;
        });
    },
    saveSource() {
      this.modalEditSource = true;
      const headers = {
        "Content-Type": "application/json",
      };
      if (!this.selectedLander) {
        this.makeToast(
          "Выберите дефолтный ленд сурса",
          "Ошибка редактирования связки",
          "danger"
        );
      }
      if (!this.valueNameForSource) {
        this.makeToast(
          "Введите имя сурса",
          "Ошибка редактирования связки",
          "danger"
        );
      }
      if (!this.valueSourceIdForSource) {
        this.makeToast(
          "Введите ID сурса",
          "Ошибка редактирования связки",
          "danger"
        );
      }
      console.log(this.valuePaymentTypeForSource);
      const body = {
        name: this.valueNameForSource,
        network_name: this.valueNetworkNameForSource,
        payment_type: this.valuePaymentTypeForSource
          ? this.valuePaymentTypeForSource.code
          : null,
        browser: this.valueBrowserForSource,
        browser_version: this.valueBrowserVersionForSource,
        traffic_type: this.valueTrafficTypeForSource
          ? this.valueTrafficTypeForSource.code
          : "",
        traffic_quality: this.valueTrafficQualityForSource,

        source_id: Number(this.valueSourceIdForSource),
        min_roi: Number(this.valueMinRoiForSource) / 100,
        max_cpm: this.valueMaxCPMForSource
          ? Number(this.valueMaxCPMForSource)
          : null,
        min_step: this.valueMinStepForSource
          ? Number(this.valueMinStepForSource)
          : null,
        difficulty: Number(this.valueDifficultyForSource),
        offer: this.selectedLander.offer,
        land_id: this.selectedLander.land_id,
        tb_id: this.selectedLander.tb_id,
        confidence_lvl: this.valueConfidenceLvl
          ? this.valueConfidenceLvl.value
          : this.valueConfidenceLvl[0].value,
      };
      console.log(body);
      axios
        .put(`${HELP.SOURCES}${body.source_id}`, JSON.stringify(body), {
          headers: headers,
        })
        .then(() => {
          this.loadSaveSource = false;
          this.modalEditSource = false;
          this.makeToast("Истоник", "Истчоник успешно добавлен", "success");
          this.hideModalEditSource();
          this.getSources();
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка обновления исчтоника", e, "danger");
          this.modalEditSource = false;
        });
    },
    removeSource() {
      this.loadRemoveSource = true;
      axios
        .delete(`${HELP.SOURCES}${this.currentSourceId}`)
        .then(() => {
          this.loadRemoveSource = false;
          this.modalApplyRemoveSource = false;
          this.makeToast(
            "Источник - " + this.currentSourceId,
            "Источник успешно удален",
            "success"
          );
          this.hideModalEditSource();
          this.getSources();
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка удаления исчтоника", e, "danger");
          this.loadRemoveSource = false;
        });
    },
    getConfidenceLvls() {
      axios
        .get(`${HELP.CONFIDENCE_LVLS}`)
        .then((res) => {
          this.confidenceLvls = [
            ...res.data.map((a) => {
              return {
                value: a,
                label: a * 100 + "%",
              };
            }),
          ];
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка удаления исчтоника", e, "danger");
        });
    },
    //#region utils
    actionSource(n, d) {
      const data = d.item;

      if (n == "edit") {
        this.currentSourceId = Number(data.source_id);
        this.valueSourceIdForSource = data.source_id;
        this.valueNameForSource = data.name;
        this.valueNetworkNameForSource = data.network_name;
        this.valueMinRoiForSource = data.min_roi
          ? Number(data.min_roi?.split("%")[0]) * 100
          : "";
        this.valueMaxCPMForSource = data.max_cpm;
        this.valueMinStepForSource = data.min_step;
        this.valueDifficultyForSource = data.difficulty;
        this.selectedLander = this.allLanders.find((l) => l.code == data.land);

        this.valueBrowserForSource = data.browser;
        this.valueBrowserVersionForSource = data.browser_version;
        console.log(data);
        this.valuePaymentTypeForSource = {
          code: data.payment_type,
          name: data.payment_type,
        };
        this.valueTrafficTypeForSource = this.optionsTrafficTypeForSource.find(
          (p) => p.code == data.traffic_type
        );
        this.valueTrafficQualityForSource = data.traffic_quality;
        this.valueConfidenceLvl = this.confidenceLvls.find(
          (l) => l.value == data.confidence_lvl
        );

        this.modalEditSource = true;
      } else if (n == "remove") {
        this.currentSourceId = Number(data.source_id);
        this.modalApplyRemoveSource = true;
      }
    },
    hideModalEditSource() {
      this.valueSourceIdForSource = "";
      this.valueMinRoiForSource = "";
      this.valueDifficultyForSource = "";
      this.valuePaymentTypeForSource = null;
      this.valueMinStepForSource = "";
      this.valueNameForSource = "";
      this.valueNetworkNameForSource = "";
      this.valueBrowserForSource = "Chrome";
      this.valueBrowserVersionForSource = "";
      this.valueBrowserVersionType = "";
      this.valueTrafficTypeForSource = "";
      this.valueTrafficQualityForSource = "";
      this.valueConfidenceLvl = "";
      this.selectedLander = null;
    },
    hideModalAddSource() {
      this.hideModalEditSource();
    },
    actionAllBundle(v) {
      if (v === "editAll") this.modalApplyMultiEditSources = true;
    },
    //Чистим поля - создания связки
    hideModalMultiEditSource() {
      this.valueMinRoiForSources = "";
    },
    seletedAll() {
      this.selMetod(true);
    },
    selMetod(a) {
      let colPage = this.totalRows;
      if (this.selectedSources.length != colPage) {
        if (a) this.$refs.selectableTable.selectAllRows();
        this.selectedTitle = "Выбрать все";
      } else {
        if (a) this.$refs.selectableTable.clearSelected();
        this.selectedTitle = "Отменить все";
      }
    },
    onRowSelected(items) {
      this.selectedSources = items;
      this.disabledButtonAllAction = items.length > 0 ? false : true;
      this.selMetod(false);
    },
    addSourceModal() {
      this.modalAddSource = true;
      this.disableSaveSource = true;
      // this.getLanders();
    },
    /**
     * Шаблон строки с процентом
     * @param {I} v
     */
    strPercent(v) {
      return `${v}%`;
    },
    /**
     * Шаблон строки с долларом
     * @param {I} v
     */
    strUSD(v) {
      const str = String(v);
      let finalVUSD;
      if (v.indexOf("-") != -1) {
        finalVUSD = `-$${str.split("-")[1]}`;
      } else {
        finalVUSD = `$${str}`;
      }
      return finalVUSD;
    },
    handleResize() {
      this.heightTable = `${window.innerHeight - 250}px`;
    },
    //#endregion
  },
};
</script>
