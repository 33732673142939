<template>
  <div>
    <!-- <b-overlay blur="5px" spinner-variant="primary" :show="loat" rounded="sm">
    </b-overlay> -->
    <b-overlay spinner-variant="primary" :show="loadAdminPanel" rounded="sm">
      <b-container fluid>
        <h2>Настройки</h2>
        <hr />
        <b-skeleton-wrapper class="mt-4" :loading="loadAdminPanel">
          <template #loading>
            <b-row cols-sm="2">
              <b-col sm>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
              </b-col>
              <b-col sm>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
              </b-col>
              <b-col sm>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
              </b-col>
              <b-col sm>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
              </b-col>
              <b-col sm>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
              </b-col>
              <b-col sm>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
              </b-col>
            </b-row>
          </template>
          <!-- Using components -->
          <b-row cols-sm="2">
            <b-col sm v-for="setting in settings" :key="setting.name">
              <b-form-group
                v-if="setting.name === 'pause_traffic'"
                :label="setting.title"
              >
                <!-- @change="applySetting(setting.name)" -->
                <b-form-checkbox
                  :state="setting.val"
                  @change="modalApplyChangeTRafficStatus = true"
                  v-model="setting.val"
                  name="check-button"
                  switch
                >
                  {{ setting.val ? "Трафик запущен" : "Трафик остановлен" }}
                  <b-spinner
                    variant="primary"
                    v-if="setting.load"
                    small
                  ></b-spinner>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                v-if="setting.name != 'pause_traffic'"
                :label="setting.title"
              >
                <b-input-group size="sm">
                  <b-form-input
                    :disabled="setting.disabled"
                    v-model="setting.val"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      @click="editSetting(setting.name)"
                      v-if="setting.disabled"
                      variant="secondary"
                    >
                      <b-icon :icon="setting.iconsEdit" aria-hidden="true">
                      </b-icon
                    ></b-button>
                    <b-button
                      @click="applySetting(setting.name)"
                      v-if="!setting.disabled"
                      variant="primary"
                      ><b-spinner v-if="setting.load" small></b-spinner>
                      <b-icon
                        v-if="!setting.load"
                        :icon="setting.iconsApply"
                        aria-hidden="true"
                      >
                      </b-icon
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <hr />

          <b-form inline>
            <b-row>
              <b-col sm="6">
                <br />
                <h3 style="text-align: center">Управление расчетом рейтов</h3>
                <br />
                <b-row align-h="between">
                  <b-col lg="7" md="12" sm="12" style="padding-right: 0">
                    <p>Веса категорий</p>
                    <div
                      style="padding-bottom: 25px !important"
                      sm="12"
                      v-for="weight in catWeights"
                      :key="weight.cat"
                    >
                      <b-form-group
                        style="display: inline-block !important; float: left"
                        v-for="(value, name) in weight"
                        :key="name"
                      >
                        <b-button
                          size="sm"
                          variant="dark"
                          style="
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            margin-bottom: 1px;
                          "
                          v-if="name === 'cat'"
                          disabled
                        >
                          <b>#{{ value }}</b>
                        </b-button>

                        <b-input-group
                          size="sm"
                          v-if="
                            name !== 'cat' &&
                            name !== 'disabled' &&
                            name !== 'load'
                          "
                        >
                          <b-form-input
                            required
                            style="
                              border-radius: 0;
                              text-align: center;
                              width: 100px;
                            "
                            :disabled="weight.disabled"
                            v-model="weight[name]"
                          >
                          </b-form-input>
                        </b-input-group>
                      </b-form-group>

                      <b-button
                        size="sm"
                        style="
                          border-top-left-radius: 0;
                          border-bottom-left-radius: 0;
                          margin-bottom: 1px;
                        "
                        @click="editWeightSetting(weight)"
                        v-if="weight.disabled"
                        variant="secondary"
                      >
                        <b-icon icon="pencil-fill" aria-hidden="true"> </b-icon
                      ></b-button>
                      <b-button
                        size="sm"
                        style="
                          border-top-left-radius: 0;
                          border-bottom-left-radius: 0;
                          margin-bottom: 1px;
                        "
                        @click="applyWeightSetting('cat', weight)"
                        v-if="!weight.disabled"
                        variant="primary"
                        ><b-spinner v-if="weight.load" small></b-spinner>
                        <b-icon
                          v-if="!weight.load"
                          icon="check-circle"
                          aria-hidden="true"
                        >
                        </b-icon
                      ></b-button>
                    </div>
                  </b-col>
                  <b-col lg="5" sm="12" style="padding-right: 0">
                    <p>Веса недель</p>
                    <div
                      style="padding-bottom: 25px !important"
                      sm="12"
                      v-for="weight in weekWeights"
                      :key="weight.week_num"
                    >
                      <b-form-group
                        style="float: left"
                        v-for="(value, name) in weight"
                        :key="name"
                      >
                        <b-button
                          size="sm"
                          variant="dark"
                          style="
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            margin-bottom: 1px;
                          "
                          v-if="name === 'week_num'"
                          disabled
                        >
                          <b>#{{ value }}</b>
                        </b-button>

                        <b-input-group
                          size="sm"
                          v-if="
                            name !== 'week_num' &&
                            name !== 'disabled' &&
                            name !== 'load'
                          "
                        >
                          <b-form-input
                            required
                            style="
                              border-radius: 0;
                              text-align: center;
                              width: 100px;
                            "
                            :disabled="weight.disabled"
                            v-model="weight[name]"
                          >
                          </b-form-input>
                        </b-input-group>
                      </b-form-group>

                      <b-button
                        size="sm"
                        style="
                          border-top-left-radius: 0;
                          border-bottom-left-radius: 0;
                          margin-bottom: 1px;
                        "
                        @click="editWeightSetting(weight)"
                        v-if="weight.disabled"
                        variant="secondary"
                      >
                        <b-icon icon="pencil-fill" aria-hidden="true"> </b-icon
                      ></b-button>
                      <b-button
                        size="sm"
                        style="
                          border-top-left-radius: 0;
                          border-bottom-left-radius: 0;
                          margin-bottom: 1px;
                        "
                        @click="applyWeightSetting('week_num', weight)"
                        v-if="!weight.disabled"
                        variant="primary"
                        ><b-spinner v-if="weight.load" small></b-spinner>
                        <b-icon
                          v-if="!weight.load"
                          icon="check-circle"
                          aria-hidden="true"
                        >
                        </b-icon
                      ></b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </b-skeleton-wrapper>

        <!-- Модельаное окно подтверждение остановки / запуска трафика -->
        <b-modal
          @hide="hideModalApplyChangeStatusTRaffic"
          v-model="modalApplyChangeTRafficStatus"
          :title="`${
            settings.length > 0
              ? settings.find((a) => a.name === 'pause_traffic').val
                ? 'Запустить'
                : 'Остановить'
              : ''
          } трафик`"
          header-bg-variant="warning"
        >
          <p>Подтверждаете смену статуса трафика?</p>

          <multiselect
            v-if="
              settings.length > 0
                ? settings.find((a) => a.name === 'pause_traffic').val
                  ? false
                  : true
                : false
            "
            class="mb-3"
            v-model="valueOffers"
            tag-placeholder="Все партнеры"
            placeholder="Все партнеры"
            label="name"
            track-by="code"
            :options="partners"
            :multiple="false"
            :taggable="true"
          ></multiselect>

          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="secondary"
                class="float-right"
                @click="
                  applySetting(
                    settings.find((a) => a.name === 'pause_traffic').name
                  )
                "
              >
                Подтверждаю
              </b-button>
              <b-button
                variant="primary"
                class="float-right mr-2"
                @click="modalApplyChangeTRafficStatus = false"
              >
                Нет
              </b-button>
            </div>
          </template>
        </b-modal>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import { API_SETTINGS } from "../constants";
import axios from "axios";
import "vue-multiselect/dist/vue-multiselect.min.css";
import dictionaries from "../utility/dictionaries";
import multiselect from "vue-multiselect";
export default {
  components: {
    multiselect,
  },
  data() {
    return {
      loadAdminPanel: true,
      modalApplyChangeTRafficStatus: false,
      settings: [],
      offers: [],
      partners: [],
      catWeights: [],
      weekWeights: [],
      valueOffers: "",
    };
  },
  async mounted() {
    await this.getOffers();
    this.getSettings();
    await this.getCategoryWeights();
    await this.getWeekWeights();
  },
  watch: {
    offers(offers) {
      this.partners = [...new Set(offers.map((a) => a.code.slice(-2)))].map(
        (o) => {
          return { code: o, name: o };
        }
      );
    },
  },
  methods: {
    ...dictionaries,
    hideModalApplyChangeStatusTRaffic() {
      this.modalApplyChangeTRafficStatus = false;
      this.settings.find((a) => a.name === "pause_traffic").val =
        !this.settings.find((a) => a.name === "pause_traffic").val;
    },
    editSetting(n) {
      this.settings.map((a) => (a.name === n ? (a.disabled = false) : true));
    },
    applySetting(n) {
      this.settings.map((a) => (a.name === n ? (a.load = true) : false));
      const headers = {
        "Content-Type": "application/json",
      };
      let val = this.settings.find((a) => a.name === n).val;
      val = typeof val === "boolean" ? Number(val) : val;
      const title = this.settings.find((a) => a.name === n).title;
      const body = {
        value: val,
        additional:
          n === "pause_traffic" ? this.valueOffers.code ?? null : null,
      };
      axios
        .put(`${API_SETTINGS.UPDATE}${n}`, JSON.stringify(body), {
          headers: headers,
        })
        .then(() => {
          this.makeToast(
            "Параметр - " + title,
            "Параметр успешно изменен",
            "success"
          );
          this.settings.map((a) => (a.name === n ? (a.load = false) : true));
          this.settings.map((a) =>
            a.name === n ? (a.disabled = true) : false
          );
          this.modalApplyChangeTRafficStatus = false;

          if (n === "pause_traffic")
            this.settings.find((a) => a.name === "pause_traffic").val =
              !this.settings.find((a) => a.name === "pause_traffic").val;
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка изменения параметра", e, "danger");
          this.settings.map((a) => (a.name === n ? (a.load = false) : true));
          this.settings.map((a) =>
            a.name === n ? (a.disabled = true) : false
          );
        });
    },
    //Тост
    makeToast(title, body, variant = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    getSettings() {
      this.loadAdminPanel = true;
      axios
        .get(encodeURI(`${API_SETTINGS.GET}`))
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка получения настроек", e, "danger");
        })
        .then((res) => {
          this.settings = res.data.settings.map((a) => {
            return {
              name: a.name,
              val: a.name === "pause_traffic" ? Boolean(a.value) : a.value,
              title: a.description,
              iconsEdit: "pencil-fill",
              disabled: true,
              iconsApply: "check-circle",
              load: false,
            };
          });
          this.loadAdminPanel = false;
        });
    },

    editWeightSetting(weight) {
      weight.disabled = false;
    },

    applyWeightSetting(keyName, weight) {
      weight.load = true;

      let data = Object.assign(
        {
          id: weight[keyName],
        },
        weight
      );
      delete data.disabled;
      delete data.load;
      delete data[keyName];

      const url =
        keyName === "cat"
          ? API_SETTINGS.UPDATE_CATEGORY_WEIGHT
          : API_SETTINGS.UPDATE_WEEK_WEIGHT;

      axios
        .post(`${url}`, data, {
          "Content-Type": "application/json",
        })
        .then(() => {
          weight.disabled = true;
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToast("Ошибка изменения параметра", message, "danger");
        })
        .finally(() => {
          weight.load = false;
        });
    },

    async getCategoryWeights() {
      axios
        .get(API_SETTINGS.GET_CATEGORY_WEIGHTS)
        .then((response) => {
          const data = response.data.data;
          data.map((d) => {
            d.disabled = true;
            d.load = false;
          });
          this.catWeights = data;
        })
        .catch((err) => {
          console.error(err);
          this.makeToast("Ошибка изменения параметра", err, "danger");
        });
    },

    async getWeekWeights() {
      await axios
        .get(API_SETTINGS.GET_WEEK_WEIGHTS)
        .then((response) => {
          const data = response.data.data;
          data.map((d) => {
            d.disabled = true;
            d.load = false;
          });
          this.weekWeights = data;
        })
        .catch((err) => {
          console.error(err);
          this.makeToast("Ошибка изменения параметра", err, "danger");
        });
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: 100;
}
</style>
