<template>
  <div>

    <b-card no-body style="min-height: calc(100vh - 70px) !important">

      <div
        class="d-flex justify-space-around"
        style="height: calc(100vh - 70px)"
      >
        <div>
          <h5 class="pt-3 pl-3 mb-2">Redis</h5>
          <v-treeview
            dense
            hoverable
            activatable
            transition
            open-on-click
            style="font-size: 14px; width: 280px;"
            :items="treeviewItems"
            :load-children="fetchNetworkNames"
            @update:active="onTreeviewChange"
          >
            <template v-slot="{ item }">
              <span>{{ item.name }}</span>
            </template>
          </v-treeview>
        </div>

        <v-divider vertical></v-divider>

        <div style="width: calc(100vw - 260px); padding-left: 1px;">
          <div
            v-show="active === 2"
          >
            <v-data-table
              dense
              hide-default-header
              :loading="ibTableLoading"
              :headers="ibTableHeaders"
              :items="ibTableItems"
            >
              <template v-slot:header>
                <thead>
                  <b-tr>
                    <b-th rowspan="2" style="width: 150px; text-align: center;">
                      Название сети
                    </b-th>
                    <b-th style="border-right: none !important;">
                      Данные
                    </b-th>
                    <b-th style="border-left: none !important;">
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th style="width: 200px">
                      Поле
                    </b-th>
                    <b-th>
                      Значение
                    </b-th>
                  </b-tr>
                </thead>
              </template>


              <template v-slot:body="{ items }">
                <p v-show="items.length === 0" class="pt-3 pl-5">Нет данных</p>
                <b-tbody>
                  <template
                    v-for="(item, index) in items"
                  >
                    <b-tr
                      v-bind:key="'name' + index"
                      :class="index&1 ? 'darker-column' : ''"
                    >
                      <td :rowspan="Object.keys(item.data).length" class="primary-column">{{ item.name }}</td>
                      <td>{{ translate(Object.keys(item.data)[0]) }}</td>
                      <td>{{ item.data[Object.keys(item.data)[0]] }}</td>
                    </b-tr> 
                    <template
                      v-for="(key, i) in Object.keys(item.data)"
                    >
                      <b-tr
                        v-bind:key="'data' + index + i"
                        v-show="key != Object.keys(item.data)[0]"
                        :class="index&1 ? 'darker-column' : ''"
                      >
                        <td>{{ translate(key) }}</td>
                        <td v-if="key !== 'data'">
                          {{ item.data[key] }}
                        </td>
                        <td v-else>
                          <div class="data-cell" v-on:click="dataCellClickHandler(item.data[key])">
                            {{ item.data[key] }}
                          </div>
                        </td>
                      </b-tr>
                    </template>
                  </template>
                </b-tbody>
              </template>
            </v-data-table>
          </div>

          <div
            v-show="active === 3"
          >
            <v-data-table
              dense
              hide-default-header
              :loading="pbTableLoading"
              :headers="pbTableHeaders"
              :items="pbTableItems"
            >
              <template v-slot:header>
                <thead>
                  <b-tr>
                    <b-th rowspan="2" style="width: 150px; text-align: center;">
                      ИД связки
                    </b-th>
                    <b-th style="border-right: none !important;">
                      Данные
                    </b-th>
                    <b-th style="border-left: none !important;">
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th style="width: 200px">
                      Поле
                    </b-th>
                    <b-th>
                      Значение
                    </b-th>
                  </b-tr>
                </thead>
              </template>


              <template v-slot:body="{ items }">
                <p v-show="items.length === 0" class="pt-3 pl-5">Нет данных</p>
                <b-tbody>
                  <template
                    v-for="(item, index) in items"
                  >
                    <b-tr
                      v-bind:key="'name' + index"
                      :class="index&1 ? 'darker-column' : ''"
                    >
                      <td class="text-center" :rowspan="Object.keys(item.data).length">{{ item.name }}</td>
                      <td>{{ translate(Object.keys(item.data)[0]) }}</td>
                      <td>{{ item.data[Object.keys(item.data)[0]] }}</td>
                    </b-tr> 
                    <template
                      v-for="(key, i) in Object.keys(item.data)"
                    >
                      <b-tr
                        v-bind:key="'data' + index + i"
                        v-show="key != Object.keys(item.data)[0]"
                        :class="index&1 ? 'darker-column' : ''"
                      >
                        <td>{{ translate(key) }}</td>
                        <td v-if="key !== 'data'">
                          {{ item.data[key] }}
                        </td>
                        <td v-else>
                          <div class="data-cell" v-on:click="dataCellClickHandler(item.data[key])">
                            {{ item.data[key] }}
                          </div>
                        </td>
                      </b-tr>
                    </template>
                  </template>
                </b-tbody>
              </template>
            </v-data-table>
          </div>

          <div v-show="active === 4">
            <v-data-table
              dense
              hide-default-header
              :items="sbTableItems"
              :headers="sbTableHeaders"
              :loading="sbTableLoading"
            >
              <template v-slot:header="{ props }">
                <thead>
                  <b-tr>
                    <b-th :class="head.value !== 'data' ? 'text-center' : ''" v-for="(head, index) in props.headers" v-bind:key="head.text + index">{{ head.text }}</b-th>
                  </b-tr>
                </thead>
              </template>


              <template v-slot:body="{ items }">
                <p v-show="items.length === 0" class="pt-3 pl-5">Нет данных</p>
                <b-tbody>
                  <template
                    v-for="(item, index) in items"
                  >
                    <b-tr
                      v-bind:key="'ud' + index"
                    >
                      <td
                        v-for="(v, k, i) in item"
                        v-bind:key="'ud' + index + i"
                      >
                        <template v-if="k !== 'data'">
                          <div class='text-center'>
                            {{ v }}
                          </div>
                        </template>
                        <template v-else>
                          <div class="data-cell" v-on:click="dataCellClickHandler(v)">
                            {{ v }}
                          </div>
                        </template>
                      </td>
                    </b-tr> 
                  </template>
                </b-tbody>
              </template>
            </v-data-table>
          </div>

          <div v-show="active === 6 || active === 7 || active === 8 || active === 10">
            <v-data-table
              dense
              hide-default-header
              :items="tableItems"
              :headers="tableHeaders"
              :loading="tableLoading"
            >
              <template v-slot:header="{ props }">
                <thead>
                  <b-tr>
                    <b-th :style="index < props.headers.length - 1 ? 'width: 220px;' : ''" v-for="(head, index) in props.headers" v-bind:key="head.text + index">{{ head.text }}</b-th>
                  </b-tr>
                </thead>
              </template>

              <template v-slot:body="{ items }">
                <p v-show="items.length === 0" class="pt-3 pl-5">Нет данных</p>
                <b-tbody>
                  <template
                    v-for="(item, index) in items"
                  >
                    <b-tr
                      v-bind:key="'conv_tb' + index"
                    >
                      <td
                        v-for="(v, k, i) in item"
                        v-bind:key="'conv_tb' + index + i"
                      >
                        <div :class="k === 'data' ? 'data-cell' : ''">
                          {{ v }}
                        </div>
                      </td>
                    </b-tr> 
                  </template>
                </b-tbody>
              </template>
            </v-data-table>
          </div>


          <div v-show="active === 9">
            <v-data-table
              dense
              show-expand
              hide-default-header
              :loading="udTableLoading"
              :headers="udTableHeaders"
              :items="udTableItems"
            >
              <template v-slot:header>
                <thead>
                  <b-tr>
                    <b-th rowspan="2" style="width: 150px; text-align: center;">
                      Тип
                    </b-th>
                    <b-th style="border-right: none !important;">
                      Данные
                    </b-th>
                    <b-th style="border-left: none !important;"></b-th>
                  </b-tr>
                  <b-tr>
                    <b-th style="width: 200px">
                      Поле
                    </b-th>
                    <b-th>
                      Значение
                    </b-th>
                  </b-tr>
                </thead>
              </template>


              <template v-slot:body="{ items }">
                <p v-show="items.length === 0" class="pt-3 pl-5">Нет данных</p>
                <b-tbody>
                  <template
                    v-for="(item, index) in items"
                  >
                    <b-tr
                      v-bind:key="'name' + index"
                      :class="index&1 ? 'darker-column' : ''"
                    >
                      <td class="text-center" :rowspan="Object.keys(item.data).length">{{ item.type }}</td>
                      <td>{{ translate(Object.keys(item.data)[0]) }}</td>
                      <td>{{ item.data[Object.keys(item.data)[0]] }}</td>
                    </b-tr> 
                    <template
                      v-for="(key, i) in Object.keys(item.data)"
                    >
                      <b-tr
                        v-bind:key="'data' + index + i"
                        v-show="key != Object.keys(item.data)[0]"
                        :class="index&1 ? 'darker-column' : ''"
                      >
                        <td>{{ translate(key) }}</td>
                        <td>
                          <div
                            :class="key === 'data' ? 'data-cell' : ''"
                          >
                            {{ item.data[key] }}
                          </div>
                        </td>
                      </b-tr>
                    </template>
                  </template>
                </b-tbody>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  More info about {{ item.name }}
                </td>
              </template>
            </v-data-table>
          </div>


        </div>
      </div>

    </b-card>
      <b-modal
        v-model="dataCellModal"
        :title="`Подробнее`"
        size="lg"
        header-bg-variant="primary"
        header-text-variant="white"
        @hide="dataCellModalHide"
        ok-only
      >
        <b-table
          small
          striped
          responsive="sm"
          v-model="dataCellItems"
          :items="dataCellItem"
        >
          <template #head()="data">
            {{ translate(data.column) }}
          </template>

          <template #cell(placements)="row">
            <template v-if="row.item.placements != ''">
              <b-button size="sm" :variant="row.detailsShowing ? 'danger' : ''" @click="toggleDetails(row)" class="mr-2">
                {{ row.detailsShowing ? 'Скрыть' : 'Показать'}} площадки
              </b-button>
            </template>
          </template>

          <template #row-details="row">
            <b-card>
              <b-input-group size="sm">
                  <b-form-input v-model="dataCellFilter" type="search" id="filterInput" placeholder="Введите ID площадки ">
                  </b-form-input>
                  <b-input-group-append>
                      <b-button :disabled="!dataCellFilter" @click="dataCellFilter = ''">Очистить</b-button>
                  </b-input-group-append>
              </b-input-group>
              <b-table
                :per-page="dataCellPerPage"
                :current-page="dataCellCurrentPage"
                :filter="dataCellFilter"
                :filter-included-fields="['id_placement']"
                primary-key="id_placement"
                :fields="[
                  { value: 'id_placement', label: 'ID площадки' }
                ]"
                :items="row.item.placements.split(',').map(a => {
                  return { id_placement: a }
                })"
              >
                <template #head()="data">
                  {{ translate(data.column) }}
                </template>
              </b-table>
              <b-pagination
                v-model="dataCellCurrentPage"
                :total-rows="row.item.placements.split(',').length || 0"
                :per-page="dataCellPerPage"
                aria-controls="my-table"
                pills
                align="center"
              ></b-pagination>
            </b-card>
          </template>
        </b-table>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { API_BUNDLES, API_REDIS_ROUTES } from '../constants';

export default {
  data() {
    return {
      active: 0,

      dataCellModal: false,
      dataCellFilter: '',
      dataCellItem: [],
      dataCellPerPage: 7,
      dataCellCurrentPage: 1,
      dataCellItems: [],

      prepBufferChildren: [],
      staticBufferChildren: [],

      /* table tb */
      tableLoading: false,
      tableItems: [],
      tableHeaders: [],

      /* user data table */
      udTableLoading: false,
      udTableItems: [],
      udTableHeaders: [],

      /* incomming buffer */
      ibTableLoading: false,
      ibTableHeaders: [
        { value: 'name', width: '150px', align: 'center' },
        { value: 'data' }
      ],
      ibTableItems: [],

      /* prep buffer */
      pbTableLoading: false,
      pbTableHeaders: [
        { value: 'name', width: '150px', align: 'center' },
        { value: 'data' }
      ],
      pbTableItems: [],

      /* static buffer */
      sbTableLoading: false,
      sbTableHeaders: [],
      sbTableItems: []
    };
  },

  computed: {
    treeviewItems() {
      return [
        {
          id: 6,
          name: 'QUEUE_IMPRESSION_TB'
        },
        {
          id: 7,
          name: 'QUEUE_CONVERSION_V1'
        },
        {
          id: 8,
          name: 'QUEUE_CONVERSION_V2'
        },
        {
          id: 9,
          name: 'QUEUE_USER_DATA'
        },
        {
          id: 10,
          name: 'QUEUE_CONVERSION_TB'
        },
        {
          id: 1,
          name: 'STAGES',
          children: [
            { id: 2, name: 'INCOMMING_BUFFER' },
            { id: 3, name: 'PREP_BUFFER', children: this.prepBufferChildren },
            { id: 4, name: 'STATIC_BUFFER', children: this.staticBufferChildren }
          ]
        }
      ]
    }
  },

  methods: {
    findById(arr, id) {
      return arr.reduce((a, item) => {
        if (a) return a;
        if (item.id === id) return item;
        if (item["children"]) return this.findById(item["children"], id)
      }, null)
    },


    /**
     * Обработчик изменения вкладки
     */
    onTreeviewChange(e) {
      this.active = e[0];
      if (e[0] === 2) {
        this.loadIncomingBufferData();
      } else if (e[0] === 6) {
        this.loadTable(API_REDIS_ROUTES.IMPRESSION_TB);
      } else if (e[0] === 7) {
        this.loadTable(API_REDIS_ROUTES.CONVERSION_V1);
      } else if (e[0] === 8) {
        this.loadTable(API_REDIS_ROUTES.CONVERSION_V2);
      } else if (e[0] === 9) {
        this.loadUserData();
      } else if (e[0] === 10) {
        this.loadTable(API_REDIS_ROUTES.CONVERSION_TB);
      } else if (e[0] !== undefined) {
        const child = this.findById(this.treeviewItems, e[0]);
        if (child === null) {
          this.makeToast('Ошибка!', 'Не удалось найти.', 'danger')
          return
        }
        this.active = child.parent;
        if (this.active === 3) {
          this.loadPrepBufferData(child.name);
        } else if (this.active === 4) {
          this.loadStaticBufferData(child.name);
        }
      }
    },


    /**
     * Возвращает названия сетей для меню
     */
    async fetchNetworkNames(item) {
      let url;
      if (item.id === 3) {
        url = API_REDIS_ROUTES.PREP_BUFFER_NETWORKS;
      } else if (item.id === 4) {
        url = API_REDIS_ROUTES.STATIC_BUFFER_NETWORKS;
      } else {
        return;
      }

      return await axios.get(url)
        .then(res => res.data.networks)
        .then(networks => {
          for (let i = 0; i < networks.length; i++) {
            item.children.push({
              id: i + (item.id === 3 ? 20 : 100),
              parent: item.id,
              name: networks[i]
            })
          }
        })
        .catch((e) => {
          console.warn(e);
          this.makeToast('Не удалось получить список сетей.', e.message, 'danger')
        })
    },


    /**
     * Данные из impression_tb, conversion_tb, conversion_v1, conversion_v2
     */
    async loadTable(url) {
      try {
        this.tableLoading = true;
        this.tableItems = [];
        const response = await axios.get(url)
        const data = response.data.data;
        this.tableItems = [...data];
        this.tableHeaders = this.generateHeaders(this.tableItems);

      } catch (e) {
        console.warn(e);
        this.makeToast('Ошибка!', e.message, 'danger')
      } finally {
        this.tableLoading = false;
      }
    },


    /**
     * Данные из user_data
     */
    async loadUserData() {
      try {
        this.udTableLoading = true;
        this.udTableItems = [];
        const response = await axios.get(API_REDIS_ROUTES.USER_DATA);
        const data = response.data.data;
        this.udTableItems = [...data]
        for (let i = 0; i < this.udTableItems.length; i++) {
          this.udTableItems[i].data.try = this.udTableItems[i].try;
          delete this.udTableItems[i].try;
        }
        this.udTableHeaders = this.generateHeaders(this.udTableItems);
      } catch (e) {
        console.warn(e);
        this.makeToast('Ошибка!', e.message, 'danger')
      } finally {
        this.udTableLoading = false;
      }
    },


    /**
     * Данные во входящем буфере
     */
    async loadIncomingBufferData() {
      try {
        this.ibTableLoading = true;
        this.ibTableItems = [];
        const response = await axios.get(API_REDIS_ROUTES.INCOMMING_BUFFER_ALL);
        const data = response.data.data;
        const tableData = [];
        for (let d in data) {
          tableData.push({
            name: d,
            data: {
              ...this.filterFields(JSON.parse(data[d]))
            }
          })
        }
        await this.getBundleNames(tableData);
        this.ibTableItems = [...tableData];
      } catch (e) {
        console.warn(e);
        this.makeToast('Ошибка!', e.message, 'danger')
      } finally {
        this.ibTableLoading = false;
      }
    },


    /**
     * Данные в подготовительном буфере
     */
    async loadPrepBufferData(networkName) {
      try {
        this.pbTableLoading = true;
        this.pbTableItems = [];
        const response = await axios.get(`${API_REDIS_ROUTES.PREP_BUFFER_NETWORKS}/${networkName}`);
        const data = response.data.data;
        const tableData = [];
        for (let d in data) {
          let netData = data[d].data;
          for (let i = 0; i < netData.length; i++) {
            tableData.push({
              name: netData[i].bundle_id,
              data: { ...this.filterFields(netData[i]) }
            })
          }
        }
        await this.getBundleNames(tableData);
        this.pbTableItems = [...tableData];
      } catch (e) {
        console.warn(e);
        this.makeToast('Ошибка!', e.message, 'danger')
      } finally {
        this.pbTableLoading = false;
      }
    },


    /**
     * Данные в статическом буфере
     */
    async loadStaticBufferData(networkName) {
      try {
        this.sbTableLoading = true;
        this.sbTableItems = [];
        const response = await axios.get(`${API_REDIS_ROUTES.STATIC_BUFFER_NETWORKS}/${networkName}`);
        const data = response.data.data;
        const tableData = [];
        for (let d in data) {
          for (let i in data[d].data) {
            tableData.push({
              ...await this.filterFields(data[d].data[i])
            })
          }
        }
        await this.getBundleNames(tableData, true);
        this.sbTableItems = [...tableData];
        this.sbTableHeaders = this.generateHeaders(this.sbTableItems);
      } catch (e) {
        console.warn(e);
        this.makeToast('Ошибка!', e.message, 'danger')
      } finally {
        this.sbTableLoading = false;
      }
    },


    /**
     * генерирует заголовки для таблицы из полей
     */
    generateHeaders(obj) {
      let tableHeaders = [];
      obj.forEach((i) => {
        tableHeaders.push(...Object.keys(i));
      })
      tableHeaders = [...new Set(tableHeaders)];
      return tableHeaders.map((e) => ({ text: this.translate(e), value: e, width: e !== 'data' ? '160px' : '' }))
    },


    /**
     * возвращает перевод полей
     */
    translate(key) {
      const t = {
        action: 'Action',
        network: 'Сеть',
        bundle_id: 'ИД связки',
        bundle_name: 'Имя связки',
        data: 'Данные',
        max_cpm: 'Макс. CPM',
        max_cpm_black: 'Макс. CPM Black',
        new_bundle_max_cpm: 'Новый макс. CPM звязки',
        new_version: 'Новая версия',
        type: 'Тип',
        priority: 'Приоритет',
        browser_version: 'Версия браузера',
        browser_version_type: 'Тип версии браузера',
        old_action: 'Old action',
        impression_id: 'Impression ID',
        payout: 'Выплата',
        added_at: 'Дата добавления',
        placements: 'Площадки',
        id_placement: 'ID площадки',
        ts_id: 'TS ИД',
        id: 'ИД',
        status: 'Статус',
        stage_type: 'Stage type',
        source_id: 'ИД источника',
        code: 'Код'
      }
      return t[key] || key;
    },

    dataCellClickHandler(item) {
      if (!Array.isArray(item)) {
        item = [item];
      }
      this.dataCellItem = item;
      this.dataCellModal = true;
    },

    dataCellModalHide() {
      this.dataCellItem = [];
      this.dataCellModal = false;
      this.dataCellCurrentPage = 1;
    },

    toggleDetails(row) {
      if (row.item._showDetails) {
        this.$set(row.item, '_showDetails', false);
      } else {
        this.dataCellItems.forEach(item => {
          this.$set(item, '_showDetails', false);
        })
        this.$set(row.item, '_showDetails', true);
      }
    },

    /**
     * Удаляет ненужные поля с объекта
     */
    filterFields(obj) {
      delete obj['_added_at'];
      delete obj['network'];
      Object.keys(obj).forEach((k) => obj[k] == null && delete obj[k]);
      return obj;
    },

    /**
     * Загружает названия связок
     * @param {Array<any>} array
     */
    async getBundleNames(array, deep = true) {
      /*
       * Получает массив объектов вида Type<array>:
       * [
       *  {
       *    network: `string`,
       *    data: {
       *      bundle_id: `number|string`,
       *      ...
       *    }
       *  },
       *  ...
       * ]
       * 
       * Для каждого объекта добавляет в поле data новое поле - bundle_name.
       * Для получения bundle_name необходимо вызвать роут, который принимает список из bundle_id.
       */
      const bundle_ids = [];
      /* здесь i - это индекс объекта из которого был получен bundle_id */
      array.forEach((v, i) => bundle_ids.push({ bundle_id: deep ? v.data.bundle_id : v.bundle_id, i }))
      try {
        const resp = await axios.get(API_BUNDLES.BUNDLES_ID_NAMES + '?bundles=' + bundle_ids.map(e => e.bundle_id).join(','));
        bundle_ids.forEach((bundle) => {
          resp.data.forEach((data, index, arr) => {
            if (Number(bundle.bundle_id ?? -1) === Number(data.bundle_id ?? -2)) {
              let name = [data.network ?? '', data.country ?? ''].join('-');
              /* Object.assign был использован, чтобы вывести bundle_id и bundle_name на первое место в объекте. */
              if (deep) {
                array[bundle.i].data = Object.assign({ bundle_id: data.bundle_id, bundle_name: name }, array[bundle.i].data);
              } else {
                array[bundle.i] = Object.assign({ bundle_id: data.bundle_id, bundle_name: name }, array[bundle.i]);
              }
              /* удаляем использованное значение */
              arr.splice(index, 1);
            }
          })
        })
      } catch (e) {
        this.makeToast("danger", "Не удалось загрузить названия сети"); 
      }
    },

    // Тост
    makeToast(title, body, variant = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  }
}
</script>

<style scoped>

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
}
*::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background: rgba(0,0,0,0.2);
}
*::-webkit-scrollbar-thumb:hover{
	background: rgba(0,0,0,0.4);
}
*::-webkit-scrollbar-thumb:active{
	background: rgba(0,0,0,.9);
}

.content.mb-5 {
  margin-bottom: 0 !important;
}
table {
  table-layout: fixed;
}
.data-cell {
  white-space: nowrap;
  overflow-x: scroll;
  max-width: 40vw;
}
table td, table th {
  border: thin solid rgba(0,0,0,.12);
}
th {
  font-size: 18px;
  height: 40px !important;
  background: #35353ee6 !important;
  color: #fff !important;
  font-weight: bold;
  text-transform: uppercase;
}
.primary-column {
  text-align: center;
}
</style>