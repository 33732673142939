<template>
  <div>
    <v-app>
      <b-overlay
        spinner-variant="primary"
        :show="priorotiesLoading"
        rounded="sm"
      >
        <b-container fluid>
          <br />
          <h2>Приоритеты</h2>
          <hr />

          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-form inline>
                <b-skeleton
                  class="col-sm-2 mr-2 mb-2"
                  type="button"
                ></b-skeleton>
                <b-skeleton
                  class="col-sm-2 mr-2 mb-2"
                  type="button"
                ></b-skeleton>
              </b-form>
              <b-form class="mt-3" inline>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
                <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
                <b-skeleton
                  class="col-sm-2 mr-2 mb-2"
                  type="button"
                ></b-skeleton>
              </b-form>
            </template>

            <b-row class="mt-3">
              <b-col sm class="px-0 mx-2">
                <multiselect
                  class="mb-2 z-1000"
                  v-model="valueFilter"
                  tag-placeholder="Имя сети"
                  placeholder="Имя сети"
                  label="name"
                  track-by="code"
                  :options="networks"
                ></multiselect>
              </b-col>
              <b-col sm class="px-0 mx-2">
                <b-button @click="applyFilter" variant="primary">
                  Применить</b-button
                >
              </b-col>
            </b-row>

            <b-table
              v-if="data && data.length"
              ref="selectableTable"
              style="font-size: 14px"
              :fields="fields"
              striped
              responsive="xl"
              hover
              small
              :sticky-header="heightTable"
              :items="data"
            >
            </b-table>
          </b-skeleton-wrapper>
        </b-container>
      </b-overlay>
    </v-app>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import dictionaries from "../utility/dictionaries_new";
import utility_funtions from "../utility/utility-funtions";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      /**
       * отобразить скелет вместо таблицы во время загрузки
       */
      loading: true,
      priorotiesLoading: false,
      heightTable: "200px",

      //#region переменные фильтров
      valueFilter: "",
      networks: [],
      //#endregion
      fields: [
        {
          key: "index",
          label: "Номер",
          sortable: true,
        },
        {
          key: "bundle_id",
          label: "ИД связки",
          sortable: true,
        },
        {
          key: "network_name",
          label: "Имя рекл. сети",
          sortable: true,
        },
        {
          key: "source_id",
          label: "ИД источника",
          sortable: true,
        },
        {
          key: "country",
          label: "Страна",
          sortable: true,
        },
        {
          key: "cost_per_min",
          label: "Расход в минуту",
          sortable: true,
        },
        {
          key: "cost_per_day",
          label: "Ожидаемый расход за сутки",
          sortable: true,
        },
        {
          key: "last_update",
          label: "Время посл. обновления, мин.",
          sortable: true,
        },
        {
          key: "importance_coef",
          label: "Коэффициент важности",
          sortable: true,
        },
        {
          key: "priority",
          label: "Приоритет",
          sortable: true,
        },
      ],
      data: [],
    };
  },
  watch: {
    valueFilter() {
      this.applyFilter();
    },
  },
  methods: {
    ...utility_funtions,
    ...dictionaries,

    async applyFilter() {
      const network = this.valueFilter ? this.valueFilter.code : null;
      this.priorotiesLoading = true;
      let priorities = await this.getPriorities();
      if (network) {
        priorities = priorities.filter((a) => a.network_name == network);
      }
      priorities = priorities.sort((a, b) => b.priority - a.priority);
      this.data = priorities.map((a, i) => {
        a.index = i + 1;
        // Переводим разницу в минуты и округляем до 2х знаков
        a.last_update =
          Math.round(
            ((new Date() - new Date(a.last_update.value)) / (1000 * 60)) * 100
          ) / 100;
        a.cost_per_day = a.cost_per_min ? a.cost_per_min * 1440 : null;
        return a;
      });
      this.priorotiesLoading = false;
    },
    async getNetworks() {
      const sources = await this.getSources();
      this.networks = [...new Set(sources.map((a) => a.network_name))]
        .sort()
        .map((a) => {
          return { code: a, name: a };
        });
      this.loading = false;
    },
    handleResize() {
      this.heightTable = `${window.innerHeight - 280}px`;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
    this.getNetworks();
  },
};
</script>

<style scoped>
h1 {
  font-weight: 100;
}
</style>