<template>
  <div>
    <b-container fluid>
      <h2>Тесты</h2>

      <hr />

      <h4>Check placements</h4>

      <b-form @submit="onSubmit">
        <b-row class="row" cols-sm="6">
          <b-col sm v-for="f in fields" :key="f.name">
            <b-form-group :label="f.label">
              <b-form-input
                :key="f.name"
                type="text"
                v-model="f.value"
                step="0.001"
                class="mb-2"
                :required="f.required"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button variant="primary" type="submit">
          <b-spinner small v-if="loading"></b-spinner
          ><span v-if="!loading">Рассчитать</span></b-button
        >
      </b-form>

      <br />

      <v-data-table
        dense
        :headers="tableHeader"
        :items="tableData"
        item-key="value"
        disable-pagination
        hide-default-footer
        :loading="loading"
        :item-class="itemRowBackground"
        loading-text="Loading data..."
        class="elevation-1 mb-3 table-bordered"
      ></v-data-table>
      <br />
      <br />
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { API_BQ_ROUTES } from "../constants";
import utility_funtions from "../utility/utility-funtions";
export default {
  data() {
    return {
      loading: false,

      page: 0,
      limit: 30,
      data: [],

      fields: [
        {
          label: "ИД связки",
          name: "bundle_id",
          value: "",
          required: true,
        },
        {
          label: "Site ID",
          name: "site_id",
          value: "",
          required: true,
        },
      ],
      tableHeader: [],
      tableData: [],
    };
  },

  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.onScroll);
    });
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    ...utility_funtions,
    itemRowBackground: function (item) {
      return "colored-" + (item.stage % 2) + " darkBottomBorder";
    },

    /* TODO: вынести в отдельный компонент */
    async onScroll() {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (
        scrollTop + clientHeight >= scrollHeight - 5 &&
        this.hasMoreData(this.page, this.limit, this.data.length) &&
        !this.loading
      ) {
        await this.loadData();
      }
    },

    hasMoreData(page, limit, total) {
      const startIndex = (page - 1) * limit + 1;
      return total === 0 || startIndex < total;
    },

    loadData() {
      this.loading = true;
      return new Promise(() => {
        setTimeout(() => {
          this.page++;
          this.tableData = [
            ...this.tableData,
            ...this.data.slice(
              this.page * this.limit,
              this.page * this.limit + this.limit
            ),
          ];
          this.loading = false;
        }, 1500);
      });
    },

    async onSubmit(evt) {
      evt.preventDefault();

      const bundle_id = this.fields.find((a) => a.name === "bundle_id").value;

      const site_id = this.fields.find((a) => a.name === "site_id").value;

      this.loading = true;
      this.tableHeader = [];
      try {
        const res = await axios.get(`${API_BQ_ROUTES.CHECK_PLACEMENTS}`, {
          params: { bundle_id, site_id },
        });
        const data = res.data.data ?? [];

        data.forEach((_, i) => {
          for(let k in data[i]) {
            if (typeof data[i][k] === 'object' && data[i][k] !== null) {
              for (let kk in data[i][k]) {
                data[i][`${k}.${kk}`] = data[i][k][kk];
              }
              delete data[i][k];
            }
          }
        })

        if (data.length >= 1) {
          /* заголовки для таблицы */
          Object.keys(data[0]).forEach((k) => {
            this.tableHeader.push({
              text: k.replaceAll("_", " "),
              value: k,
            });
          });

          /* добавляем первые limit данные в таблицу */
          this.data = [...data];
          this.tableData = [...data.slice(0, this.limit)];
        }
      } catch (e) {
        this.makeToast(
          "Ошибка при получении данных с сервера.",
          e.message,
          "danger"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
/deep/ table > tbody > tr.darkBottomBorder {
  border-bottom: 2px solid #ccc;
}

/deep/ table > tbody > tr.colored-0 {
  background-color: #c7f5a6;
}
/deep/ table > tbody > tr.colored-1 {
  background-color: #98f059;
}
</style>
