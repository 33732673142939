<template>
  <div>
    <b-container fluid>
      <h2>Схема таблицы "Настройки сетей"</h2>
      <hr />
      <b-button
        @click="addNetworkModal = !addNetworkModal"
        variant="outline-success"
        class="mr-2 mb-3"
        >Добавить поле
      </b-button>

      <b-table
        selectable
        bordered
        ref="selectableTable"
        style="font-size: 14px"
        striped
        responsive="xl"
        hover
        small
        :fields="fields"
        :items="networkSettings"
      >
        <template #cell(actions)="data">
          <div style="width: 80px">
            <b-button
              v-for="action in actionsBtn"
              :key="action.name"
              size="sm"
              class="mr-1"
              @click="openDeleteDialog(data.item.column_name)"
              :variant="action.vIcon"
            >
              <b-icon
                font-scale="0.97"
                :icon="action.icon"
                aria-hidden="true"
              ></b-icon
            ></b-button>
          </div>
        </template>
      </b-table>
    </b-container>

    <!-- Добавить поле -->
    <b-modal
      v-model="addNetworkModal"
      :title="`Добавить поле`"
      @hidden="resetModal"
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form-input
        type="text"
        class="mb-3"
        v-model="networkFieldName"
        required
        max="25"
        placeholder="Имя поля на eng вместо пробелов '_'"
      ></b-form-input>

      <b-form-input
        type="text"
        class="mb-3"
        v-model="networkFieldComment"
        required
        max="50"
        placeholder="Комментарий к полю"
      ></b-form-input>

      <b-form-select
        v-model="networkFieldTypeValue"
        :options="networkFieldTypes"
        required
        class="mb-3"
        placeholder="Тип поля"
      ></b-form-select>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="addNetwork">
            Добавить
          </b-button>
          <b-button
            variant="secondary"
            class="float-right mr-2"
            @click="addNetworkModal = false"
          >
            Отменить
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Модальное окно подтверждение удаления поля из таблицы -->
    <b-modal
      v-model="modalApplyRemove"
      :title="`Удаление поля ${selectedFieldName}`"
      header-bg-variant="warning"
    >
      <b-overlay spinner-variant="primary" :show="loadRemove" rounded="sm">
        <p>
          Вы уверены что хотите удалить поле: {{ selectedFieldName }}? Данные
          содержащиеся в нем будут потеряны
        </p>
      </b-overlay>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            @click="deleteNetworkField(selectedFieldName)"
          >
            Подтверждаю
          </b-button>
          <b-button
            variant="secondary"
            class="float-right mr-2"
            @click="modalApplyRemove = false"
          >
            Нет
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script scoped>
import axios from "axios";
import { API_BUNDLES } from "../constants";
import utility_funtions from "../utility/utility-funtions";

export default {
  data() {
    return {
      addNetworkModal: false,
      modalApplyRemove: false,
      removeNetworkModal: false,
      loadRemove: false,

      networkFieldName: "",
      networkFieldComment: "",
      networkFieldTypeValue: null,
      selectedFieldName: "",

      networkSettings: [],
      networkFieldTypes: [
        {
          text: "character varying",
          value: "character varying",
        },
        {
          text: "integer",
          value: "integer",
        },
        {
          text: "smallint",
          value: "smallint",
        },
        {
          text: "bigint",
          value: "bigint",
        },
        {
          text: "numeric",
          value: "numeric",
        },
      ],
      fields: [],
      actionsBtn: [
        {
          name: "remove",
          icon: "x-circle-fill",
          variant: "light",
          vIcon: "danger",
        },
      ],
    };
  },

  mounted() {
    this.getNetworks();
  },

  methods: {
    ...utility_funtions,
    openDeleteDialog(field_name) {
      console.log(field_name);
      this.selectedFieldName = field_name;
      this.modalApplyRemove = true;
    },
    getNetworks() {
      axios
        .get(API_BUNDLES.NETWORK_SCHEME_SETTINGS)
        .then((resp) => {
          this.fields = ["actions", ...Object.keys(resp?.data[0])];
          this.networkSettings = resp?.data;
        })
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {});
    },

    addNetwork() {
      const data = {
        field_name: this.networkFieldName,
        comment: this.networkFieldComment,
        field_type: this.networkFieldTypeValue,
      };
      axios
        .post(API_BUNDLES.NETWORK_SCHEME_SETTINGS_ADD, data)
        .then(() => {
          this.makeToast(
            "Успех!",
            "Сеть " + this.networkFieldName + " добавлена.",
            "success"
          );
        })
        .catch((e) => {
          this.makeToast("Ошибка!", e.response.data.message, "danger");
        })
        .finally(() => {
          this.getNetworks();
          this.addNetworkModal = false;
        });
    },

    deleteNetworkField(selectedFieldName) {
      this.loadRemove = true;
      axios
        .get(
          API_BUNDLES.NETWORK_SCHEME_SETTINGS_REMOVE +
            "?field_name=" +
            selectedFieldName
        )
        .then(() => {
          this.makeToast(
            "Успех!",
            "Поле " + selectedFieldName + " удалено.",
            "success"
          );
        })
        .catch((e) => {
          this.makeToast("Ошибка!", e.response.data.message, "danger");
        })
        .finally(() => {
          this.modalApplyRemove = false;
          this.loadRemove = false;
          this.getNetworks();
        });
    },

    resetModal() {
      this.networkFieldName = "";
      this.networkFieldComment = "";
      this.networkFieldTypeValue = null;
      this.selectedFieldName = "";
    },
  },
};
</script>