import { render, staticRenderFns } from "./bundle-archive.html?vue&type=template&id=cc95ef38&scoped=true&"
import script from "./bundle-archive.js?vue&type=script&lang=js&"
export * from "./bundle-archive.js?vue&type=script&lang=js&"
import style0 from "./bundle-archive.css?vue&type=style&index=0&id=cc95ef38&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc95ef38",
  null
  
)

export default component.exports