<template>
  <div>
    <b-container fluid>
      <h2>Ленды</h2>
      <hr />
      <b-skeleton-wrapper class="mt-4" :loading="loadLanders">
        <template #loading>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </template>
        <div class="mt-4" v-if="landers.length === 0">
          <h1 class="text-center">Ни чего не найдено!</h1>
        </div>

        <b-button
          @click="modalAddLand = true"
          variant="outline-success"
          class="mr-2 mb-3"
          >Добавить
        </b-button>
        <v-data-table
          dense
          disable-pagination
          disable-sort
          :headers="fields"
          :items="landers"
          item-key="value"
          hide-default-footer
          fixed-header
          :height="heightTable"
          class="elevation-1 mb-3 table-bordered"
        >
          <template v-slot:[`item.link`]="{ item }">
            <a style="white-space: nowrap" class="preview" :href="item.link">
              {{ item.link_text }}
            </a>
          </template>
        </v-data-table>
      </b-skeleton-wrapper>
        
      <!-- Модальное окно добавления ленда -->
      <b-modal
        @hide="resetAddLandForm"
        v-model="modalAddLand"
        :title="`Добавление сплит теста`"
      >

        <b-form-input
          type="number"
          v-model="landId"
          placeholder="Номер ленда"
          class="mb-3"
        ></b-form-input>

        <b-form-select
          v-model="landType"
          class="mb-3"
          :options="landTypes"
        >
        </b-form-select>

        <b-form-input
          type="text"
          v-model="description"
          placeholder="Описание"
          class="mb-3"
        ></b-form-input>

        <template #modal-footer>
          <div class="w-100">
            <b-button
              :disabled="isAddLandDisabled"
              variant="primary"
              class="float-right"
              @click="addLand"
            >
              Добавить
            </b-button>
            <b-button
              variant="secondary"
              class="float-right mr-2"
              @click="resetAddLandForm"
            >
              Отменить
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import utility_funtions from "../utility/utility-funtions";
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "axios";
import { API_LANDERS } from "../constants";
export default {
  components: {},
  data() {
    return {
      modalAddLand: false,
      isAddLandDisabled: true,

      landId: null,
      landType: 0,
      description: null,

      landTypes: [
          { value: 0, text: 'Mainstream' },
          { value: 1, text: 'Adult' }
      ],

      loadLanders: true,
      heightTable: "300px",
      landers: [],
      fields: [
        {
          text: "ИД ленда",
          value: "land_id",
        },
        {
          text: "Ссылка",
          value: "link",
        },
        {
          text: "Тип",
          value: "type"
        },
        {
          text: "Описания",
          value: "description",
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.getLanders();
    this.handleResize();
  },
  watch: {
    landId() {
      if (this.landId != null && this.description != null) {
        this.isAddLandDisabled = false;
      }
    },
    description() {
      if (this.description != null && this.description != null) {
        this.isAddLandDisabled = false;
      }
    }
  },
  methods: {
    ...utility_funtions,
    handleResize() {
      this.heightTable = `${window.innerHeight - 280}px`;
    },
    getLanders() {
      this.loadLanders = true;
      axios
        .get(API_LANDERS.LAND_IDS)
        .then((data) => {
          const base_url = "https://joxow.com/l/PA/";
          this.landers = data.data.map((a) => {
            return {
              land_id: a.land_id,
              link_text: `${base_url}${a.land_id}/`,
              link: `${base_url}${a.land_id}/`,
              description: a.description,
              type: a.type === 0 ? 'Mainstream' : 'Adult'
            };
          });
          this.loadLanders = false;
        })
        .catch((er) => {
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка get landers", e, "danger");
        });
    },
    addLand() {
      const body = {
        land_id: Number(this.landId),
        type: Number(this.landType),
        description: this.description
      };
      axios
        .post(API_LANDERS.ADD_LAND, body, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(() => {
          this.getLanders();
          this.makeToast("Успех!", "Ленд добавлен", "success");
        })
        .catch((err) => {
          console.log(err);
          this.makeToast("Ошибка add land", err, "danger");
        })
        .finally(() => {
          this.resetAddLandForm();
        })
    },
    resetAddLandForm() {
      this.landId = null;
      this.description = null;
      this.modalAddLand = false;
    }
  },
};
</script>

<style scoped>
h1 {
  font-weight: 100;
}
</style>
