<template>
  <div>
    <b-container fluid>
      <h2>Рейты</h2>
      <hr />
      <b-skeleton-wrapper :loading="loadingFilter">
        <template #loading>
          <b-form class="mt-4" inline>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm mr-2 mb-2" type="input"></b-skeleton>
            <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
          </b-form>
        </template>

        <b-row class="mt-2">
          <b-col sm class="px-0 mx-2">
            <multiselect
              class="mb-2 z-1000"
              v-model="valueCodes"
              tag-placeholder="Страна"
              placeholder="Страна"
              label="name"
              track-by="code"
              :options="codes"
              :multiple="true"
              :taggable="true"
            ></multiselect>
          </b-col>
          <b-col sm class="px-0 mx-2">
            <multiselect
              class="mb-2 z-1000"
              v-model="valuePartner"
              tag-placeholder="Партнер"
              placeholder="Партнер"
              label="name"
              track-by="partner"
              :options="offers"
              :multiple="true"
              :taggable="true"
            ></multiselect>
          </b-col>
          <b-col sm class="px-0 mx-2">
            <multiselect
              class="mb-2 z-1000"
              v-model="valueSources"
              tag-placeholder="Источник"
              placeholder="Источник"
              label="name"
              track-by="code"
              :options="sources"
              :multiple="true"
            ></multiselect>
          </b-col>
          <b-col sm class="px-0 mx-2">
            <b-button
              @click="
                currentPage = 1;
                getRates();
              "
              variant="primary"
            >
              Применить</b-button
            >
          </b-col>
        </b-row>
      </b-skeleton-wrapper>
      <b-skeleton-wrapper class="mt-4" :loading="loadRates">
        <template #loading>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </template>
        <div class="mt-4" v-if="rates.length === 0">
          <h1 class="text-center">Ни чего не найдено!</h1>
        </div>

        <v-data-table
          dense
          disable-pagination
          disable-sort
          :headers="fields"
          :items="rates"
          item-key="value"
          hide-default-footer
          fixed-header
          :height="heightTable"
          class="elevation-1 mb-3 table-bordered"
        >
        </v-data-table>
      </b-skeleton-wrapper>
    </b-container>
  </div>
</template>

<script>
import dictionaries from "../utility/dictionaries";
import utility_funtions from "../utility/utility-funtions";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      heightTableRate: 100,
      rows_count: 0,
      offers: [],
      partners: [],
      valuePartner: [],
      valueCodes: [],
      codes: [],
      valueSources: [],
      sources: [],
      loadingFilter: true,
      loadRates: true,
      currentPage: 1,
      totalRows: 200,
      heightTable: "300px",
      rates: [],
      fields: [],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    // window.addEventListener("resize", this.onResize);
  },
  mounted() {
    this.getCountries();
    this.getSources();
    this.getOffers();
    this.getRates();
    this.handleResize();
  },
  methods: {
    ...dictionaries,
    ...utility_funtions,
    onResize() {
      this.heightTableRate = document.getElementById("outer").clientHeight;
    },
    selectPagination(v) {
      this.currentPage = v;
    },
    //Изменпие размера
    handleResize() {
      this.heightTable = `${window.innerHeight - 280}px`;
    },
  },
  watch: {
    currentPage() {
      this.getRates();
    },
    offers(offers) {
      this.partners = [...new Set(offers.map((a) => a.code.slice(-2)))].map(
        (o) => {
          return { code: o, name: o };
        }
      );
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: 100;
}
::v-deep .offerClass {
  left: 35px !important;
}
::v-deep .defaultRateClass {
  left: calc(30px + 70px) !important;
}

::v-deep .table-header thead {
  background-color: black;
}

::v-deep .itemDatatBale {
  color: red !important;
}

::v-deep tr td {
  z-index: 800 !important;
}

::v-deep tr td:first-child {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 800 !important;
  background: #f5f5f5;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr td:nth-child(2) {
  left: 60px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 800 !important;
  background: #f5f5f5;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr td:nth-child(3) {
  left: 160px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 800 !important;
  background: #f5f5f5;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr th:first-child {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr th:nth-child(2) {
  left: 60px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep tr th:nth-child(3) {
  left: 160px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900 !important;

  outline: 2px solid #dee2e6;
  -webkit-outline: 2px solid #dee2e6;
  outline-offset: -1px;
}

::v-deep .headBackground {
  background: #f5f5f5 !important;
  border: 1px solid #dee2e6;
  top: -2px !important;
}

.z-1000 {
  z-index: 1000 !important;
}
.headBackground {
  top: -1px;
}
</style>
