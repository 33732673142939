<template>
  <div>
    <b-overlay spinner-variant="primary" rounded="sm">
      <b-container fluid>
        <h2>Сплиты</h2>
        <hr />
        <b-skeleton-wrapper class="mt-4" :loading="loadTemporarySplitTest">
          <template #loading>
            <b-form inline>
              <b-skeleton class="col-sm-2 mr-2 mb-2" type="button"></b-skeleton>
            </b-form>
            <b-skeleton-table
              :rows="5"
              :columns="4"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </template>
          <b-button
            @click="modalAddLanderToSplitTest = true"
            variant="outline-success"
            class="mr-2 mb-3"
            >Добавить
          </b-button>
          <b-button
            @click="getSplitTests()"
            variant="outline-primary"
            class="mr-2 mb-3"
            >Обновить
          </b-button>
          <div class="mt-4" v-if="tests.length === 0">
            <h1 class="text-center">Ничего не найдено!</h1>
          </div>
          <b-table
            v-if="tests.length != 0"
            ref="selectableTable"
            style="font-size: 14px"
            :sticky-header="heightTable"
            :fields="fields"
            striped
            responsive="xl"
            hover
            small
            :items="tests"
          >
            <template #cell()="data">
              <div style="white-space: nowrap">
                {{ data.value }}
              </div>
            </template>
            <template #head()="data">
              <div style="white-space: nowrap">{{ data.label }}</div>
            </template>
            <template #cell(action)="data">
              <div style="width: 80px">
                <b-button
                  size="sm"
                  class="mr-1"
                  @click="
                    () => {
                      modalApplyRemovePacket = true;
                      dataRemovePacket = data.item.test_id;
                    }
                  "
                  variant="danger"
                >
                  <b-icon icon="x-circle-fill" aria-hidden="true"></b-icon
                ></b-button>
              </div>
            </template>
          </b-table>
        </b-skeleton-wrapper>

        <!-- Модальное окно добавления сплита -->
        <b-modal
          @hide="hideModalAddLanderToSplitTest"
          v-model="modalAddLanderToSplitTest"
          :title="`Добавление сплит теста`"
        >
          <multiselect
            class="mb-3"
            v-model="valueBundlesIds"
            tag-placeholder="ID связки"
            placeholder="ID связки"
            label="name"
            track-by="code"
            :options="bundlesIds"
            :multiple="true"
            :taggable="true"
          ></multiselect>
          <multiselect
            class="mb-3"
            v-model="valueLands"
            tag-placeholder="Ленды"
            placeholder="Ленды"
            label="name"
            track-by="code"
            :options="allLanders"
            :multiple="true"
            :taggable="true"
          ></multiselect>
          <b-form-input
            type="text"
            v-model="comment"
            placeholder="Комментарий"
            class="mb-3"
          ></b-form-input>
          <template #modal-footer>
            <div class="w-100">
              <b-button
                :disabled="disableAddLandersForSplitTest"
                variant="primary"
                class="float-right"
                @click="addSplitTest"
              >
                Добавить
              </b-button>
              <b-button
                variant="secondary"
                class="float-right mr-2"
                @click="modalAddLanderToSplitTest = false"
              >
                Отменить
              </b-button>
            </div>
          </template>
        </b-modal>

        <!-- Модельаное окно подтверждение удаления пакета тестов -->
        <b-modal
          v-model="modalApplyRemovePacket"
          :title="`Удаление пакета ${dataRemovePacket}`"
          header-bg-variant="warning"
        >
          <p>Подтверждаете удаление пакета?</p>

          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="primary"
                class="float-right"
                @click="removeSplitTest"
              >
                Подтверждаю
              </b-button>
              <b-button
                variant="secondary"
                class="float-right mr-2"
                @click="modalApplyRemovePacket = false"
              >
                Нет
              </b-button>
            </div>
          </template>
        </b-modal>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import utility_funtions from "../utility/utility-funtions";
import dictionaries from "../utility/dictionaries";
import { SPLIT_TEST } from "../constants";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "axios";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      modalApplyRemovePacket: false,
      toggleDetails: false,
      modalAddLanderToSplitTest: false,
      loadTemporarySplitTest: false,
      disableAddLandersForSplitTest: true,

      tests: [],
      fields: [],

      //#region dictionaris
      bundlesIds: [],
      allLanders: [],
      //#endregion

      //#region add split form
      valueBundlesIds: [],
      valueLands: [],
      comment: "",
      //#endregion

      dataRemovePacket: null,
      heightTable: "200px",
    };
  },
  mounted() {
    this.getBundlesIds();
    this.getAllLanders();
    this.getSplitTests();
    this.handleResize();
  },
  watch: {
    valueBundlesIds() {
      if (this.valueBundlesIds.length != 0 && this.valueLands.length != 0) {
        this.disableAddLandersForSplitTest = false;
      }
    },
    valueLands() {
      if (this.valueBundlesIds.length != 0 && this.valueLands.length != 0) {
        this.disableAddLandersForSplitTest = false;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    ...utility_funtions,
    ...dictionaries,
    hideModalAddLanderToSplitTest() {
      this.valueBundlesIds = [];
      this.valueLands = [];
      this.comment = "";
    },
    getSplitTests() {
      this.loadTemporarySplitTest = true;
      return axios
        .get(SPLIT_TEST.GET)
        .then((tests) => {
          const d = tests.data.data;
          // console.log(JSON.stringify(d));
          if (d.length) {
            // const groupPack = this.groupBy(d, (c) => c.id);
            this.tests = d.map((a) => {
              return {
                ...a,
                name: a.name,
                create_date: new Date(a.create_date)
                  .toLocaleString("ru-RU")
                  .split(", ")
                  .join(" "),
              };
            });
            this.fields = Object.keys(this.tests[0]).map((a) => {
              let title = "";
              switch (a) {
                case "test_id":
                  title = "ИД сплита";
                  break;
                case "bundle_ids":
                  title = "ИД связок";
                  break;
                case "offers":
                  title = "Офферы";
                  break;
                case "land_ids":
                  title = "ИД лендов";
                  break;
                case "tb_ids":
                  title = "ИД ТБ";
                  break;
                case "comment":
                  title = "Комментарий";
                  break;
                case "create_date":
                  title = "Дата создания";
                  break;
              }
              return { label: title, key: a };
            });
            //console.log(this.fields);
            this.fields = [
              { key: "action", label: "Действия", stickyColumn: true },
            ].concat(this.fields);
            this.loadTemporarySplitTest = false;
          } else {
            this.tests = d;
            // this.loadTemporarySplitTest = false;
            // this.makeToast(
            //   "Ошибка получения лендов сплит - теста",
            //   "Ошибка ",
            //   "danger"
            // );
          }
          this.getBundlesIds();
        })
        .catch((er) => {
          console.log(er);
          const e = "response" in er ? er.response.data.message : "";
          this.makeToast("Ошибка изменения параметра", e, "danger");
          this.loadTemporarySplitTest = true;
        })
        .finally(() => {
          this.loadTemporarySplitTest = false;
        })
    },
    addSplitTest() {
      let url = SPLIT_TEST.GET;
      let body = {
        bundle_ids: this.valueBundlesIds.map((a) => a.code),
        offers: this.valueLands.map((a) => a.offer),
        land_ids: this.valueLands.map((a) => a.land_id),
        tb_ids: this.valueLands.map((a) => a.tb_id),
        comment: this.comment,
      };

      this.loadAddLandersToSpltTest = true;
      this.modalAddLanderToSplitTest = false;
      const headers = {
        "Content-type": "application/json",
      };

      axios
        .post(url, JSON.stringify(body), {
          headers: headers,
        })
        .then(() => {
          this.makeToast("Сплит тест создан", "Успех", "success");
          this.getSplitTests();
          this.loadAddLandersToSpltTest = false;
        })
        .catch((er) => {
          this.loadAddLandersToSpltTest = false;
          console.log(er);
          const e = er.response.data.message;
          this.makeToast("Ошибка добавления сплит-теста", e, "danger");
        });
    },
    removeSplitTest() {
      const test_id = this.dataRemovePacket;
      if (!test_id) {
        this.modalApplyRemovePacket = false;
        this.loadAddLandersToSpltTest = true;
        this.makeToast("Не найден ид сплит теста", "Ошибка", "danger");
        return;
      }
      this.modalApplyRemovePacket = false;
      this.loadAddLandersToSpltTest = true;
      console.log(test_id);
      axios
        .delete(SPLIT_TEST.GET + test_id)
        .then(() => {
          this.makeToast("Пакет лендов успешно удален", "Успех", "success");
          this.getSplitTests();
          this.loadAddLandersToSpltTest = false;
        })
        .catch((err) => {
          console.log(err);
          const e = err.response
            ? err.response.data.message
              ? err.response.data.message
              : err.message
              ? err.message
              : JSON.stringify(err)
            : err.message;
          this.loadAddLandersToSpltTest = false;
          this.makeToast("Ошибка удаоения пакета лендов", e, "danger");
        });
    },
    handleResize() {
      this.heightTable = `${window.innerHeight - 280}px`;
    },
  },
};
</script>

<style scoped>
</style>
