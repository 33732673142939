import { API_BUNDLES } from "../../constants";
import axios from "axios";
import dictionaries from "../../utility/dictionaries";

export default {
    ...dictionaries,
    // Множественное добавление связок
    addBundles() {
        this.loadSaveBundle = true;
        const headers = {
            "Content-Type": "application/json",
        };

        const body = {
            source_ids: this.valueSourcesIdForBundle.map((a) => a.code),
            countries: this.valueCountriesForBundle.map((a) => a.code),
            min_roi: this.valueMinRoiForBundle ?
                Number(this.valueMinRoiForBundle) / 100 : null,
            min_bid: Number(this.valueMinCPMForBundle),

            offer: this.chosenEditLander.offer,
            land_id: this.chosenEditLander.land_id,
            tb_id: this.chosenEditLander.tb_id,
        };
        axios
            .post(`${API_BUNDLES.FIND}multi`, JSON.stringify(body), {
                headers: headers,
            })
            .then(() => {
                this.loadSaveBundle = false;
                this.modalAddManyBundle = false;
                this.makeToast(
                    "Множественное создание связок",
                    "Связки успешно созданы",
                    "success"
                );
                this.getBundles();
                this.getBundlesIds();
            })
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message;
                // console.log(JSON.stringify(er.response.data.message));
                // // console.log(typeof er);
                // // console.log(JSON.stringify(Object.keys(er)));
                this.makeToast("Ошибка создания связок", e, "danger");
                this.loadSaveBundle = false;
            });
    },
    //Добавление связки
    addBundle() {
        this.loadAddBundle = true;
        this.modalAddBundle = false;
        const headers = {
            "Content-Type": "application/json",
        };
        const body = {
            source_id: Number(this.valueSourceIdForBundle.code),
            country: this.valueCountryForBundle.code,
            min_roi: this.valueMinRoiForBundle ?
                Number(this.valueMinRoiForBundle) / 100 : null,
            min_bid: Number(this.valueMinCPMForBundle),
            offer: this.chosenEditLander.offer,
            land_id: this.chosenEditLander.land_id,
            tb_id: this.chosenEditLander.tb_id,
        };
        console.log('addBundle body', body);
        axios
            .post(`${API_BUNDLES.FIND}`, JSON.stringify(body), {
                headers: headers,
            })
            .then(() => {
                this.loadAddBundle = false;
                this.makeToast(
                    "Связка " +
                    `${
                  this.valueSourceIdForBundle
                    ? this.valueSourceIdForBundle.code
                    : ""
                } ${
                  this.valueCountryForBundle
                    ? " - " + this.valueCountryForBundle.code
                    : ""
                } `,
                    "Связка успешно создана",
                    "success"
                );
                this.getBundles();
                this.getBundlesIds();
            })
            .catch((er) => {
                const e = er.response.data.message;
                // console.log(JSON.stringify(er.response.data.message));
                // // console.log(typeof er);
                // // console.log(JSON.stringify(Object.keys(er)));
                this.makeToast("Ошибка создания связки", e, "danger");
                this.loadAddBundle = false;
            });
    },
    saveBundle() {
        this.loadSaveBundle = true;
        const headers = {
            "Content-Type": "application/json",
        };
        const body = {
            min_cpm: this.valueMinCPMForBundle,
            min_roi: this.valueMinRoiForBundle ?
                Number(this.valueMinRoiForBundle) / 100 : null,
            offer: this.chosenEditLander.offer,
            land_id: this.chosenEditLander.land_id,
            tb_id: this.chosenEditLander.tb_id,
        };
        axios
            .put(
                `${API_BUNDLES.FIND}${this.selectIdBundle}`,
                JSON.stringify(body), {
                    headers: headers,
                }
            )
            .then(() => {
                this.loadSaveBundle = false;
                this.modalEditBundle = false;
                this.makeToast(
                    "Связка - " + this.selectIdBundle,
                    "Связка успешно обновлена",
                    "success"
                );
                this.getBundles();
            })
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка обновления связки", e, "danger");
                this.loadSaveBundle = false;
                this.modalEditBundle = false;
            });
    },

    saveBundles() {
        this.loadSaveBundle = true;
        const headers = {
            "Content-Type": "application/json",
        };
        const body = {
            min_cpm: this.valueMinCPMForBundle,
            min_roi: this.valueMinRoiForBundle ?
                Number(this.valueMinRoiForBundle) / 100 : null,
            offer: this.chosenEditLander ? this.chosenEditLander.offer : null,
            land_id: this.chosenEditLander ? this.chosenEditLander.land_id : null,
            tb_id: this.chosenEditLander ? this.chosenEditLander.tb_id : null,
        };
        console.log('saveBundles body', body);
        this.loadSaveBundle = false;
        axios
            .put(`${API_BUNDLES.FIND}${this.selectedBundles.map((a) => a.bundle_id)}/multi`, JSON.stringify(body), {
                headers: headers,
            })
            .then(() => {
                this.loadSaveBundle = false;
                this.modalApplyEditBundles = false;
                this.makeToast(
                    "Связка - " + this.selectIdBundle,
                    "Связки успешно обновлены",
                    "success"
                );
                this.getBundles();
            })
            .catch((er) => {
                console.log(er);
                const e = er.response.data.message;
                this.makeToast("Ошибка обновления связок", e, "danger");
                this.loadSaveBundle = false;
                this.modalApplyEditBundles = false;
            });
    },

    removeBundle() {
        this.loadAddBundle = true;
        this.modalApplyRemoveBundle = false;
        axios
            .delete(`${API_BUNDLES.FIND}${this.selectIdBundle}`)
            .then(() => {
                this.loadAddBundle = false;
                this.makeToast(
                    "Связка " + this.selectIdBundle,
                    "Связка успешно удалена",
                    "success"
                );
                this.getBundles();
            })
            .catch((er) => {
                const e = er.response.data.message;
                console.log(er.response);
                this.makeToast("Ошибка удаления связки", e, "danger");
                this.loadAddBundle = false;
            });
    },

    multiRemoveBundle(ids) {
        this.modalApplyMultiRemoveBundles = false;
        this.loadBundleOverlay = false;
        if (ids) {
            this.loadBundleOverlay = true;
            axios
                .delete(`${API_BUNDLES.FIND}${ids}/multi`)
                .then((res) => {
                    const errBundles = res.data;
                    console.log(errBundles);
                    this.loadBundleOverlay = false;
                    this.makeToast(
                        "Связки удалены",
                        `Связки успешно удалены ${
            errBundles.length > 0 ? "колме " + errBundles.join(",") : ""
          }`,
                        "success"
                    );
                    this.getBundles();
                })
                .catch((er) => {
                    console.log(er);
                    const e = er.response.data.message;
                    this.makeToast("Ошибка удаления связок", e, "danger");
                    this.loadBundleOverlay = false;
                });
        }
    },
}